export const RECEIVE_JOBS = 'RECEIVE_JOBS'
export const NEW_JOB = 'NEW_JOB'
export const UPDATE_JOB = 'UPDATE_JOB'
export const DELETE_JOB = 'DELETE_JOB'

export function receiveJobs(jobs) {
    return {
        type: RECEIVE_JOBS,
        jobs
    }
}

export function newJob(job) {
  return {
      type: NEW_JOB,
      job
  }
}

export function updateJob(jobs) {
  return {
      type: UPDATE_JOB,
      jobs
  }
}

export function deleteJob(jobid) {
  return {
      type: DELETE_JOB,
      jobid
  }
}