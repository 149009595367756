import React, { useState } from 'react'
import { Modal, Form, Row, Col, InputGroup, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'

const NewEngineerModal = ({ newEngineer, openNewEngineer, engineerCreated, engineerUpdated, engineerError, dispatchEngineer }) => {
    const [validated, setValidated] = useState(false)
    const [passwordshow, setPasswordShow] = useState(false);
    const options = [
        {
            label: "Yes",
            value: true
        }, 
        {
            label: "No",
            value: false
        }
    ]
    const handleSubmit = async (e, props) => {
        const form = e.currentTarget
        
        console.log(form.checkValidity())

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
        } else {
            e.preventDefault()

            const formData = new FormData(e.target),
                    formDataObj = Object.fromEntries(formData.entries())
            let engineerobj

            if (newEngineer !== true) {
                console.log(formDataObj)
                await axios.put(`${endpoint}/api/users`, formDataObj, {
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            console.log("Message Sent.")
                            engineerobj = response.data                 
                            dispatchEngineer({ type: 'UPDATE_USER', engineer: engineerobj })
                            engineerUpdated(true)
                            openNewEngineer(false)
                        } else {
                            console.log(response.statusText)  
                            console.log(response.errors)   
                            engineerError("error")   
                            openNewEngineer(false)            
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        engineerError(true)   
                        openNewEngineer(false)
                    })
            } else {
                console.log(formDataObj);
                await axios.post(`${endpoint}/api/users`, formDataObj, {
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            console.log("Message Sent.")
                            engineerobj = response.data
                            console.log(response)                 
                            dispatchEngineer({ type: 'NEW_USER', engineer: engineerobj })
                            engineerCreated(true)
                            openNewEngineer(false)
                        } else {
                            console.log(response.statusText)  
                            console.log(response.errors)   
                            engineerError(true)    
                            openNewEngineer(false)           
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        engineerError(true)    
                        openNewEngineer(false)
                    })
            }
        }
    }
    console.log(newEngineer);
    return (
        <Modal show={newEngineer} onHide={() => openNewEngineer(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{newEngineer !== true ? 'Edit Engineer' : 'Add New Engineer'}</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>

                <Form.Control type="hidden" name="engineer_id" defaultValue={newEngineer !== true ? newEngineer._id : ''}/>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Name</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">create</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Engineer Name..." name="name" defaultValue={newEngineer !== true ? newEngineer.name : ''} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a name.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Email</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text style={{'font-weight': 'bold'}}>@</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Engineer email..." name="email" defaultValue={newEngineer !== true ? newEngineer.email : ''} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose an email
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Password</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">vpn_key</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type={passwordshow ? "text" :"password"} placeholder="Engineer password..." name="password" defaultValue="" required={newEngineer ===true}/>
                                <Form.Control.Feedback type="invalid">
                                    Please input a password.
                                </Form.Control.Feedback>
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setPasswordShow(!passwordshow)}><i className="material-icons">info</i></InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Engineer?</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text style={{'font-weight': 'bold'}}>?</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="select" name="isEngineer">
                                {options.map((option) => {
                                    if (newEngineer !== true){                                        
                                        return <option  selected={newEngineer.isEngineer === option.value} value={option.value}>{option.label}</option>
                                    } else {
                                        return <option value={option.value}>{option.label}</option>
                                    }
                                })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please choose.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        {(newEngineer!== true && newEngineer.is_deleted) && 
                            <Form.Group as={Col} controlId="formGridDate">
                                <Form.Label>Change Deletion?</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text style={{'font-weight': 'bold'}}>?</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control as="select" name="change_deletion">
                                    {options.map((option) => {
                                        if (newEngineer !== true){                                        
                                            return <option  selected={newEngineer.isEngineer === option.value} value={option.value}>{option.label}</option>
                                        } else {
                                            return <option value={option.value}>{option.label}</option>
                                        }
                                    })}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please choose.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        }


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => openNewEngineer(false)}>Close</Button>
                    <Button variant="success" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default NewEngineerModal