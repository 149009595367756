import React, { Component, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Container, OverlayTrigger, Popover, Modal, Button } from 'react-bootstrap'
import { endpoint } from '../../utils/api'

const onClick = async () => {
    const res = await axios.get(`${endpoint}/testAPI`)
}

const Invoice = ({ jobs }) => {
    return (
        <Container fluid id="main-container">
            <p>Test</p>
            <a onClick={onClick}>Make Invoice</a>
        </Container>
    )
}

function mapStateToProps({ jobs }) {
    return {
        jobs
    }
}

export default connect(mapStateToProps)(Invoice)