import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'

const RemoveEngineerModal = ({ removeEngineer, openRemoveEngineer, engineerRemoved, dispatchEngineer, engineerError }) => {
    const callRemoveEngineer = async () => {
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/api/users/${removeEngineer._id}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        console.log("Message Sent.")
                        console.log(response.data)                      
                        dispatchEngineer({ type: 'REMOVE_USER', userid: response.data.id })
                        engineerRemoved(true)
                        openRemoveEngineer(false)
                    } 
                })
                .catch((error) => {
                    engineerError(error.response.data.msg)
                    openRemoveEngineer(false)
                })
    }

    return (
        <Modal show={removeEngineer !== false ? true : false} onHide={() => openRemoveEngineer(false)}>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'red' }}>Delete Engineer</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to delete this engineer?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => openRemoveEngineer(false)}>Close</Button>
                <Button variant="danger" onClick={() => callRemoveEngineer()}><i className="material-icons">delete</i>&nbsp;Delete Engineer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveEngineerModal