import React from 'react'

export const FeeResult = ({ feebreakdown }) => {
    return (
        <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Fee Element</th>
                    <th>Cost</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Testing Fee</td>
                    <td>{`£${feebreakdown.fee} + VAT`}</td>
                </tr>
                <tr>
                    <td>Travel</td>
                    <td>{`£${feebreakdown.travel} + VAT`}</td>
                </tr>
                <tr>
                    <th>Total Fee</th>
                    <th>{`£${feebreakdown.fee + feebreakdown.travel} + VAT`}</th>
                </tr>
            </tbody>
        </table>
    )
}
