import React, { useState, useEffect } from 'react'
import { InputGroup, Form, Col,  FormControl } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import "../../css/forms/ContactForm.css"
import { updateJobAPI } from '../../utils/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { projectLeaders } from '../../utils/projectLeaders';
import '../../css/icons/fontAwesomeIcons.css'

library.add(faAt)

const Contact = ({ job, enquiry }) => {
    const {leaders} = projectLeaders();

    const [delegatedCheck, setDelegatedCheck] = useState(false)    
    useEffect(() => {
        setDelegatedCheck(enquiry !== null ? enquiry.delegated : job !== null ? job.delegated : false)
    }, [ enquiry, job])
   
    useEffect(() => {
    }, [delegatedCheck])

   
    return (
        <Widget title="Client Details" icon="account_box" paddingStyle="5px">
            <Form.Group as={Col} controlId="formGridContactName">
                <Form.Label>Name</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">account_box</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control placeholder="Name..." name="name" defaultValue={job !== null ? job.contact.name : (enquiry !== null ? enquiry.contact.name : '')} required/>
                    <Form.Control.Feedback type="invalid">
                        Please choose a contact name.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPosition">
                <Form.Label>Position</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">work</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control placeholder="Position..." name="position" defaultValue={job !== null ? job.contact.position : (enquiry !== null ? enquiry.contact.position : '')} required/>
                    <Form.Control.Feedback type="invalid">
                        Please choose a contact position.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridContactEmail">
                <Form.Label>Email</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><FontAwesomeIcon icon={faAt}  size='1x' className='atIcon' /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control placeholder="Email..." name="email" defaultValue={job !== null ? job.contact.email : (enquiry !== null ? enquiry.contact.email : '')} required/>
                    <Form.Control.Feedback type="invalid">
                        Please choose a contact email.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>


            <Form.Group as={Col} controlId="formGridContactTelephone">
                <Form.Label>Telephone</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">phone</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control placeholder="Telephone..." name="telephone" defaultValue={job !== null ? (job.contact.telephone !== null ? `0${job.contact.telephone}` : '') : (enquiry !== null ? (enquiry.contact.telephone !== null ? `0${enquiry.contact.telephone}` : '') : '')} required/>
                    <Form.Control.Feedback type="invalid">
                        Please choose a contact telephone.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
            {!window.location.pathname.includes("enquiry") &&
              <Form.Group  as={Col} controlId="formGridDelegated">
                <input 
                    className="delegatedCheckBox"
                    type="checkbox"
                    label="delegated"
                    controlId="delegated"
                    name="delegated" 
                    checked={delegatedCheck}  
                    onChange={() => setDelegatedCheck(!delegatedCheck)}
                    
                />
                <label className="delegatedCheckLabel" for="delegated">&nbsp;&nbsp;&nbsp;&nbsp;Delegated</label>
            </Form.Group>
            }  

            {(!window.location.pathname.includes("enquiry") && delegatedCheck) && 
                <Form.Group as={Col} controlId="formGridContactName">
                    <Form.Label>Project Leader</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">how_to_reg</i></InputGroup.Text>
                        </InputGroup.Prepend>
                            <Form.Control as="select" name="project_leader">
                                {leaders.map((option) => {
                                    if (job !== null){                                        
                                        return <option  selected={job.contact.project_leader === option.value} value={option.value}>{option.label}</option>
                                    } else if (enquiry !== null){
                                        return <option  selected={enquiry.contact.project_leader === option.value} value={option.value}>{option.label}</option>
                                    } else {
                                        return <option value={option.value}>{option.label}</option>
                                    }
                                })}
                            </Form.Control>
                        </InputGroup>
                </Form.Group>
            }
        

        </Widget>
    )
}

export default Contact