import axios from "axios"
import { endpoint } from "../../utils/api"

export const feeSettings = {
    global: {
        localRadius: 15, // Radius of a 'local' job
        stackDiscount: 50, // discount for a stack
        advanceDiscount: 50, // discount for a job booked far in advance
        homePostcode: 'BS28QF', // postcode of HQ
        workingDay: 540 // 9 hours
    },
    sound: {
        setsaday: 3, // max sets in a day
        fee: {
            localJob: 350, // price for a local job
            nonLocalJob: 450, // price for a non local job
            localRadius: 30, // Radius of a 'local' job
            additional: 150, // cost for additional sets
            "Airborne Walls": 75, 
            "Airborne Floors": 75,
            "Impacts": 75,
            "Backgrounds": 75,
            "RTs": 75
        },
        time: {
            setup: 30,
            "Airborne Walls": 20,
            "Airborne Floors": 20,
            "Impacts": 10,
            "Backgrounds": 5,
            "RTs": 5
        }
    },
    air: {
        fee: {
            localJob: 250, // price for a local job
            nonLocalJob: 300, // price for a non local job
            localRadius: 30, // Radius of a 'local' job
            additional: 150 // cost for additional sets
        },
        time: {
            setup: 30,
            "Air Tests": 45
        }
    },
    survey: {
        fee: {
            localJob: 350,
            nonLocalJob: 450,
            localRadius: 30,
            additional: 150
        },
        time: {
            setup: 30,
            "Noise Survey": 45
        }
    }
}

/**
  * @description Handle a user inputted postcode - calculate driving and generate fee
  * @param {Object} postcode - object describing users inputted postcode 
  * @param {Function} setPostcodeError - hook to trigger error for invalid postcode
  * @param {Function} setFeeBreakdown - hook to set a fee and it's breakdown based upon number of tests and postcode
  * @param {Function} setPostcode - hook to set the postcode object
  */
export const handlePostcode = (postcode, setPostcodeError, setFeeBreakdown, setPostcode) => {
    if (postcode.text.length > 2) { // only calculate if the postcode is greater than 3 characters long
        calculateDriving(postcode)
            .then((res) => {
                if (res == "Invalid Postcode!") { // handle invalid postcode
                    setPostcodeError(true)
                    setFeeBreakdown({fee: 0, travel: 0, sitevisits: 0}) // if invalid postcode set fee breakdown to 0
                } else {
                    setPostcode(res) // set postcode with result of calculateDriving function
                    setPostcodeError(false)
                }
            })
    } else {
        setPostcodeError(true) // if postcode less then 3 characters long trigger postcode error and reset fee
        setPostcode({
            text: '',
            driving: {
                text: '',
                value: 0
            },
            geocode: {
                lat: '',
                lng: ''
            }
        })
        setFeeBreakdown({fee: 0, travel: 0, sitevisits: 0})
    }
}

/**
  * @description Calculate driving time and distance from a postcode from BS28QF (office address) using Google Maps API
  * @param {Object} postcode - object describing users inputted postcode 
  * @return {Object} if valid postcode (response from API with distance and time) 
  * @return {String} if invalid postcode
  */
export const calculateDriving = async postcode => {
    const geocodePostcode = await axios({       // uses Google Maps geocoding API to get latitude and longitude of a postcode
        url: `${endpoint}/api/book/geocode`,
        method: 'POST',
        data: {
            postcode: postcode.text // the String input from user
        }
    })
    .then((res) => {
        if (res.data.travel.hasOwnProperty('duration')) { // if valid postcode
            const drivingTime = Math.round(res.data.travel.duration_in_traffic.value / 60) // API returns time in seconds so convert it to minutes

            if (drivingTime > 200) { // if driving time is greater than 200 minutes then consider it too far to complete in a day
                return "Too far"
            } else {
                return {    // if less than 200 minutes return result from API
                    text: postcode.text,
                    driving: res.data.travel.distance,
                    geocode: res.data.geocode
                }
            }
        } else {
            return "Invalid Postcode!"  // invalid postcode
        }
    })
    .catch((error) => {
        console.log(error)
        return "Invalid Postcode!"  // invalid postcode
    })

    return geocodePostcode
}

/**
  * @description Calculate the fee breakdown 
  * @param {Object} numberoftests - breakdown of number of tests by test type 
  * @param {Object} postcode - object describing users inputted postcode 
  * @param {String} testtype - Sound/Air/Survey
  * @return {Object} fee breakdown object
  */
export const calculateFee = (numberoftests, postcode, testtype) => {
    let totalNumberTests = 0
    Object.keys(numberoftests).map(test => totalNumberTests += test) // loop through test type and total up the number of tests
    
    const drivingTime = (postcode.driving.value / 1609) * 2 // convert meters to miles - multiply by 2 for return journey

    const travelCost = Math.round((postcode.driving.value / 1609) * 0.45) // meters to miles at 45p / mile

    let timeTaken = feeSettings[testtype].time.setup // initally set timeTaken to the setup time

    Object.keys(numberoftests).forEach(type => {    // loop through each test type and total up the time taken to complete each test
        timeTaken += feeSettings[testtype].time[type] * numberoftests[type]
    });

    const totalTimeTaken = timeTaken + drivingTime // add the driving time on to the time taken to test

    const sitevisits = Math.ceil(totalTimeTaken / feeSettings.global.workingDay) // divide the total time by working day time to get number of site visits

    const numberofsets = getNumberSets(numberoftests, testtype) 

    // if number of miles <= localRadius then use discounted fee else use standard rate
    const rate = postcode.driving.value / 1609 <= feeSettings[testtype].fee.localRadius ? feeSettings[testtype].fee.localJob : feeSettings[testtype].fee.nonLocalJob;

    // multiply site visits by day rate and add cost of additional sets at the corresponding rate 
    const cost = (sitevisits * rate) + (feeSettings[testtype].fee.additional * (numberofsets - 1))

    console.log(travelCost)

    if (postcode.driving.hasOwnProperty('value')) { // if valid postcode return 
        return {
            fee: cost,
            travel: travelCost >= 25 ? Math.round(travelCost / 25) * 25 : 0, // only charge travel if it is >= £25
            sitevisits
        }
    } else { // if invalid postcode reset fee
        return {
            fee: 0,
            travel: 0,
            sitevisits: 0
        }
    }
}

/**
 * @description get the number of sets of tests
 * @param {Object} numberoftests - breakdown of number of tests by test type 
 * @param {String} testtype - sound/air/survey
 * @return {Int} sets
 */
const getNumberSets = (numberoftests, testtype) => {
    switch (testtype) {
        case "sound":   // assume a set is 2 airborne walls, 2 airborne floors, 2 impacts
            if (numberoftests.hasOwnProperty('Airborne Walls')) { 
                return Math.ceil(numberoftests["Airborne Walls"] / 2)
            } else if (numberoftests.hasOwnProperty('Airborne Floors')) {
                return Math.ceil(numberoftests["Airborne Floors"] / 2)
            } else if (numberoftests.hasOwnProperty('Impacts')) {
                return Math.ceil(numberoftests["Impacts"] / 2)
            }
        case "air":
            if (numberoftests.hasOwnProperty('Air Tests')) {
                return numberoftests["Air Tests"]
            }
        case "survey":
            if (numberoftests.hasOwnProperty('Noise Survey')) {
                return numberoftests["Noise Survey"]
            }
        default:
            return 1
    }
}