import React, { Component } from 'react'
import {Switch, Route, Redirect, withRouter} from 'react-router'
import { connect } from 'react-redux'

import EmptyPage from './components/empty-page'

import Enquiries from './components/enquiries/Enquiries'
import Jobs from './components/jobs/Jobs'
import JobCalendar from './components/jobcalendar/JobCalendar'
import Invoice from './components/invoices/Invoice'
import Cars from './components/cars/Cars'
import JobForm from './components/jobs/JobForm'
import AdminDashboard from './components/dashboard/AdminDashboard'
import EngineerDashboard from './components/dashboard/EngineerDashboard'
import FinanceDashboard from './components/dashboard/Finance Dashboard'
import Book from './components/book/Book'
import Login from './components/auth/Login'
import EnquiryForm from './components/enquiries/EnquiryForm'
import Engineers from './components/engineers/Engineers'
import Kit from './components/kits/Kit'

import {setConfig} from './actions/config'
import ResourceCalendar from './components/resourcecalendar/ResourceCalendar'
import Resources from './components/resourcecalendar/Resources'


function PrivateRoute ({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

class Routes extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.setConfig('collapsed', false)
    }
  }

  render() {
    const { auth } = this.props

    return (<Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/" component={AdminDashboard} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/dashboard" component={AdminDashboard} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/engineerdashboard" component={EngineerDashboard} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/financedashboard" component={FinanceDashboard} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/enquiries" component={Enquiries} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/jobs" component={Jobs} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/jobs/:id" component={JobForm} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/jobcalendar" component={JobCalendar} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/invoice" component={Invoice} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/cars" component={Cars} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/job/create/:type" component={JobForm} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/job/create" component={JobForm} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/book" component={Book} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/enquiry/:id" component={EnquiryForm} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/enquiry/create" component={EnquiryForm} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/engineers" component={Engineers} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/equipment" component={Kit} />
            <PrivateRoute exact authed={auth.isAuthenticated} path="/resourcecalendar" component={Resources} />


            <Route component={EmptyPage} />
          </Switch>
    )
  }
}

const mapStateToProps = ({ auth, config }) => {
  return {
    auth,
    config
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setConfig: (key, value) => dispatch(setConfig(key, value))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
