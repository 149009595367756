import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import { endpoint } from '../utils/api'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGOUT = 'LOGOUT'
export const REGISTER_SUCCESS = 'REGISTER_SUCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

export function loginSuccess(data) {
  return {
      type: LOGIN_SUCCESS,
      payload: data
  }
}

export function loginFail() {
  return {
    type: LOGIN_FAIL    
  }
}

export function logout() {
  return {
    type: LOGOUT    
  }
}

export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    await setAuthToken(localStorage.token)
  }

  try {
    const res = await axios.get(`${endpoint}/api/auth`, {
        headers: {
          Authorization: 'Bearer '+ localStorage.token
        }
      })
      console.log(res)
      console.log(localStorage.token)
      console.log(res.data)
    dispatch({
      type: USER_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    dispatch({
      type: AUTH_ERROR
    })
  }
}