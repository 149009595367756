import {React, useState, useEffect} from 'react'
import { InputGroup, Form, Col, FormControl, Row } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'

import SoundType1 from './categories/soundType1'
import SoundType2 from './categories/SoundType2'
import SoundType3 from './categories/SoundType3'

const SoundForm = ({ job }) => {

    const [soundTestType, setSoundType] = useState(2)
   
    const categories = [
        {
            label: "Part E",
            name: "Part E",
            type: 2
        },
        {
            label: "Part E - RFRP",
            name: "Part E - RFRP",
            type: 2
        },
        {
            label: "Part E - Hotel",
            name: "Part E - Hotel",
            type: 1
        },
        {
            label: "Hotel 24hours",
            name: "Hotel 24hours",
            type: 3
        },
        {
            label: "BB93",
            name: "BB93",
            type: 1
        },
        {
            label: "Office",
            name: "Office",
            type: 1
        },
        {
            label: "HTM",
            name: "HTM",
            type: 1
        }
    ]
    useEffect(() => {
        if( job!== null){
            categories.forEach((category) => {
                if (category.name == job.soundtest.project_type){
                    setSoundType(category.type)
                }
            })
        }
        
    }, [job])
  

    const setCategory = (e) =>{        
        categories.forEach((category) => {
            if (category.name == e.target.value){
                setSoundType(category.type)
            }
        })
    }
    

      return (
        <div className="col-12 col-lg-4">
            
            <Widget title="Sound Testing" icon="speaker" paddingStyle="5px">

                <Form.Group as={Col} controlId="formGridContactName">
                    <Form.Label>Project Type</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">category</i></InputGroup.Text>
                        </InputGroup.Prepend>
                            <Form.Control as="select" name="project_type" onChange={(e) => setCategory(e)}>
                                {categories.map((option) => {
                                    if(job !== null){                                                                                         
                                        return <option selected={job.soundtest.project_type === option.name} value={option.name}>{option.label}</option>
                                    } else {
                                        return <option value={option.name}>{option.label}</option>
                                    }
                                })}                             
                            </Form.Control>
                    </InputGroup>
                </Form.Group>                             
                
                {soundTestType === 1 ? <SoundType1 job = {job} /> : soundTestType === 2 ? <SoundType2 job = {job} /> : <SoundType3 job = {job} />}
                
               
            </Widget>
            </div>
        
    )
}

export default SoundForm