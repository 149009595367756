import React from 'react'
import { Button, Alert } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

export const DateRangePicker = ({ openRange, startDate, endDate, onChange, range }) => {
    return (
        <Alert key={1} variant={"primary"}>
            <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8" style={{verticalAlign: 'middle', display: 'inline-block'}}>
                    <span style={{paddingTop: '0.4rem', fontSize: '1.2rem', display: 'inline-block'}}>Date Range: <b>{range}</b></span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4" style={{textAlign: 'right'}}>
                    <Button className="btn btn-primary" onClick={openRange}><i className="material-icons">event</i>{`     Select Dates`}</Button>

                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        style={{position: 'relative'}}
                    />
                </div>
            </div>
        </Alert>
    )
}
