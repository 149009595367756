import React, { useState, useReducer, useEffect } from 'react'
import { connect } from 'react-redux'
import NewCarModal from './NewCarModal'
import Widget from '../../elements/DashboardWidget'
import AlertComponent from '../alerts/AlertComponent'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import { cars as carsReducer } from '../../reducers/cars'
import { getCars, getResourceBookings } from '../../utils/api'
import RemoveCarModal from './RemoveCarModal'
import BookingsTable from '../engineers/BookingsTable'

const Cars = ({ cars, carbookings, dispatch }) => {
    const [newcarmodal, openNewCar] = useState(false)
    const [removecarmodal, openRemoveCar] = useState(false)
    const [carcreated, carCreated] = useState(false)
    const [carupdated, carUpdated] = useState(false)
    const [carremoved, carRemoved] = useState(false)
    const [carerror, carError] = useState(false)

    const [carsArr, dispatchCar] = useReducer(carsReducer, cars)

    const [bookingupdated, setBookingUpdated] = useState(false)
    const [bookingcreated, setBookingCreated] = useState(false)
    const [bookingerror, setBookingError] = useState(false)

    return (
        <Container id="main-container">
            {carupdated && <AlertComponent type="primary" text="Car Updated!"/>}
            {carcreated && <AlertComponent type="success" text="Car Created!"/>}
            {carremoved && <AlertComponent type="danger" text="Car Removed!"/>}
            {carerror && <AlertComponent type="danger" text={carerror}/>}

            {bookingupdated && <AlertComponent type="primary" text="Booking Updated!"/>}
            {bookingcreated && <AlertComponent type="success" text="Booking Created!"/>}
            {bookingerror && <AlertComponent type="danger" text="Booking Removed!"/>}

            <Row>
                <Col md={12}>
                    <Widget title="Cars" paddingStyle="5px" buttonRight={<Button variant="primary" onClick={() => openNewCar(true)}>+ New Car</Button>}>

                            <Table striped border hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Numberplate</th>
                                        <th>Location</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(carsArr).map((car) => {
                                        if (carsArr[car].is_deleted === false) {
                                            return <tr key={carsArr[car]._id}>
                                                <td>{carsArr[car].name}</td>
                                                <td>{carsArr[car].numberplate}</td>
                                                <td>BS28QF</td>
                                                <td>
                                                    <div style={{width: '100%'}}>
                                                        <div style={{display: "inline-block"}}> 
                                                            <button className="btn btn-primary btn-sm" onClick={() => openNewCar(carsArr[car])}><i className="material-icons">edit</i></button>
                                                        </div>
                                                        <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                                                            <button className="btn btn-danger btn-sm" onClick={() => openRemoveCar(carsArr[car])}><i className="material-icons">delete</i></button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    })}
                                </tbody>
                            </Table>
                        </Widget>
                </Col>
            </Row>     

            <BookingsTable bookings={carbookings} type="car" data={cars} setBookingUpdated={setBookingUpdated} setBookingCreated={setBookingCreated} setBookingError={setBookingError} />

            {newcarmodal && <NewCarModal newcarmodal={newcarmodal} openNewCar={openNewCar} carCreated={carCreated} carUpdated={carUpdated} carError={carError} dispatch={dispatch} dispatchCar={dispatchCar} />}
            {removecarmodal && <RemoveCarModal removecarmodal={removecarmodal} openRemoveCar={openRemoveCar} carRemoved={carRemoved} carUpdated={carUpdated} dispatchCar={dispatchCar} carError={carError}/>}

        </Container>
    )
}

function mapStateToProps({ cars, carbookings }) {
    return {
        cars,
        carbookings
    }
}

export default connect(mapStateToProps)(Cars)