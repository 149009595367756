import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api'

const RemoveCarModal = ({ removecarmodal, openRemoveCar, carRemoved, dispatchCar, carError }) => {
    const removeCar = async () => {
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/api/car/${removecarmodal._id}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        console.log("Message Sent.")
                        console.log(response)                      
                        dispatchCar({ type: 'REMOVE_CAR', carid: response.data.id })
                        carRemoved(true)
                        openRemoveCar(false)
                    } 
                })
                .catch((error) => {
                    carError(error.response.data.msg)
                    openRemoveCar(false)
                })
    }

    return (
        <Modal show={removecarmodal !== false ? true : false} onHide={() => openRemoveCar(false)}>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'red' }}>Delete Car</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to delete this car?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => openRemoveCar(false)}>Close</Button>
                <Button variant="danger" onClick={() => removeCar()}><i className="material-icons">delete</i>&nbsp;Delete Car</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveCarModal