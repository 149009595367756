import React, { useState } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'

const AlertComponent = ({ type, text }) => {
    const [show, setShow] = useState(true);

    if (show) {
    return (
        <Row>
            <Col md={12}>
            <Alert variant={type} onClose={() => setShow(false)} dismissible>
                {text}
            </Alert>
            </Col>
        </Row>
    ) 
    } else 
    {
      return <></>
    }
}

export default AlertComponent