import React, { useState, useEffect } from 'react'
import { InputGroup, Form, Col, FormControl, Button } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'

const Completion = ({ job, setCompletionModal, completion }) => {    
    const [confirmedcheck, setConfirmedCheck] = useState(false)
    const [invoicedcheck, setInvoicedCheck] = useState(false)
    const [paidcheck, setPaidCheck] = useState(false)
    const [issuedcheck, setIssuedCheck] = useState(false)

    useEffect(() => {
        setConfirmedCheck(job !== null ? job.completion.confirmed : false)
        setInvoicedCheck(job !== null ? job.completion.invoiced : false) 
        setPaidCheck(job !== null ? job.completion.paid : false) 
        setIssuedCheck(job !== null ? job.completion.issued : false)
    }, [job])

    return (
        <Widget title="Completion" icon="check" paddingStyle="5px">
            <Form.Group as={Col} controlId="formGridPostcode">
                <Form.Label>Invoice Number</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">payment</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control placeholder="Invoice Number..." name="invoice_number" defaultValue={job !== null ? (job.hasOwnProperty('invoice_number') ? job.invoice_number : '') : ''}/>
                </InputGroup>
            </Form.Group>

            {/**<Form.Group as={Col} controlId="formGridConfimred">
                <input 
                    type="checkbox"
                    label="Confirmed"
                    id="confirmed"
                    name="confirmed" 
                    checked={confirmedcheck}  
                    onChange={() => confirmedcheck ? setConfirmedCheck(false) : setConfirmedCheck(true)}
                />
                <label for="confirmed">&nbsp;&nbsp;&nbsp;&nbsp;Confirmed</label>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridInvoiced">
                <input 
                    type="checkbox"
                    label="Invoiced"
                    id="invoiced"
                    name="invoiced" 
                    checked={invoicedcheck}  
                    onChange={() => invoicedcheck ? setInvoicedCheck(false) : setInvoicedCheck(true)}
                />
                <label for="invoiced">&nbsp;&nbsp;&nbsp;&nbsp;Invoiced</label>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPaid">
                <input 
                    type="checkbox"
                    label="Paid"
                    id="paid"
                    name="paid" 
                    checked={paidcheck}  
                    onChange={() => paidcheck ? setPaidCheck(false) : setPaidCheck(true)}
                />
                <label for="paid">&nbsp;&nbsp;&nbsp;&nbsp;Paid</label>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridIssued">
                <input 
                    type="checkbox"
                    label="Issued"
                    id="issued"
                    name="issued" 
                    checked={issuedcheck}  
                    onChange={() => issuedcheck ? setIssuedCheck(false) : setIssuedCheck(true)}
                />
                <label for="issued">&nbsp;&nbsp;&nbsp;&nbsp;Issued</label>
    </Form.Group>**/}

            {/**{(completion !== {}) && (
                <Form.Group as={Col} controlId="formGridCompletion">
                    <Button onClick={() => setCompletionModal(true)}>Completion</Button>
            </Form.Group>)}**/}


            <Form.Group as={Col} controlId="formGridNotes">
                <Form.Label>Notes:</Form.Label>
                <Form.Control name="notes" as="textarea" rows={2} defaultValue={job !== null ? job.hasOwnProperty('notes') ? job.notes : '' : ''} />
            </Form.Group>

        </Widget>
    )
}

export default Completion