const urls = [
  {
    title: 'Job Management',
    items: [
      {
        url: '/dashboard',
        icon: 'dashboard',
        title: 'Admin Dashboard',
        items: [],
        engineer: false
      },
      {
        url: '/engineerdashboard',
        icon: 'dashboard',
        title: 'Engineer Dashboard',
        items: [],
        engineer: true
      },
      {
        url: '/financedashboard',
        icon: 'dashboard',
        title: 'Finance Dashboard',
        items: [],
        engineer: true
      },
      {
        url: '/enquiries',
        icon: 'mail',
        title: 'Projects',
        items: [],
        engineer: true
      },
      {
        url: '/jobs',
        icon: 'assignment_turned_in',
        title: 'Site Visits',
        items: [],
        engineer: true
      },
      {
        url: '/jobcalendar',
        icon: 'calendar_today',
        title: 'Job Calendar',
        items: [],
        engineer: true
      },
      {
        url: '/resourcecalendar',
        icon: 'calendar_today',
        title: 'Resource Calendar',
        items: [],
        engineer: true
      },

    ]
  },
  {
    title: 'Settings',
    items: [
      {
        url: '/engineers',
        icon: 'perm_identity',
        title: 'Engineers',
        items: [],
        engineer: false
      },
      {
        url: '/equipment',
        icon: 'volume_up',
        title: 'Equipment',
        items: [],
        engineer: false
      },
      {
        url: '/cars',
        icon: 'directions_car',
        title: 'Cars',
        items: [],
        engineer: false
      }
    ]
  }
]
export function navigation(state = Array.from(urls), action) {
  switch (action.type) {
    case 'SET_NAVIGATION':
      return Object.assign({}, state, {
        ...action.navigation
      })
    default:
      return state
  }
}
