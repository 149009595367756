import { RECEIVE_ENQUIRIES, NEW_ENQUIRY, NEW_JOB_TO_ENQUIRY, UPDATE_ENQUIRY, DELETE_JOB_FROM_ENQUIRY } from "../actions/enquiries"

export default function enquiries(state = {}, action) {
    switch(action.type) {
        case RECEIVE_ENQUIRIES:
            return {
                ...state,
                ...action.enquiries
            }
        case NEW_ENQUIRY:
            return {
                ...state,
                [action.enquiry._id]: action.enquiry               
            }
        case UPDATE_ENQUIRY:
            return {
                ...state,
                [action.enquiry._id]: action.enquiry               
            }
        case NEW_JOB_TO_ENQUIRY:
            let enquiry = Object.keys(state).filter(s => state[s]._id === action.enquiry_id)
            let newjobs = []

            for (let job in action.jobs) {
                newjobs.push(job._id);
            }

            return {
                ...state,
                ...state[enquiry].jobs.push(newjobs)
            }
        case DELETE_JOB_FROM_ENQUIRY:
            const enquiryfound = Object.keys(state).filter(s => state[s].jobs.indexOf(action.job_id) > -1)

            if (enquiryfound.length > 0) {
                const enquiry_id = enquiryfound[0]
                return {
                    ...state,
                    [enquiry_id]: state[enquiry_id].jobs.splice(state[enquiry_id].jobs.indexOf(action.job_id), 1)
                }
            } else {
                return {
                    ...state
                }
            }
        default:
            return state
    }
}