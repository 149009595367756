import React, {useState, useEffect} from 'react';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart , Doughnut }   from 'react-chartjs-2'
import { typeTest } from '../../../utils/formatFunctions';
import moment from 'moment'

const TypePieChart = ({year, alljobs, delegatedChart, type}) => {
    
    let yearsJobs = []

    let totalAir = 0;
    let totalSound = 0;
    let totalNoise = 0;
    Object.keys(alljobs).map(j => {
      let flag = true

      if (flag) {
        if (moment(alljobs[j].startjob).year() >= year && moment(alljobs[j].startjob).year() < year +1){
            flag = true
          } else if (year === "All Time") {
            flag = true
          }
          else {
            flag = false
          }
      }      
 
      let typetest = typeTest(alljobs[j]);
      let jobtype = typetest == 'sound' ? 'Sound Test' : (typetest == 'air' ? 'Air Test' : 'Noise Surveys')

      if (flag) {
        if (type !== null) {
          if (type.name == jobtype) {
            flag = true
          } else {
            flag = false
          }
        }
      }
  
      if (flag) {
        if (delegatedChart !== null) {
          let internal = delegatedChart.name == "All External" ? false : true
          let leader = (delegatedChart.name !== "All External" && delegatedChart.name!== "All Internal" ) ? delegatedChart.name : null

          if (leader === null && internal == alljobs[j].delegated) {                    
            flag = true
          } 
          else if (leader !== null && alljobs[j].contact.project_leader == leader) {
            flag = true
          } 
          else {
            flag = false
          }
        }
      }
      
      if (flag) {
        yearsJobs.push(alljobs[j])
      }
    }                      
  )

    yearsJobs.map(j => {
        if(typeTest(j) === "sound") {
            totalSound = totalSound + 1
        } else if (typeTest(j) === "air"){
            totalAir = totalAir +1
        } else {
            totalNoise = totalNoise + 1
        }
    })
    
    const data = {
        labels: ["Air Tests", "Noise Surveys", "Sound Tests"],
        datasets: [
          {
            label: 'Dataset 1',
            datalabels: {
              labels: {
                title: null
              }
            },
            data: [totalAir, totalNoise, totalSound],
            backgroundColor: ['rgba(240, 165, 0, 0.9)', 'rgba(255, 87, 127, 0.9)', 'rgba(15, 82, 186, 0.9)'],
          }
        ]
      };
    return <div >
        
        <Doughnut
            data={data}            
        
            options= {{
                responsive:true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                
                }
            }}             
            
              
        />

    </div>
}

export default TypePieChart