import React from 'react'
import { formatDate, formatFee, formatTime, typeTest } from '../../utils/formatFunctions'


const InvoiceTable = ({ jobs, filter }) => {

  const handleRowClick = (id) => {
    window.location.href = `/jobs/${id}`
  }  

  if (jobs.length > 0) {
   
    if (filter.length > 0) {
      jobs = jobs.filter((j) => j.engineers[0] === filter[0]._id)
    }

    return (
      <div className="table-widget-4">
        <table className="table table-unbordered table-responsive">
          <thead>
            <tr>
                {/* <th>Test Type</th> */}
                <th>Date</th>
                <th>Site Address</th>
                <th>Client</th>
                <th>Project Name </th>
                <th>Project Leader</th>
                <th className="col-hide">Fee</th>
                {/* <th className="col-hide">Invoiced</th>                     */}
            </tr>
          </thead>
          <tbody>
                {jobs.sort((a,b) => (a.startjob > b.startjob) ? 1 : ((b.startjob > a.startjob) ? -1 : 0)).map(j => (
                    <tr className={typeTest(j)+ '-rowcolor'} key={j._id} onClick={() => handleRowClick(j._id) }>
                        {/* <td className="new-line">{typeTest(j) == 'sound' ? 'Sound Test' : typeTest(j) == 'air' ? 'Air Test' : j.survey.type == 'Setup' ? 'Noise Survey: \n Setup' : 'Noise Survey: \n Pickup'}</td> */}
                        <td>{formatDate(j.startjob)}</td>
                        <td>{j.address}</td>
                        <td>{j.contact.name}</td>
                        <td>{j.projectName}</td>
                        <td>{j.contact.project_leader}</td>
                        <td className="col-hide">{formatFee(j.price)}</td>
                        {/* <td className={j.completion.invoiced ? 'col-hide fillGreen' : 'col-hide fillRed'}></td>   */}

                       </tr>
                ))}
          </tbody>
        </table>
    </div>
    )
  } else {
    return (
      <div style={{ textAlign: 'center' }}>
        <b>No jobs in the range...</b>
      </div>
    )
  }
}

export default InvoiceTable