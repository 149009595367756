import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../../actions/auth'
import '../../css/elements/dropdown-user.css'

const DropdownUser = ({ dispatch }) => {
  const logOut = () => {
    dispatch(logout())
  }
  
  return (<div className="navbar-dropdown dropdown-user">
    <div className="dropdown-title">My account</div>
      <div className="dropdown-item" onClick={logOut}>
        <i className="sli-power"/>
        <span className="title">Logout</span>
        <div className="separator" />
      </div>
    </div>)
}

let items = [
  {
    icon: 'sli-power',
    iconColor: 'default',
    name: 'Logout',
    badge: false,
    badgeText: false,
    badgeClass: false
  }
]

const mapStateToProps = ({ cars }) => {
  return {
    cars
  }
}

export default connect(mapStateToProps)(DropdownUser)
