import { capitalizeFirstLetter } from "../../utils/formatFunctions"

const CompletionTrafficLight = ({ completion, setCompletion }) => {
    const updateCompletion = (e, c) => {
        setCompletion({
            ...completion,
            [c]: e.target.className == 'fillRed' ? true : false
        })
    }

    return (
        <div className="col-12 col-lg-12">
            <table className="table table-unbordered table-striped table-responsive">
                <thead>
                    <tr className="pointer">
                        {Object.keys(completion).map((c) => <th className={completion[c] ? 'fillGreen' : 'fillRed'} onClick={e => updateCompletion(e, c)}>{capitalizeFirstLetter(c)}</th>)}
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default CompletionTrafficLight