import { RECEIVE_KIT_BOOKINGS, NEW_KIT_BOOKING, UPDATE_KIT_BOOKING, REMOVE_KIT_BOOKING } from "../actions/kitbookings"

export function kitbookings(state = {}, action) {
    switch(action.type) {
        case RECEIVE_KIT_BOOKINGS:
            return {
                ...state,
                ...action.kitbookings
            }
        case NEW_KIT_BOOKING:
            return {
                ...state,
               [action.booking._id]: action.booking          
            }
        case UPDATE_KIT_BOOKING:
            let updatedbookings = {
                ...state
            };

            let bookings = action.bookings;

            for (let booking in bookings) {
                updatedbookings[bookings[booking]._id] = bookings[booking];
            }

            return updatedbookings
        case REMOVE_KIT_BOOKING:
            let updatedstate = {}
            let newState = Object.keys(state).filter((key) => key !== action.bookingid) // array of keys minus deleted
            newState.forEach(n => (updatedstate[n] = state[n]))

            return updatedstate
        default:
            return state
    }
}