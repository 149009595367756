import React, { useState } from 'react'
import { Modal, Form, Row, Col, InputGroup, Button } from 'react-bootstrap'
import { newCar, updateCar } from '../../actions/cars'
import axios from 'axios'
import { endpoint } from '../../utils/api'

const NewCarModal = ({ newcarmodal, openNewCar, carCreated, carUpdated, carError, dispatch, dispatchCar }) => {
    const [validated, setValidated] = useState(false)

    const handleSubmit = async (e, props) => {
        const form = e.currentTarget
        
        console.log(form.checkValidity())

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
        } else {
            e.preventDefault()

            const formData = new FormData(e.target),
                    formDataObj = Object.fromEntries(formData.entries())
            let carobj

            if (newcarmodal !== true) {
                await axios.put(`${endpoint}/api/car`, formDataObj, {
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            console.log("Message Sent.")
                            carobj = response.data
                            console.log(response)                 
                            dispatchCar({ type: 'UPDATE_CAR', car: carobj })
                            carUpdated(true)
                            openNewCar(false)
                        } else {
                            console.log(response.statusText)  
                            console.log(response.errors)   
                            carError(true)   
                            openNewCar(false)            
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        carError(true)   
                        openNewCar(false)
                    })
            } else {
                await axios.post(`${endpoint}/api/car`, formDataObj, {
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            console.log("Message Sent.")
                            carobj = response.data
                            console.log(response)                 
                            dispatchCar({ type: 'NEW_CAR', car: carobj })
                            carCreated(true)
                            openNewCar(false)
                        } else {
                            console.log(response.statusText)  
                            console.log(response.errors)   
                            carError(true)    
                            openNewCar(false)           
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        carError(true)    
                        openNewCar(false)
                    })
            }
        }
    }

    return (
        <Modal show={newcarmodal} onHide={() => openNewCar(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{newcarmodal !== true ? 'Edit Car' : 'Add New Car'}</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>

                <Form.Control type="hidden" name="car_id" defaultValue={newcarmodal !== true ? newcarmodal._id : ''}/>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Name</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">create</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Car Name..." name="name" defaultValue={newcarmodal !== true ? newcarmodal.name : ''} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a name.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Numberplate</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">directions_car</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Numberplate..." name="numberplate" defaultValue={newcarmodal !== true ? newcarmodal.numberplate : ''} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a numberplate.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Location</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">location_on</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Location..." name="location" defaultValue={newcarmodal !== true ? newcarmodal.location : ''} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a location.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => openNewCar(false)}>Close</Button>
                    <Button variant="success" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default NewCarModal