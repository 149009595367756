import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import '../css/elements/widget.css'

const Widget = ({title, description, children, icon, paddingStyle, enquiry, buttonRight, fontIcon}) => {
  return (
    <div className="widget dashboard-widget" style={{padding: paddingStyle}}>
      <div className="row widget-header">
        <div className="col-12 col-md-9">
          <div className="title">{title}</div>
          {description !== undefined && (<div
            className="description"
            dangerouslySetInnerHTML={{__html: description}}
          />)}
        </div>
        <div className="col-12 col-md-3 ml-auto text-right right-icons">
          {enquiry && (
            <Link 
              to={{
                pathname: '/enquiry/create'
              }}>
                <Button variant="primary">
                  + New Project
                </Button>
            </Link>
          )}
          {fontIcon !== undefined && <FontAwesomeIcon icon={fontIcon} className="fontWidgetIcon" size='2x' />}
          {icon !== undefined && <i className="material-icons">{icon}</i>}
          
          {buttonRight !== undefined && buttonRight}
        </div>
      </div>
      <div className="row">
        <div className="col">{children}</div>
      </div>
    </div>
  )
}

Widget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string
  ]),
  icon: PropTypes.string,
  padding: PropTypes.string,
  enquiry: PropTypes.bool
}

export default Widget
