import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import { Container, Row , InputGroup} from 'react-bootstrap'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Filter } from './Filter'
import { customDayPropGetter, eventStyleGetter, Event } from './calendarUtils'
import { handleInitialData } from '../../actions/shared'
import { typeTest } from '../../utils/formatFunctions'

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = momentLocalizer(moment)

const JobCalendar = ({ jobs, users, cars, kits, dispatch }) => {
  const [engineer, setEngineer] = useState(null)
  const [car, setCar] = useState(null)
  const [kit, setKit] = useState(null)
  const [jobscal, setJobsCal] = useState([])  
  useEffect(() => {
    dispatch(handleInitialData())   
  }, [])

  useEffect(() => {
    let arr = []

    Object.keys(jobs).forEach(j => {
      arr.push(jobs[j])
    })

    if (engineer !== null) {
      arr = arr.filter((job) => job.engineers.includes(engineer.id))
    }
    //TODO: Change to arrays of kit and cars 
    if (car !== null) {
      arr = arr.filter((job) => job.cars.includes(car.id))
    }

    if (kit !== null) {
      arr = arr.filter((job) => job.kits.includes(kit.id))
    }

    setJobsCal(arr)
  }, [engineer, car, kit, jobs])

  let jcal = jobscal.map((job) => {
      return {
          ...job,
          date: moment(job.startjob).toDate(),
          start: moment(job.startjob).toDate(),
          end: moment(job.endjob).toDate(),
          title: /*(typeTest(job) == 'sound' ? 'Sound Test - ' : (typeTest(job) == 'air' ? 'Air Test - ' : 'Noise Survey - ')) +*/ job.address + " - " + job.postcode
      }
  })

  return (
      <Container fluid id="main-container">
        <Row>
          <Filter title="Engineers" id="usersSelect" setFunction={setEngineer} data={users} cols={4} margin="mb-3" />
          <Filter title="Cars" id="carsSelect" setFunction={setCar} data={cars} cols={4} margin="mb-3" />
          <Filter title="Kits" id="kitsSelect" setFunction={setKit} data={kits} cols={4} margin="mb-3" />
        </Row>
        <Calendar
            localizer={localizer}
            events={jcal}
            views={Object.keys(Views).map(k => Views[k])}
            startAccessor="start"
            endAccessor="end"
            dayPropGetter={customDayPropGetter}
            step={30}
            components={{
                event: Event
              }}
            style={{ height: 1600 }}
            eventPropGetter={eventStyleGetter}
        />
        
      </Container>
  )
}

function mapStateToProps({ jobs, users, cars, kits }) {
    return {
        jobs,
        users,
        cars,
        kits
    }
}

export default connect(mapStateToProps)(JobCalendar)