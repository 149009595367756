import React, {useState, useEffect} from 'react';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart , Bar }            from 'react-chartjs-2'
import moment from 'moment'
import { typeTest } from '../../../utils/formatFunctions';

const EngineerJobsChart = ({year, alljobs, users, type, delegatedChart}) => {
  
    let engineers = [];

    Object.keys(users).map(u => {
      engineers.push(users[u].name)
    })
    let yearsJobs = []

    Object.keys(alljobs).map(j => {
      let flag = true

      if (flag) {
        if (moment(alljobs[j].startjob).year() >= year && moment(alljobs[j].startjob).year() < year +1){
            flag = true
          } else if (year === "All Time") {
            flag = true
          }
          else {
            flag = false
          }
      }      
 
      let typetest = typeTest(alljobs[j]);
      let jobtype = typetest == 'sound' ? 'Sound Test' : (typetest == 'air' ? 'Air Test' : 'Noise Surveys')

      if (flag) {
        if (type !== null) {
          if (type.name == jobtype) {
            flag = true
          } else {
            flag = false
          }
        }
      }
  
      if (flag) {
        if (delegatedChart !== null) {
          let internal = delegatedChart.name == "All External" ? false : true
          let leader = (delegatedChart.name !== "All External" && delegatedChart.name!== "All Internal" ) ? delegatedChart.name : null

          if (leader === null && internal == alljobs[j].delegated) {                    
            flag = true
          } 
          else if (leader !== null && alljobs[j].contact.project_leader == leader) {
            flag = true
          } 
          else {
            flag = false
          }
        }
      }
      
      if (flag) {
        yearsJobs.push(alljobs[j])
      }
    }                      
  )
    let totalJobs = [];

    Object.keys(users).map(u => {
      let total = 0
        yearsJobs.map(j => {
          
            if(j.engineers.includes(users[u]._id)){
              total = total + 1
            }
        })
      totalJobs.push(total);
    })  

    const data = {
        labels: engineers,
        datasets: [
          {
            data: totalJobs,
            datalabels: {
              labels: {
                title: null
              }
            },
            backgroundColor: [
              'rgba(49, 145, 240, 1)'
            ],
            borderColor: [
              'rgba(47, 138, 229, 1)'
            ],
            borderWidth: 1.5,
          }         
        ]
      }
    return <div>
        
        <Bar
            data={data}  
            height={300}          
            options={ {
              indexAxis: 'y',
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                elements: {
                  bar: {
                    borderWidth: 2,
                  }
                },
                responsive: true,
                scales: {
                  y: {
                    grid: {
                      display: false,
                      drawBorder: true,                      
                    }   
                  },
                  x: {
                 
                    grid: {
                      display: true,
                      drawBorder: true,
                      drawOnChartArea: true,
                    }  
                  }
                }
              }}
              
            
              
        />

    </div>
}

export default EngineerJobsChart