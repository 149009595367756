import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { InputGroup, Form, Col } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import moment from 'moment'
import { endpoint } from '../../utils/api'

const EquipmentNS = ({ job, dateTime, users, cars, kits, linkJob}) => {
    const [availableSetupEngineers, setAvailableSetupEngineers] = useState(users);
    const [availablePickupEngineers, setAvailablePickupEngineers] = useState(users);
    const [availableSetupCars, setAvailableSetupCars] = useState(cars);
    const [availablePickupCars, setAvailablePickupCars] = useState(cars);
    const [availableKits, setAvailableKits] = useState(kits);
    const [loading, setLoading] = useState(false);

    const [selectedPickupEngineer, setSelectedPickupEngineer] = useState("");
    const [selectedSetupEngineer, setSelectedSetupEngineer] = useState("");
    const [selectedSetupCar, setSelectedSetupCar] = useState("");
    const [selectedPickupCar, setSelectedPickupCar] = useState("");
    const [selectedKit, setSelectedKit] = useState("");

    useEffect(() => {

        if (dateTime !== null) {
            setLoading(true);

            let setupid = -1;
            let pickupid = -1;

            if(job !== null && linkJob !== null){
                if (job !== undefined && linkJob !== undefined){
                    if (job.survey.type === "Setup") {     
                        setupid = job._id;
                        pickupid = linkJob._id
                    } else {
                        setupid = linkJob._id;
                        pickupid = job._id
                    }
                }                
            }
            console.log(setupid);
            console.log(pickupid);
            console.log(job);
            if ( (job !== null && setupid !== -1) || (job === null && setupid === -1)) {
                     //Checks the availability of the Setup Engineer, Car and Kit.
                    axios({
                        method: "POST",
                        url: `${endpoint}/api/job/${setupid}/ns/checkavailability`,
                        headers: {
                            Authorization: 'Bearer '+ localStorage.token
                        },
                        data: {
                            starttimeSetup: dateTime.startjob,
                            endtimeSetup: moment(dateTime.startjob).add(1, 'hours'),
                            starttimePickup: dateTime.endjob,
                            endtimePickup: moment(dateTime.endjob).add(1, 'hours'),
                            linkJobID: pickupid
                        }
                        
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            console.log(response.data[0]);
                            setAvailableSetupCars(response.data[0].cars);
                            setAvailableSetupEngineers(response.data[1].engineers);
                            setAvailableKits(response.data[2].kits);
                            setAvailablePickupCars(response.data[3].cars);
                            setAvailablePickupEngineers(response.data[4].engineers);

                            setLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })     
            }
           
        }  

    }, [dateTime])


    
    useEffect(() => {

        if (linkJob !== undefined && linkJob !== null && users !== undefined)
        {   
            let setup = {}     
            if (job.survey.type === "Setup") {
                setup.engineer = job.engineers[0];
                setup.car = job.cars[0];                       
            } else {
                setup.engineer = linkJob.engineers[0];
                setup.car = linkJob.cars[0];           
            }       
        
            for (const user in users) {
                if (users[user]._id == setup.engineer) { 
                    setSelectedSetupEngineer(users[user].name);
                }              
            }

            for (const car in cars) {
                if (cars[car]._id == setup.car) { 
                    setSelectedSetupCar(cars[car].name);
                }              
            }            
           
            for (const kit in kits) {
                if (kits[kit]._id == job.kits[0]) {                        
                    setSelectedKit(kits[kit].name); 
                }
            }                 
        }
        
    },[job, users, cars, availablePickupEngineers]);

    useEffect(() => {
        if (linkJob !== null &&  linkJob !== undefined && users !== undefined)
        {         
            let pickup = {};
            if (job.survey.type === "Setup") {          
                pickup.engineer = linkJob.engineers[0];
                pickup.car = linkJob.cars[0];                        
            } else {
                pickup.engineer = job.engineers[0];
                pickup.car = job.cars[0];             
            }

            for (const user in users) {      
                if (users[user]._id == pickup.engineer) { 
                    setSelectedPickupEngineer(users[user].name);
                }
            }
            
            for (const car in cars) {    
                if (cars[car]._id == pickup.car) { 
                    setSelectedPickupCar(cars[car].name);
                }
            }          
        }
        
    }, [linkJob, users, cars, availablePickupEngineers]);

    if (loading) {
        return (
            <div className="spinnerdiv">
                <div className="spinner"></div>
            </div>
        )
    }

     
    return (
        <Widget title="Equipment" icon="directions_car" paddingStyle="5px">
            <Form.Group as={Col} controlId="formGridEngineer">
                <Form.Label>Setup</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">account_circle</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" name="setupengineer" onChange={(e) => setSelectedSetupEngineer(e.target.value)}>
                        {Object.keys(users).map((user) => {
                            if (Object.keys(availableSetupEngineers).map((engineer) => availableSetupEngineers[engineer]._id).indexOf(users[user]._id) > -1) {
                                return <option key={users[user]._id} selected={selectedSetupEngineer === users[user].name ? 'selected' : ''} disabled={users[user].is_deleted}>{users[user].name}</option>
                            } else {
                                return <option key={users[user]._id} selected={selectedSetupEngineer === users[user].name ? 'selected' : ''} disabled>{users[user].name}</option>
                            }
                        })}
                    </Form.Control>
                </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEngineer">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">directions_car</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" name="setupcar" onChange={(e) => setSelectedSetupCar(e.target.value)}>
                        {Object.keys(cars).map((car) => {
                            if (cars[car].is_deleted === false) {
                                if (Object.keys(availableSetupCars).map((c) => availableSetupCars[c]._id).indexOf(cars[car]._id) > -1) {
                                    return <option key={cars[car]._id} selected={selectedSetupCar === cars[car].name ? 'selected' : ''} >{cars[car].name}</option>
                                } else {
                                    return <option key={cars[car]._id} selected={selectedSetupCar === cars[car].name ? 'selected' : ''} disabled>{cars[car].name}</option>
                                }
                            }
                        })}
                    </Form.Control>
                </InputGroup>
            </Form.Group>
                    
            <Form.Group as={Col} controlId="formGridEngineer">
                <Form.Label>Pickup</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">account_circle</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" name="pickupengineer" onChange={(e) => setSelectedPickupEngineer(e.target.value)}>
                        {Object.keys(users).map((user) => {
                            if (Object.keys(availablePickupEngineers).map((engineer) => availablePickupEngineers[engineer]._id).indexOf(users[user]._id) > -1) {
                                return <option key={users[user]._id} selected={selectedPickupEngineer === users[user].name ? 'selected' : ''}>{users[user].name}</option>
                            } else {
                                return <option key={users[user]._id} selected={selectedPickupEngineer === users[user].name ? 'selected' : ''} disabled>{users[user].name}</option>
                            }
                        })}
                    </Form.Control>
                </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEngineer">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">directions_car</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" name="pickupcar" onChange={(e) => setSelectedPickupCar(e.target.value)}>
                        {Object.keys(cars).map((car) => {
                            if (cars[car].is_deleted === false) {
                                if (Object.keys(availablePickupCars).map((c) => availablePickupCars[c]._id).indexOf(cars[car]._id) > -1) {
                                    return <option key={cars[car]._id} selected={selectedPickupCar === cars[car].name ? 'selected' : ''}>{cars[car].name}</option>
                                } else {
                                    return <option key={cars[car]._id} selected={selectedPickupCar === cars[car].name ? 'selected' : ''} disabled>{cars[car].name}</option>
                                }
                            }
                        })}
                    </Form.Control>
                </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridKit">
                <Form.Label>Kit</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">speaker_group</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" name="kitsNS" onChange={(e) => setSelectedKit(e.target.value)} >
                        {Object.keys(kits).filter((k) => kits[k].type === "Noise Survey").map((kit) => {
                                if (kits[kit].is_deleted === false) {                                
                                    if (Object.keys(availableKits).map((c) => availableKits[c]._id).indexOf(kits[kit]._id) > -1) {
                                        
                                        return <option key={kits[kit]._id} selected={selectedKit === kits[kit].name ? 'selected' : ''}>{kits[kit].name}</option>
                                    } else {
                                        return <option key={kits[kit]._id} selected={selectedKit === kits[kit].name ? 'selected' : ''} disabled>{kits[kit].name}</option>
                                    }
                                }
                            })
                        }
                    </Form.Control>
                </InputGroup>
            </Form.Group>
        </Widget>
    )
}

export default EquipmentNS