import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { formatDate, formatTelephone, formatFee, formatPostcode, typeTest } from '../../utils/formatFunctions'
import { Dropdown, Row, Col, Container } from 'react-bootstrap'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { soundTestColor, airTestColor, noiseSurveyColor } from '../../utils/colors'
import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react';
import { makeAnimated } from 'react-select'

library.add(faMinusCircle, faPlusCircle);

const EnquiryTable = ({ enquiries, jobs, users }) => {


   const returnType = (type) => {
    if (type === "air"){
      return <td></td>;
    }
  }

    const calcRemaining = (cell, row) => {
  
      let jobsarr = []
      row.jobs.forEach(i => {
        let foundjob = Object.keys(jobs).find( j => jobs[j]._id === i)
        
        if (foundjob !== undefined) {
          jobsarr.push(jobs[foundjob])
        } 
      })

      let totalfee = 0          

      jobsarr.forEach(k => totalfee = totalfee + k.price)
      let difference = row.price - totalfee   

      return '£' + difference

    }

    const checkStatus = (cell, row) => {
  
        let jobsarr = []
            row.jobs.forEach(i => {
              let foundjob = Object.keys(jobs).find( j => jobs[j]._id === i)
              
              if (foundjob !== undefined) {
                jobsarr.push(jobs[foundjob])
              } 
            })
      
            let totalfee = 0
          
            jobsarr.forEach(k => totalfee = totalfee + k.price)

            let completejobs = jobsarr.filter((l) => l.completion.paid && l.completion.issued && l.completion.invoiced && l.completion.confirmed)
  
            if (completejobs.length === jobsarr.length && row.price <= totalfee) {
              return (<span className={`badge badge-success badge-outline`}>
                        Complete
                      </span>)
            } else {
              return (<span className={`badge badge-warning badge-outline`}>
                        In Progress
                      </span>)
            }
      }

        const GetActionFormat = (cell, row) => {
          const editJob = e => {
            e.stopPropagation()
            //window.location.href = '/jobs/' + row._id
          }
  
          const createJob = e => {
            e.stopPropagation()
            console.log("test")
          }
  
          const removeJob = e => {
            e.stopPropagation()
            console.log("delete")
          }
  
          return (
              <div style={{width: '100%'}}>
                  {/**<button type="button" className="btn btn-primary btn-sm">
                      Edit
          </button>**/}
                <div style={{display: "inline-block"}}> 
                  <Link 
                    to={{
                      pathname: '/enquiry/' + row._id
                    }}>
                      <button type="button" className="btn btn-primary btn-sm">
                        <i className="material-icons">open_in_new</i>
                      </button>
                  </Link>
                </div>
                <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
                      Create
                    </Dropdown.Toggle>
  
                    <Dropdown.Menu>
                      <Link 
                      to={{
                        pathname: '/job/create/sound',
                        query: {
                          enquiry_id: row._id
                        } 
                      }}>
                        <Dropdown.Item style={{color: 'black'}} href="#/action-1">Sound Test</Dropdown.Item>
                      </Link>
                      <Link 
                        to={{
                          pathname: '/job/create/air',
                          query: {
                            enquiry_id: row._id
                          } 
                        }}>
                        <Dropdown.Item style={{color: 'black'}} href="#/action-2">Air Test</Dropdown.Item>
                      </Link>
                      <Link 
                      to={{
                        pathname: '/job/create/noise',
                        query: {
                          enquiry_id: row._id
                        } 
                      }}>
                        <Dropdown.Item style={{color: 'black'}} href="#/action-3">Noise Survey</Dropdown.Item>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                  <button type="button" className="btn btn-danger btn-sm">
                    <i className="material-icons">delete</i>
                  </button>
                </div>
              </div>
          )
        }
  
        const defaultSorted = [{
          dataField: 'createdAt',
          order: 'desc'
        }]
  
        const { SearchBar } = Search;
  
        const afterSearch = (newResult) => {
        };
  
        const expandRow = {
          renderer: row => {
            let jobsarr = []
            row.jobs.forEach(i => {
              let foundjob = Object.keys(jobs).find( j => jobs[j]._id === i)
              let allEngineers = []
              if (users !== undefined && foundjob !== undefined ) {
                for (let i = 0; i < jobs[foundjob].engineers.length ; i++) {
                  if (users[jobs[foundjob].engineers[i]] !== undefined) {
                    allEngineers.push(users[jobs[foundjob].engineers[i]].name)
                  }
                }
                jobsarr.push({
                  ...jobs[foundjob],
                  engineer: allEngineers
                })
              } else {
                console.log("not found")
              }
            })
            if (row.jobs.length > 0) {
              return (
                <table className="table table-unbordered table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Date</th>
                      <th className="col-hide">Fee</th>
                      <th>Engineers</th>
                      <th className="col-hide">Paid</th>
                      <th className="col-hide">Issued</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobsarr.map(j => 
                      <tr key={j._id} style={{borderLeft: typeTest(j) == 'sound' ? `0.4rem solid ${soundTestColor}` : typeTest(j) == 'air' ? `0.4rem solid ${airTestColor}` : `0.4rem solid ${noiseSurveyColor}`}}>
                        <td className="new-line">{typeTest(j) == 'sound' ? 'Sound Test' : typeTest(j) == 'air' ? 'Air Test' : j.survey.type == 'Setup' ? 'Noise Survey: \n Setup' : 'Noise Survey: \n Pickup'}</td>
                        <td>{formatDate(j.startjob)}</td>                        
                        <td className="col-hide">{j.price !== undefined && ('£' + j.price)}</td>
                        <td>
                        {j.engineer.map((e, i) => {
                          if (j.engineer.length === i+1){
                            return <>{e}{' '}</>
                          } else {
                            return <>{e}{',  '}</>
                          }                          
                        })}
                        </td>
                        <td className="col-hide" style={{backgroundColor: j.completion.paid ? 'rgba(50,205,50, 0.5)' : 'rgb(255,0,0, 0.5)'}}>{j.completion.paid ? 'Yes' : 'No'}</td>
                        <td className="col-hide" style={{backgroundColor: j.completion.issued ? 'rgba(50,205,50, 0.5)' : 'rgb(255,0,0, 0.5)'}}>{j.completion.issued ? 'Yes' : 'No'}</td>
                        <td>
                          <Link 
                            to={{
                              pathname: `/jobs/${j._id}`,
                              query: {
                                enquiry_id: row._id
                              } 
                            }}>
                              <button className="btn btn-secondary btn-sm">
                                <i className="material-icons">open_in_new</i>
                              </button>
                            </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )
            } else {
              return <div><b>No jobs yet...</b></div>
            }
          },
          showExpandColumn: true,
          expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
              return <FontAwesomeIcon icon={faMinusCircle} color="red" />
            }
            return <FontAwesomeIcon icon={faPlusCircle} color="green" />
          },
          expandColumnRenderer: ({ expanded, rowKey }) => {
            if (enquiries[rowKey].jobs.length > 0) {
              if (expanded) {
                return (
                  <FontAwesomeIcon icon={faMinusCircle} color="red" />
                );
              }
              return (
                <FontAwesomeIcon icon={faPlusCircle} color="green" />
              );
            }
          }
        }
      
      const columns2 = [
        {
          dataField: '_id',
          text: 'ID',
          sort: true,
          hidden: true
        }, {
          dataField: 'projectName',
          text: 'Project Name',
          sort: true, 
        }, {
          dataField: 'contact.project_leader',
          text: 'Project Leader',
          sort: true,
        }, {
          dataField: 'createdAt',
          text: 'Enquiry Date',
          sort: true,
          formatter: formatDate,
          classes: 'col-hide',
          headerClasses: 'col-hide'
        }, {
          dataField: 'address',
          text: 'Address',
          sort: true
        }, {
          dataField: 'postcode',
          text: 'Postcode',
          sort: true,
          classes: 'col-hide centerTable',
          headerClasses: 'col-hide centerTable',
          formatter: formatPostcode
        }, {
          dataField: 'contact.name',
          text: 'Client Name',
          sort: true
        }, {
          dataField: 'contact.telephone',
          text: 'Telephone',
          sort: true,
          formatter: formatTelephone,
          classes: 'col-hide',
          headerClasses: 'col-hide'
        }, {
          dataField: 'price',
          text: 'Fee',
          sort: true,
          formatter: formatFee,
          classes: 'col-hide',
          headerClasses: 'col-hide'
        }, {
          dataField: '',
          text: 'Remaining Fee',
          sort: true,
          formatter: calcRemaining,
          classes: 'col-hide',
          headerClasses: 'col-hide'
        }, {
          dataField: 'status',
          text: 'Status',
          sort: true,
          classes: 'col-hide centerTable',
          headerClasses: 'col-hide centerTable',
          formatter: checkStatus
        },{
          dataField: 'actions',
          text: 'Actions',
          sort: false,
          formatter: GetActionFormat,
          classes: 'col-hide actions-div-projects',
          headerClasses: 'col-hide'
        }]
  
      const data3 = Object.keys(enquiries).map(i => enquiries[i])

    return (
        <ToolkitProvider
            keyField="_id"
            data={ data3 }
            columns={ columns2 }
            search={ { afterSearch } }
        >
            {
                props => (
                    <div>
                        <SearchBar { ...props.searchProps } />
                        <hr />
                        <BootstrapTable
                            { ...props.baseProps }
                            expandRow={expandRow}
                            pagination={ paginationFactory() }
                            defaultSorted={ defaultSorted } 
                            bordered={true}
                            striped
                            hover
                            responsive
                        />
                    </div>
                )
            }
        </ToolkitProvider>
    )
}

const mapStateToProps = ({ users }) => {
  return {
    users
  }
}

export default connect(mapStateToProps)(EnquiryTable)