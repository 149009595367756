import React from 'react'
import { InputGroup, Form, Col } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import GoogleMapComponent from '../dashboard/GoogleMapComponent'
import BookmarkIcon from '@material-ui/icons/Bookmark';
import "../../css/icons/UI-Icons.css"
const Location = ({ job, enquiry }) => (
    <Widget title="Location" icon="location_on" paddingStyle="5px">
        <Form.Group as={Col} controlId="formGridAddress">
            <Form.Label>Project Name</Form.Label>
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text><i className="material-icons">bookmark</i></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control placeholder="Project Name" name="projectName" defaultValue={job !== null ? job.projectName : (enquiry !== null ? enquiry.projectName : '')}/>
                <Form.Control.Feedback type="invalid">
                    Please type the Project Name.
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>


        <Form.Group as={Col} controlId="formGridAddress">
            <Form.Label>Address</Form.Label>
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text><i className="material-icons">location_on</i></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control placeholder="Address..." name="address" defaultValue={job !== null ? job.address : (enquiry !== null ? enquiry.address : '')} required/>
                <Form.Control.Feedback type="invalid">
                    Please choose an address.
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPostcode">
            <Form.Label>Postcode</Form.Label>
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text><i className="material-icons">map</i></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control placeholder="Postcode..." name="postcode" defaultValue={job !== null ? job.postcode : (enquiry !== null ? enquiry.postcode : '')} required/>
                <Form.Control.Feedback type="invalid">
                    Please choose a postcode.
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridLink">
            <a href={"https://www.google.com/maps/search/?api=1&query=" + (job !== null && job.postcode)} style={{ color: 'blue' }}>Get Directions</a>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridMap">
            <GoogleMapComponent jobs={job !== null ? [job] : []} height={14} center={job !== null ? job.geocode : {lat: 51.455517, lng: -2.586151}}/>
        </Form.Group>
    </Widget>
)

export default Location