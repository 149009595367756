import React, {Component} from 'react'
import {connect} from 'react-redux'
import {ConnectedRouter} from 'react-router-redux'
import history from './history'

//global css
import './css/main.css'
import './css/ui-elements/buttons.css'
import './css/icons/material-design-icons.css'

import './css/ui-elements/badges.css'
import './css/forms/react-datetime.css'
import './css/forms/react-select/default.css'
import 'rc-tooltip/assets/bootstrap.css'
import './css/forms/sliders.css'
import './css/icons/simple-line-icons.css'

//structural elements
import LeftSidebar from './elements/left-sidebar/LeftSidebar'
import RightSidebar from './elements/right-sidebar'
import Navbar1 from './elements/navbar-1'
import Navbar2 from './elements/navbar-2'
import TopNavigation1 from './elements/top-navigation-1'
import Backdrops from './elements/backdrops'
import { handleInitialData } from './actions/shared'
import Routes from './Routes'
import { loadUser } from './actions/auth'
import setAuthToken  from './utils/setAuthToken'
import Login from './components/auth/Login'

class App extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_CONFIG',
      config: {
        layout: 'default-sidebar-1',
        navbar: 'light',
        logo: 'info',
        leftSidebar: 'dark'
      }       
    })

    if (localStorage.token) {
      setAuthToken(localStorage.token)
    }

    this.props.dispatch(loadUser());

    this.props.dispatch(handleInitialData())
  }

  render() {
    let {
      layout,
      background,
      navbar,
      topNavigation,
      logo,
      leftSidebar,
      collapsed
    } = this.props.config
    let isEmptyView = layout === 'empty-view-1' ? true : false

    return (
      <ConnectedRouter history={history}>
        <div>
          {!this.props.auth.isAuthenticated ? <Login /> : (
            <div
              data-layout={layout}
              data-background={background}
              data-navbar={navbar}
              data-top-navigation={topNavigation}
              data-logo={logo}
              data-left-sidebar={leftSidebar}
              data-collapsed={collapsed}>
              <Backdrops />
              <Navbar1 />
              <Navbar2 />
              <TopNavigation1 />
              <div className={isEmptyView ? '' : 'container-fluid'}>
                <div className={isEmptyView ? '' : 'row'}>
                  <LeftSidebar />
                  <div className="col main">
                    <Routes />
                  </div>
                </div>
              </div>
            </div>)}
        </div>
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    config: state.config,
    auth: state.auth
  }
}
export default connect(mapStateToProps)(App)
