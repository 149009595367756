import React, {useState, useEffect} from 'react';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart , Doughnut,Pie }   from 'react-chartjs-2'
import { typeTest } from '../../../utils/formatFunctions';
import moment from 'moment'
import { projectLeaders } from '../../../utils/projectLeaders'

const ProjectLeaderJobChart = ({year, alljobs, type, delegatedChart}) => {

    const {leaders} = projectLeaders()
    let leadersArr = [];
    leaders.map(l => {
      leadersArr.push(l.value)
    })


    let yearsJobs = []

    Object.keys(alljobs).map(j => {
      let flag = true

      if (flag) {
        if (moment(alljobs[j].startjob).year() >= year && moment(alljobs[j].startjob).year() < year +1){
            flag = true
          } else if (year === "All Time") {
            flag = true
          }
          else {
            flag = false
          }
      }      
      
      if (flag) {
        if (delegatedChart !== null) {
          let internal = delegatedChart.name == "All External" ? false : true
          let leader = (delegatedChart.name !== "All External" && delegatedChart.name!== "All Internal" ) ? delegatedChart.name : null

          if (leader === null && internal == alljobs[j].delegated) {                    
            flag = true
          } 
          else if (leader !== null && alljobs[j].contact.project_leader == leader) {
            flag = true
          } 
          else {
            flag = false
          }
        }
      }
 
      let typetest = typeTest(alljobs[j]);
      let jobtype = typetest == 'sound' ? 'Sound Test' : (typetest == 'air' ? 'Air Test' : 'Noise Surveys')

      if (flag) {
        if (type !== null) {
          if (type.name == jobtype) {
            flag = true
          } else {
            flag = false
          }
        }
      }
      
      if (flag) {
        yearsJobs.push(alljobs[j])
      }
    }                      
  )

    let totalAA = 0; 
    let totalAR = 0; 
    let totalCBR = 0; 
    let totalCBA = 0; 
    let totalJC = 0; 
    let totalKE = 0; 
    let totalMR = 0; 
    let totalPJ = 0; 
    let totalS = 0; 
    let totalZN = 0; 
    let totalZE = 0; 
    
    yearsJobs.map(j => {
        switch(j.contact.project_leader) {
            case leadersArr[0]:
              totalAA = totalAA + 1
            break;
            case leadersArr[1]:
              totalAR = totalAR + 1
            break;
            case leadersArr[2]:
              totalCBR = totalCBR + 1
            break;
            case leadersArr[3]:
              totalCBA = totalCBA + 1
            break;
            case leadersArr[4]:
              totalJC = totalJC + 1
            break;
            case leadersArr[5]:
              totalKE = totalKE + 1
            break;
            case leadersArr[6]:
              totalMR = totalMR + 1
            break;
            case leadersArr[7]:
              totalPJ = totalPJ + 1
            break;
            case leadersArr[8]:
              totalS = totalS + 1
            break;
            case leadersArr[9]:
              totalZN = totalZN + 1
            break;
            case leadersArr[10]:
              totalZE = totalZE + 1
            break;
            
        }
    })
    
    const vals = [totalAA, totalAR, totalCBR, totalCBA, totalJC, totalKE, totalMR, totalPJ, totalS, totalZN, totalZE]
    const data = {
        labels: leadersArr,
        datasets: [
          {
            label: 'Dataset 1',
            data: vals,
            datalabels: {
              labels: {
                title: null
              }
            },
            backgroundColor:   ['#EF5350', '#EC407A', '#AB47BC', '#7E57C2', '#29B6F6', '#26C6DA', '#26A69A' , '#66BB6A', '#9CCC65', '#D4E157', '#FFEE58' ]
          }
        ]
      };
    return <div >
        
        <Doughnut
            data={data}            
        
            options= {{
                responsive:true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
            }}  
          }           
            
              
        />

    </div>
}

export default ProjectLeaderJobChart