import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { InputGroup, Form, Col, Button } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import { endpoint } from '../../utils/api'
import "../../css/bootstrap/bootstrap-button.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCross } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";


library.add(faCheck, faCross)

const Equipment = ({ job, dateTime, users, cars, kits, type }) => {
    const [availableEngineers, setAvailableEngineers] = useState(users);
    const [availableCars, setAvailableCars] = useState(cars);
    const [availableKits, setAvailableKits] = useState(kits);
    const [loading, setLoading] = useState(false);

    
    const [engineerList, setEngineerList] = useState([]);
    const [carList, setCarList] = useState([]);
    const [kitList, setKitList] = useState([]);       

    const [selectedEngineers, setSelectedEngineers] = useState([""]);
    const [selectedCars, setSelectedCars] = useState([""]);
    const [selectedKits, setSelectedKits] = useState([""]);
    const [kitType, setKitType] = useState("");

   
 
    useEffect(() => {
        if (dateTime.startjob !== "" || dateTime.endjob !== "") { 

            setLoading(true)

            let id = -1;
            if (job != null) {     
                id = job._id;
            }
        
            axios({
                method: "POST",
                url: `${endpoint}/api/job/${id}/checkavailability`,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                },
                data: {
                    starttime: dateTime.startjob,
                    endtime: dateTime.endjob
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    setAvailableEngineers(response.data.engineers)
                    setAvailableCars(response.data.cars)
                    setAvailableKits(response.data.kits)
                    setLoading(false);
                } 
            })
            .catch((error) => {
                console.log(error)
            })
            
        }
    }, [dateTime, job])

    
    useEffect(() => {
        if (type === "sound") {
            setKitType("Sound Testing");
        } else if (type === "air") {
            setKitType("Air Testing");
        }

    }, [type])

    useEffect(() => {
        let eng = [];
        for (let i = 0; i < selectedEngineers.length; i++){
            eng.push(<EngineerField index={i} />)            
        }
        setEngineerList(eng);
    }, [selectedEngineers, availableEngineers])

    useEffect(() => {
        let car = [];
        for (let i = 0; i < selectedCars.length; i++){
            car.push(<CarField index={i} />)            
        }
        setCarList(car);
    }, [selectedCars, availableCars])

    useEffect(() => {
        let kit = [];
        for (let i = 0; i < selectedKits.length; i++){
            kit.push(<KitField index={i} />)            
        }
        setKitList(kit);
    }, [selectedKits, availableKits, kitType])


    useEffect(() => {        
        let selectedEngineersTemp = [""];
        let selectedCarsTemp = [""];
        let selectedKitsTemp = [""];

        if (job !== null && users !== null)
        {            
            for (const engineer in job.engineers) {
                for (const user in users) {
                    if (users[user]._id == job.engineers[engineer]) {                                           
                        selectedEngineersTemp[engineer] = users[user].name; 
                    }
                }
            }               
        }

        if (job !== null && cars !== null)
        {            
            for (const carInjob in job.cars) {
                for (const car in cars) {
                    if (cars[car]._id == job.cars[carInjob]) {                        
                        selectedCarsTemp[carInjob] = cars[car].name; 
                    }
                }
            }               
        }

        if (job !== null && kits !== null)
        {            
            for (const kitInjob in job.kits) {
                for (const kit in kits) {
                    if (kits[kit]._id == job.kits[kitInjob]) {                        
                        selectedKitsTemp[kitInjob] = kits[kit].name; 
                    }
                }
            }               
        }

        setSelectedEngineers(selectedEngineersTemp);        
        setSelectedCars(selectedCarsTemp);
        setSelectedKits(selectedKitsTemp);
    }, [job, users, cars, kits])

    
    const HandleInputEng = (e,index) => {
        let selectedEngCopy = [...selectedEngineers];     
        selectedEngCopy[index] = e.target.value;       
        setSelectedEngineers(selectedEngCopy);
    }

    const HandleInputCar = (e,index) => {
        let selectedCarCopy = [...selectedCars];       
        selectedCarCopy[index] = e.target.value; 
        setSelectedCars(selectedCarCopy);
    }

    const HandleInputKit = (e,index) => {
        let selectedKitsCopy = [...selectedKits];
        selectedKitsCopy[index] = e.target.value; 
        setSelectedKits(selectedKitsCopy);
    }

    //Enginner Field
    const EngineerField = ({ index }) => {
        let fieldName = "engineer" + index.toString();
        
        return (
           
            <Form.Group as={Col} controlId="formGridEngineer">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">account_circle</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" name={fieldName} onChange={(e) => HandleInputEng(e, index) }>
                        {Object.keys(users).map((user) => {
                            if (Object.keys(availableEngineers).map((engineer) => availableEngineers[engineer]._id).indexOf(users[user]._id) > -1) {
                                return (<option key={users[user]._id} selected={selectedEngineers[index] === users[user].name ? 'selected' : ''} disabled={users[user].is_deleted}>{users[user].name}</option>)
                            } else {
                                return <option key={users[user]._id} selected={selectedEngineers[index] === users[user].name ? 'selected' : ''} disabled>{users[user].name}</option>
                            }
                        })}
                    </Form.Control>
                </InputGroup>
            </Form.Group>
        )
    }

    //Car Field
    const CarField = ({ index }) => {
        let fieldName="car" + index.toString();

        return (
            <Form.Group as={Col} controlId="formGridEngineer">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">directions_car</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" name={fieldName} onChange={(e) => HandleInputCar(e, index) }>
                        {Object.keys(cars).map((car) => {
                            if (cars[car].is_deleted === false) {
                                if (Object.keys(availableCars).map((c) => availableCars[c]._id).indexOf(cars[car]._id) > -1) {
                                    return <option key={cars[car]._id} selected={selectedCars[index] === cars[car].name ? 'selected' : ''}>{cars[car].name}</option>
                                } else {
                                    return <option key={cars[car]._id} selected={selectedCars[index] === cars[car].name ? 'selected' : ''} disabled>{cars[car].name}</option>
                                }
                            }    
                        })}
                    </Form.Control>
                </InputGroup>
            </Form.Group>
        )
    }

    //Kit Field
    const KitField =  ({ index }) => {
        let fieldName = "kit" + index.toString();
        return (
            <Form.Group as={Col} controlId="formGridKit">
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text><i className="material-icons">speaker_group</i></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control as="select" name={fieldName} onChange={(e) => HandleInputKit(e, index) }>                  

                
                {    
                    Object.keys(kits).filter((k) => kits[k].type === kitType).map((kit) => {
                        if (kits[kit].is_deleted === false) {
                            if (Object.keys(availableKits).map((c) => availableKits[c]._id).indexOf(kits[kit]._id) > -1) {
                                return <option key={kits[kit]._id} selected={selectedKits[index] === kits[kit].name ? 'selected' : ''}>{kits[kit].name}</option>
                            } else {
                                return <option key={kits[kit]._id} selected={selectedKits[index] === kits[kit].name ? 'selected' : ''} disabled>{kits[kit].name}</option>
                            }
                        }
                    })         
                }      
                    
                </Form.Control>
            </InputGroup>
        </Form.Group>
        )
    }
    
    //Adds Multiple Engineer Fields
    const onEngAddBtnClick = () => {
        setSelectedEngineers(selectedEngineers.concat(""));
       // setEngineerList(engineerList.concat(<EngineerField index={engineerList.length} />))
    }

    const onEngDeleteBtnClick = () => {
        if (selectedEngineers.length > 1) {
            setSelectedEngineers(selectedEngineers.splice(0, selectedEngineers.length - 1));
        }
    }

    //Adds Multiple Car Fields
    const onCarAddBtnClick = () => {
        setSelectedCars(selectedCars.concat(""));
    }

    const onCarDeleteBtnClick = () => {
        if (selectedCars.length > 1) {
            setSelectedCars(selectedCars.splice(0, selectedCars.length - 1));
        }
    }
    
    //Adds Multiple Kit Fields
    const onKitAddBtnClick = () => {
        setSelectedKits(selectedKits.concat(""));
       // setKitList(kitList.concat(<KitField index={kitList.length} type={type} />))
    }

    const onKitDeleteBtnClick = () => {
        if (selectedKits.length > 1) {
            setSelectedKits(selectedKits.splice(0, selectedKits.length - 1)); 
        }
    }
 

    if (loading) {
        return (
            <div className="spinnerdiv">
                <div className="spinner"></div>
            </div>
        )
    } 
     
     


    return (
        <Widget title="Equipment" icon="directions_car" paddingStyle="5px">         
            <Form.Group>
                {engineerList}
                <Button variant="primary"  className="equipmentButton" onClick={onEngAddBtnClick}><i className="material-icons iconAdd">add</i></Button>
                <Button variant="warning"  className="equipmentButton" onClick={onEngDeleteBtnClick}><i className="material-icons iconDelete">remove</i></Button>
            </Form.Group>

            <Form.Group>
                {carList}
                <Button variant="primary" className="equipmentButton" onClick={onCarAddBtnClick}><i className="material-icons iconAdd">add</i></Button>
                <Button variant="warning" className="equipmentButton" onClick={onCarDeleteBtnClick}><i className="material-icons iconDelete">remove</i></Button>
            </Form.Group>

            <Form.Group>
                {kitList}
                <Button variant="primary"  className="equipmentButton" onClick={onKitAddBtnClick}><i className="material-icons iconAdd">add</i></Button>
                <Button variant="warning"  className="equipmentButton" onClick={onKitDeleteBtnClick}><i className="material-icons iconDelete">remove</i></Button>
            </Form.Group>
        </Widget>
    )
}

export default Equipment