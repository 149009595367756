import {React} from 'react'
import { InputGroup, Form, Col, FormControl} from 'react-bootstrap'
import { faBed, faPoundSign } from '@fortawesome/free-solid-svg-icons';
import '../../../css/icons/fontAwesomeIcons.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faBed, faPoundSign)

const SoundType2 = ({job}) => {
    return (
        <div>

                <Form.Group as={Col} controlId="formGridFee">
                    <Form.Label>Fee</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon={faPoundSign}  size='1x' className='poundIcon' /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="Fee..." name="price" type="number" defaultValue={job !== null ? job.price : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose a fee.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Sets</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">toc</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Sets..." name="sets" type="number" defaultValue={job !== null ? job.soundtest.sets : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please enter the number of sets.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Number of Dwellings</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">home</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Dwellings..." name="dwellings" type="number" defaultValue={job !== null ? job.soundtest.dwellings : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose the number of dwellings.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </div>

    )

}

export default SoundType2