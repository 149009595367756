export const RECEIVE_ENQUIRIES = 'RECEIVE_ENQUIRIES'
export const NEW_ENQUIRY = 'NEW_ENQUIRY'
export const NEW_JOB_TO_ENQUIRY = 'NEW_JOB_TO_ENQUIRY'
export const UPDATE_ENQUIRY = 'UPDATE_ENQUIRY'
export const DELETE_JOB_FROM_ENQUIRY = 'DELETE_JOB_FROM_ENQUIRY'

/**
  * @description Receive questions action questions
  * @param {Object} questions - questions
  * @return {Object} RECEIVE_QUESTIONS object 
  */
export function receiveEnquiries(enquiries) {
    return {
        type: RECEIVE_ENQUIRIES,
        enquiries
    }
}

export function newEnquiry(enquiry) {
  return {
      type: NEW_ENQUIRY,
      enquiry
  }
}

export function updateEnquiry(enquiry) {
  return {
      type: UPDATE_ENQUIRY,
      enquiry
  }
}

export function newJobToEnquiry(jobs, enquiry_id) {
  return {
      type: NEW_JOB_TO_ENQUIRY,
      jobs,
      enquiry_id
  }
}

export function deleteJobFromEnquiry(job_id, enquiry_id) {
  return {
      type: DELETE_JOB_FROM_ENQUIRY,
      job_id,
      enquiry_id
  }
}