import React from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import { Event, BookEvent, eventBookStyleGetter } from '../jobcalendar/calendarUtils'

import 'react-big-calendar/lib/css/react-big-calendar.css'

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = momentLocalizer(moment)

const BookCalendar = ({ jobs }) => {
    let allViews = Object.keys(Views).map(k => Views[k])

    let jobscal = []

    Object.keys(jobs).forEach(j => {
      jobscal.push(jobs[j])
    })

    let jcal = jobscal.map((job) => {
        return {
            ...job,
            date: moment(job.startjob).toDate(),
            start: moment(moment(job.startjob).format('L') + ' ' + '09:00').toDate(),//moment(job.startjob).toDate(),
            end: moment(moment(job.endjob).format('L') + ' ' + '13:00').toDate(),
            title: `${job.completion.hasOwnProperty('confirmed') ? job.completion.confirmed === false ? '' : 'STACK ' : 'STACK '}£350 - CT - Black Ford - ST-001`//(job.hasOwnProperty('soundtest') ? 'Sound Test - ' : (job.hasOwnProperty('airtest') ? 'Air Test - ' : 'Noise Survey - ')) + job.address + " - " +  job.postcode
        }
    })

    const bookCustomDayPropGetter = date => {
        const today = new Date()
            if (date <= today) {
                return {
                    className: 'special-day',
                    style: {
                        backgroundColor: '#d3d3d3', // gray
                    },
                }
            } else {
                if (Object.keys(jobs).filter(j => (date.getDate() === new Date(jobs[j].startjob).getDate() && date.getMonth() === new Date(jobs[j].startjob).getMonth())).length === 0) {
                    return {
                        className: 'special-day',
                        style: {
                            backgroundColor: '#9fdf9f', // green
                        },
                    }
                } else {
                    return {
                        className: 'special-day',
                        style: {
                            backgroundColor: '#ff9999', // red
                        },
                    }
                }
            }
        }

    return (
        <Calendar
            localizer={localizer}
            events={jcal}
            views={allViews}
            startAccessor="start"
            endAccessor="end"
            components={{
                event: BookEvent
            }}
            style={{ height: 800 }}
            eventPropGetter={eventBookStyleGetter}
            //dayPropGetter={bookCustomDayPropGetter}
        />
    )
}

export default BookCalendar