import React, {useState, useEffect} from 'react';
import {Chart} from 'chart.js';
import { Chart as ReactChart, Bar }   from 'react-chartjs-2'
import moment from 'moment'
import { typeTest } from '../../../utils/formatFunctions';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const YearRevenueChart = ({year, alljobs, setInternalJobs, setExternalJobs, setProjectedFee, setInvoicedFee, setTotalHours, type, delegatedChart}) => {
    Chart.register(ChartDataLabels);
     let yearsJobs = []
   
    let chosenType = type !== null && type?.name === "Air Test" ? "air" : type?.name === "Sound Test" ? "sound" : "noise"
    let internal = delegatedChart !== null && delegatedChart?.name == "All External" ? false : delegatedChart?.name == "All Internal" ? true : null
    let leader = delegatedChart !== null && (delegatedChart?.name !== "All External" && delegatedChart?.name!== "All Internal" ) ? delegatedChart?.name : null

    Object.keys(alljobs).map(j => {
      let flag = true

      if (flag) {
        if (moment(alljobs[j].startjob).year() >= year && moment(alljobs[j].startjob).year() < year +1){
            flag = true
          } else if (year === "All Time") {
            flag = true
          }
          else {
            flag = false
          }
      }      
 
      let typetest = typeTest(alljobs[j]);
      let jobtype = typetest == 'sound' ? 'Sound Test' : (typetest == 'air' ? 'Air Test' : 'Noise Surveys')

      if (flag) {
        if (type !== null) {
          if (type.name == jobtype) {
            flag = true
          } else {
            flag = false
          }
        }
      }
  
      if (flag) {
        if (delegatedChart !== null) {
          let internal = delegatedChart.name == "All External" ? false : true
          let leader = (delegatedChart.name !== "All External" && delegatedChart.name!== "All Internal" ) ? delegatedChart.name : null

          if (leader === null && internal == alljobs[j].delegated) {                    
            flag = true
          } 
          else if (leader !== null && alljobs[j].contact.project_leader == leader) {
            flag = true
          } 
          else {
            flag = false
          }
        }
      }
      
      if (flag) {
        yearsJobs.push(alljobs[j])
      }
    }                      
  )

    let internalJobs = 0;
    yearsJobs.map(j => {
      if(j.delegated) {
        internalJobs = internalJobs + 1
      }
    })
    setInternalJobs(internalJobs);

    let externalJobs = 0;
    yearsJobs.map(j => {
      if(!j.delegated) {
        externalJobs = externalJobs + 1
      }
    })
    setExternalJobs(externalJobs);

    let projectedFee = 0;
    yearsJobs.map(j => projectedFee = projectedFee + j.price)
    setProjectedFee(projectedFee);

    
    let invoicedFee = 0;
    yearsJobs.map(j => {
      if( j.completion.invoiced) {
        invoicedFee = invoicedFee + j.price
      }   
    })
    setInvoicedFee(invoicedFee);

    setTotalHours(Math.round(yearsJobs.map((job) => moment.duration(moment(job.endjob).diff(moment(job.startjob))).asHours()).reduce((a, b) => a + b, 0)))

    let intjanjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 0);    
    let intfebjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 1);    
    let intmarjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 2);
    let intaprjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 3);
    let intmayjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 4);
    let intjunjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 5);
    let intjuljobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 6);
    let intaugjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 7);
    let intsepjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 8);
    let intoctjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 9);
    let intnovjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 10);
    let intdecjobs = yearsJobs.filter(j => j.delegated && moment(j.startjob).month() === 11);

    let extjanjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 0);    
    let extfebjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 1);    
    let extmarjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 2);
    let extaprjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 3);
    let extmayjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 4);
    let extjunjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 5);
    let extjuljobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 6);
    let extaugjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 7);
    let extsepjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 8);
    let extoctjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 9);
    let extnovjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 10);
    let extdecjobs = yearsJobs.filter(j => !j.delegated && moment(j.startjob).month() === 11);

    const sumijan = intjanjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumifeb = intfebjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumimar = intmarjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumiapr = intaprjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumimay = intmayjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumijun = intjunjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumijul = intjuljobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumiaug = intaugjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumisep = intsepjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumioct = intoctjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const suminov = intnovjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumidec = intdecjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    
    const sumejan = extjanjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumefeb = extfebjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumemar = extmarjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumeapr = extaprjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumemay = extmayjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumejun = extjunjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumejul = extjuljobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumeaug = extaugjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumesep = extsepjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumeoct = extoctjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumenov = extnovjobs.reduce((partialSum, a) => partialSum + a.price, 0);
    const sumedec = extdecjobs.reduce((partialSum, a) => partialSum + a.price, 0);

    let intdata = [sumijan, sumifeb, sumimar, sumiapr, sumimay, sumijun, sumijul, sumiaug, sumisep, sumioct, suminov, sumidec]
    let extdata = [sumejan, sumefeb, sumemar, sumeapr, sumemay, sumejun, sumejul, sumeaug, sumesep, sumeoct, sumenov, sumedec]
    
    const sumjan = sumijan + sumejan
    const sumfeb = sumifeb + sumefeb
    const summar = sumimar + sumemar
    const sumapr = sumiapr + sumeapr
    const summay = sumimay + sumemay
    const sumjun = sumijun + sumejun
    const sumjul = sumijul + sumejul
    const sumaug = sumiaug + sumeaug
    const sumsep = sumisep + sumesep
    const sumoct = sumioct + sumeoct
    const sumnov = suminov + sumenov
    const sumdec = sumidec + sumedec

    const totalsums = [sumjan, sumfeb, summar, sumapr, summay, sumjun, sumjul, sumaug, sumsep, sumoct, sumnov, sumdec]

    const data = {
        labels: ["January","February","March","April","May","June","July","August","September","October","November","December"],
        datasets: [
          {
            label: 'External Jobs',
            data: extdata,
            datalabels: {
              labels: {
                title: null
              }
            },
            backgroundColor: [
              'rgba(49, 145, 240, 1)'
            ],
            borderColor: [
              'rgba(47, 138, 229, 1)'
            ],
            borderWidth: 1.5,
            stack: "Stack 0"
          },
          {
            label: 'Internal Jobs',
            data: intdata,
            
            backgroundColor: [
              'rgba(72, 197, 243, 1)',
            ],
            borderColor: [
              'rgba(62, 172, 228, 1)',
            ],
            borderWidth: 1.5,
            stack: "Stack 0"
          }
        ]
      }
    return <div>
        
        <Bar
            data={data}            
            height={50}
            width={150}
            options={ {
                plugins: {
                  legend: {
                    position: 'right',
                  },
                  datalabels: {
                    anchor: 'end',
                    align: 'top',
                    padding: 0,
                    formatter: (value, context) => {
                      value = totalsums[context.dataIndex].toString();
                      value = value.split(/(?=(?:...)*$)/);
                      value = value.join(',');
                      return "£" + value;   
                    },
                    labels: {
                      title: {
                        color: '#555555'
                      }
                    }
                  },
                },
                elements: {
                  bar: {
                    borderWidth: 2,
                  }
                },
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks:{
                      beginAtZero:true,
                      callback: function(value, index, values) { 
                                value = value.toString();
                                value = value.split(/(?=(?:...)*$)/);
                                value = value.join(',');
                                return "£"+value;     
                                                       
                      }
                    },
                    grid: {
                      display: true,
                      drawBorder: true,
                      
                    }   
                  },
                  x: {
                 
                    grid: {
                      display: true,
                      drawBorder: true,
                      drawOnChartArea: false,
                      position: 'true',
                    }  
                  }
                },
                
              }}
              
            
              
        />

    </div>
}

export default YearRevenueChart