import React from 'react'
import { Button, Alert } from 'react-bootstrap'
import { Filter } from '../jobcalendar/Filter';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const YearRangePicker = ({ allYears, setYear, year }) => {

    const handleChange = (event) => {
        setYear(event.target.value);
      };
    return (
        <Alert key={1} variant={"primary"} >
            <div className="row" >
                <div className="col-lg-9 col-md-8 col-sm-8" style={{verticalAlign: 'middle', display: 'inline-block'}}>
                    <span style={{paddingTop: '0.4rem', fontSize: '1.2rem', display: 'inline-block'}}>Date Range: <b>{year === "All Time" ? "01/01/" + allYears[0] + "- 31/12/" + allYears[allYears.length-1] : "01/01/"+year+" - 31/12/"+year}</b></span>
                </div>
                {/* <div className="col-lg-2 col-md-0 col-sm-0" style={{textAlign: 'right'}}></div> */}
                <div className="col-lg-3 col-md-4 col-sm-4" style={{textAlign: 'right', margin: '0'}}>
                    <Box  style={{textAlign: 'right'}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                        <Select
                        style={{textAlign: 'left'}}
                        value={year}
                        label="Year"
                        onChange={handleChange}
                        >
                        <MenuItem value={"All Time"}>All Time</MenuItem>
                        {allYears.map (y => {
                            return <MenuItem value={y}>{y}</MenuItem>
                        })}
                        </Select>
                    </FormControl>
                    </Box>
                </div>
            </div>
        </Alert>
    )
}
