export const RECEIVE_CAR_BOOKINGS = 'RECEIVE_CAR_BOOKINGS'
export const NEW_CAR_BOOKING = 'NEW_CAR_BOOKING'
export const UPDATE_CAR_BOOKING = 'UPDATE_CAR_BOOKING'
export const REMOVE_CAR_BOOKING = 'REMOVE_CAR_BOOKING'

export function receiveCarBookings(carbookings) {
    return {
        type: RECEIVE_CAR_BOOKINGS,
        carbookings
    }
}

export function newCarBooking(booking) {
  return {
      type: NEW_CAR_BOOKING,
      booking
  }
}

export function updateCarBooking(bookings) {
  return {
      type: UPDATE_CAR_BOOKING,
      bookings
  }
}

export function deleteCarBooking(bookingid) {
  return {
      type: REMOVE_CAR_BOOKING,
      bookingid
  }
}