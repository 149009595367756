import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { deleteEngineerBooking } from '../../actions/engineerbookings'
import { deleteKitBooking } from '../../actions/kitbookings'
import { deleteCarBooking } from '../../actions/carbookings'
import { connect } from 'react-redux'
import { endpoint } from '../../utils/api'

const RemoveBookingModal = ({ removebookingmodal, openRemoveBooking, type, dispatch, setBookingError }) => {
    const removeKit = async () => {
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/api/${type}bookings/${removebookingmodal._id}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        console.log("Message Sent.")
                        console.log(response) 
                        console.log(type)    
                        type === "engineer" ? dispatch(deleteEngineerBooking(response.data.id)) : (type === "kit" ? dispatch(deleteKitBooking(response.data.id)) : dispatch(deleteCarBooking(response.data.id)))                 
                        setBookingError(true)
                        openRemoveBooking(false)
                    } 
                })
                .catch((error) => {
                    openRemoveBooking(false)
                })
    }

    return (
        <Modal show={removebookingmodal !== false ? true : false} onHide={() => openRemoveBooking(false)}>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'red' }}>Delete Booking</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to delete this booking?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => openRemoveBooking(false)}>Close</Button>
                <Button variant="danger" onClick={() => removeKit()}><i className="material-icons">delete</i>&nbsp;Delete Booking</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default connect()(RemoveBookingModal)