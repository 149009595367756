import React from 'react'
import { formatDate, formatFee, formatTime, typeTest } from '../../utils/formatFunctions'


const JobTable = ({ jobs, dashboardType, filter }) => {
  const daysofweek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

  const handleRowClick = (id) => {
    window.location.href = `/jobs/${id}`
  }  

  if (jobs.length > 0) {
    if (filter.length > 0) {
      jobs = jobs.filter((j) => j.engineers[0] === filter[0]._id)
    }

    return (
      <div className="table-widget-4">
        <table className="table table-unbordered table-striped table-responsive">
          <thead>
            <tr>
                <th>Test Type</th>
                <th>Date</th>
                <th className="col-hide">Day</th>
                <th>Start Time</th>
                <th>Site Address</th>
                <th>Client</th>
                {/**<th className="col-hide">Client</th>**/}
                <th>Project Name </th>
                <th>Project Leader</th>
                <th className="col-hide">Fee</th>
                {dashboardType === "admin" && (
                  <>
                    <th className="col-hide">Confirmed</th>
                    <th className="col-hide">Invoiced</th>
                    <th className="col-hide">Checklist Sent</th>
                    <th className="col-hide">RAMS Sent</th>
                    <th className="col-hide">Paid</th>
                    <th className="col-hide">Issued</th>
                  </>)}
                    
                <th className="centerTable">Status</th>
            </tr>
          </thead>
          <tbody>
                {jobs.sort((a,b) => (a.startjob > b.startjob) ? 1 : ((b.startjob > a.startjob) ? -1 : 0)).map(j => (
                    <tr className={daysofweek[new Date(j.startjob).getDay()] + '-rowcolor'} key={j._id} onClick={() => handleRowClick(j._id) }>
                      {/**<Link 
                        to={{
                            pathname: `/jobs/${j._id}`
                        }}>**/}
                        <td className="new-line">{typeTest(j) == 'sound' ? 'Sound Test' : typeTest(j) == 'air' ? 'Air Test' : j.survey.type == 'Setup' ? 'Noise Survey: \n Setup' : 'Noise Survey: \n Pickup'}</td>
                        <td>{formatDate(j.startjob)}</td>
                        <td className="col-hide">{daysofweek[new Date(j.startjob).getDay() - 1]}</td>
                        <td>{formatTime(j, "startjob")}</td>
                        <td>{j.address}</td>
                        <td>{j.contact.name}</td>
                        {/**<td className="col-hide">{j.contact.name}</td>**/}
                        <td>{j.projectName}</td>
                        <td>{j.contact.project_leader}</td>
                        <td className="col-hide">{formatFee(j.price)}</td>

                        {dashboardType === "admin" && (
                          <>
                            <td className={j.completion.confirmed ? 'col-hide fillGreen' : 'col-hide fillRed'}></td>
                            <td className={j.completion.invoiced ? 'col-hide fillGreen' : 'col-hide fillRed'}></td>
                            <td className={j.completion.hasOwnProperty('checklist') ? (j.completion.checklist ? 'col-hide fillGreen' : 'col-hide fillRed') : 'col-hide fillRed'}></td>
                            <td className={j.completion.hasOwnProperty('rams') ? (j.completion.rams ? 'col-hide fillGreen' : 'col-hide fillRed') : 'col-hide fillRed'}></td>
                            <td className={j.completion.paid ? 'col-hide fillGreen' : 'col-hide fillRed'}></td>
                            <td className={j.completion.issued ? 'col-hide fillGreen' : 'col-hide fillRed'}></td>
                          </>)}

                        <td className="centerTable"> 
                          {j.completion.issued ? (
                            <span className={`badge badge-success badge-outline`}>
                                Complete
                            </span>
                          )
                            : (j.completion.confirmed ? (
                            <span className={`badge badge-info badge-outline`}>
                                Confirmed
                            </span>
                          ) : (
                            <span className={`badge badge-warning badge-outline`}>
                                Pending
                            </span>
                          ))}
                        </td>
                  </tr>
                ))}
          </tbody>
        </table>
    </div>
    )
  } else {
    return (
      <div style={{ textAlign: 'center' }}>
        <b>No jobs in the range...</b>
      </div>
    )
  }
}

export default JobTable