import { useState, useEffect } from 'react'
import { Row, Container, Col, InputGroup, Form } from 'react-bootstrap'
import Postcode from './Postcode'
import { calculateDriving, calculateFee, handlePostcode } from './feeFunctions'
import GoogleMapComponent from '../dashboard/GoogleMapComponent'

const AirForm = ({ numberoftests, setNumberTests, postcode, setPostcode, setFeeBreakdown }) => {
    const [types, setTypes] = useState(0)
    const [postcodeerror, setPostcodeError] = useState(false)

    useEffect(() => {
        handlePostcode(postcode, setPostcodeError, setFeeBreakdown, setPostcode)

        if (types > 0) {
            setNumberTests({
                "Air Tests": types
            })
        }
    }, [postcode.text, types])

    useEffect(() => {
        if (postcodeerror === false) {
            const feebreakdown = calculateFee(numberoftests, postcode, "air")
            postcodeerror === false && setFeeBreakdown(feebreakdown)
        }
    }, [postcodeerror, types])

    return (
        <Row>
            <Col lg={12} md={12}>
                <Row>
                    <Col lg={6} md={6} sm={6}>
                        <Form.Group controlId="formGridEnd">
                            <Form.Label>Types</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">home</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="number" placeholder="Types" name="types" controlId="types" onChange={e => setTypes(e.target.value)} required/>
                            </InputGroup>
                        </Form.Group>   
                    </Col>
                    <Postcode postcodeerror={postcodeerror} setPostcode={setPostcode} />
                </Row>

                {(postcodeerror === false && postcode.geocode.lat != '' && postcode.text.length > 2) &&
                    <GoogleMapComponent 
                        jobs={[{
                            _id: 1,
                            geocode: postcode.geocode,
                            address: '',
                            date: new Date()
                        }]}
                        center={postcode.geocode}
                        height={20}
                    />
                }
            </Col>
        </Row>
    )
}

export default AirForm