import React from 'react'
import { Spinner, Button } from 'react-bootstrap'


export const ButtonSpinner = () =>
{
  return (
    <Spinner
    as="span"
    animation="border"
    size="sm"
    role="status"
    aria-hidden="true"
  />
)
}



export const SaveButton = (loading) => {

  return (
      <Button variant="success" type="submit" disabled={loading.loading} >
        <i className="material-icons">save</i>&nbsp;{loading.loading ? <ButtonSpinner/>  : 'Save'}
      </Button>
  ) 
}

