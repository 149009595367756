import React, {useState, useEffect} from 'react';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart , Bar }            from 'react-chartjs-2'
import moment from 'moment';
import { typeTest } from '../../../utils/formatFunctions';

const AllYearsRevenuesChart = ({allYears, alljobs, type, delegatedChart}) => {
    
    let filteredJobs = [];
    Object.keys(alljobs).map(j => {
      let flag = true

      let typetest = typeTest(alljobs[j]);
      let jobtype = typetest == 'sound' ? 'Sound Test' : (typetest == 'air' ? 'Air Test' : 'Noise Surveys')

      if (flag) {
        if (type !== null) {
          if (type.name == jobtype) {
            flag = true
          } else {
            flag = false
          }
        }
      }
  
      if (flag) {
        if (delegatedChart !== null) {
          let internal = delegatedChart.name == "All External" ? false : true
          let leader = (delegatedChart.name !== "All External" && delegatedChart.name!== "All Internal" ) ? delegatedChart.name : null

          if (leader === null && internal == alljobs[j].delegated) {                    
            flag = true
          } 
          else if (leader !== null && alljobs[j].contact.project_leader == leader) {
            flag = true
          } 
          else {
            flag = false
          }
        }
      }
      
      if (flag) {
        filteredJobs.push(alljobs[j])
      }
    }                      
  )

  let yearRevenuesInt = []
  let yearRevenuesExt = []
    allYears.map(y => {
    let totalInt = 0
    let totalExt = 0
      filteredJobs.map(j => { 
        if(moment(j.startjob).year() >= y && moment(j.startjob).year() < y +1) {
                if(j.delegated) {
                  totalInt = totalInt + j.price
                } else {
                  totalExt = totalExt + j.price
                }
        }              
      })
      yearRevenuesInt.push(totalInt);
      yearRevenuesExt.push(totalExt);
    })
   

     

    const data = {
        labels: allYears,
        datasets: [
          {
            label: 'External Jobs',
            data: yearRevenuesExt,
            datalabels: {
              labels: {
                title: null
              }
            },
            backgroundColor: [
              'rgba(49, 145, 240, 1)'
            ],
            borderColor: [
              'rgba(47, 138, 229, 1)'
            ],
            borderWidth: 1.5,
            stack: "Stack 0"
          },
          {
            label: 'Internal Jobs',
            data: yearRevenuesInt,
            datalabels: {
              labels: {
                title: null
              }
            },
            backgroundColor: [
              'rgba(72, 197, 243, 1)',
            ],
            borderColor: [
              'rgba(62, 172, 228, 1)',
            ],
            borderWidth: 1.5,
            stack: "Stack 0"
          }
        ]
      }
    return <div>
        
        <Bar
            data={data}            
            height={50}
            width={150}
            options={ {
                plugins: {
                  legend: {
                    position: 'right',
                  },
                },
                elements: {
                  bar: {
                    borderWidth: 2,
                  }
                },
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks:{
                      beginAtZero:true,
                      callback: function(value, index, values) { 
                                value = value.toString();
                                value = value.split(/(?=(?:...)*$)/);
                                value = value.join(',');
                                return "£"+value;     
                                                       
                      }
                    },
                    grid: {
                      display: true,
                      drawBorder: true,
                      
                    }   
                  },
                  x: {
                 
                    grid: {
                      display: true,
                      drawBorder: true,
                      drawOnChartArea: false,
                      position: 'true',
                    }  
                  }
                }
              }}
              
            
              
        />

    </div>
}

export default AllYearsRevenuesChart