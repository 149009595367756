import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import axios from 'axios'
import { endpoint } from '../../utils/api'
import { Modal, Button, Table, Form, Col } from 'react-bootstrap'

const handleSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const message = document.getElementById('message').value;

   axios({
    method: "POST", 
    url: `${endpoint}/send`, 
    data: {
        name: name,   
        email: email,  
        messsage: message
    }
    }).then((response)=>{
        if (response.data.msg === 'success'){
            alert("Message Sent."); 
            this.resetForm()
        }else if(response.data.msg === 'fail'){
            alert("Message failed to send.")
        }
    })
}

const resetForm = () => {
    document.getElementById('contact-form').reset();
}

const NewJobModal = ({ open, openModal, enquiry_id }) => {

    return (
        <Modal
        show={open}
        onHide={() => openModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Create New Job
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <form id="contact-form" onSubmit={handleSubmit.bind(this)} method="POST">
            <input type="text" className="form-control" id="enquiry_id" name="enquiry_id" value={enquiry_id}/>
            <div className="form-group">
                <label for="name">Name</label>
                <input type="text" className="form-control" id="name" />
            </div>
            <div className="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input type="email" className="form-control" id="email" name="email" aria-describedby="emailHelp" />
            </div>
            <div className="form-group">
                <label for="message">Message</label>
                <textarea className="form-control" rows="5" id="message"></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
        </form>

        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => openModal(false)}>Close</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default NewJobModal