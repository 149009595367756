export const RECEIVE_KIT_BOOKINGS = 'RECEIVE_KIT_BOOKINGS'
export const NEW_KIT_BOOKING = 'NEW_KIT_BOOKING'
export const UPDATE_KIT_BOOKING = 'UPDATE_KIT_BOOKING'
export const REMOVE_KIT_BOOKING = 'REMOVE_KIT_BOOKING'

export function receiveKitBookings(kitbookings) {
    return {
        type: RECEIVE_KIT_BOOKINGS,
        kitbookings
    }
}

export function newKitBooking(booking) {
  return {
      type: NEW_KIT_BOOKING,
      booking
  }
}

export function updateKitBooking(bookings) {
  return {
      type: UPDATE_KIT_BOOKING,
      bookings
  }
}

export function deleteKitBooking(bookingid) {
  return {
      type: REMOVE_KIT_BOOKING,
      bookingid
  }
}