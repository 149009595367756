import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import BookForm from './BookForm'
import BookCalendar from './BookCalendar'
import { Container } from 'react-bootstrap'

const Book = ({ jobs }) => {
  const [showcalendar, setShowCalendar] = useState(false)
  const [testtype, setTestType] = useState('Please Select...')

    return (
        <Container fluid id="main-container">
            <BookForm setShowCalendar={setShowCalendar} setTestType={setTestType} testtype={testtype} />
            
            {showcalendar && <BookCalendar jobs={jobs} />}
        </Container>
    )
}

function mapStateToProps({ jobs }) {
    return {
        jobs
    }
}

export default connect(mapStateToProps)(Book)