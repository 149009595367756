import React from 'react'
import { connect } from 'react-redux'
import Menu from './Menu'
import '../../css/elements/left-sidebar-1.css'

const LeftSidebar = ({ auth, navigation }) => {
  return (
    <div>
      <div className="left-sidebar-placeholder" />
      <div className="left-sidebar left-sidebar-1">
        <div className="wrapper">
          <div className="content">
            {navigation.map((menu, i) => (
                  <div key={i} className="section">
                    <div className="section-title">{menu.title}</div>
                    <ul className="list-unstyled">
                      {menu.items.map((items, i) => <Menu key={i} items={items} />)}
                    </ul>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ auth, navigation }) {
  return {
    auth, 
    navigation
  }
}

export default connect(mapStateToProps)(LeftSidebar)
