import { getInitialData } from '../utils/api'
import { receiveCars } from './cars'
import { setConfig } from './config'
import { receiveEnquiries } from './enquiries'
import { receiveJobs } from './jobs'
import { receiveUsers } from './users'
import { receiveKits } from './kits'
import { receiveCarBookings } from './carbookings'
import { receiveEngineerBookings } from './engineerbookings'
import { receiveKitBookings } from './kitbookings'

/**
  * @description Get initial data
  * @return dispatch receive all data
  */
 export function handleInitialData() {
    return (dispatch) => {
        return getInitialData() // get initial data from api
            .then(({ jobs, users, enquiries, cars, kits, config, engineerbookings, kitbookings, carbookings }) => {
                dispatch(receiveJobs(jobs))
                dispatch(receiveUsers(users))
                dispatch(receiveEnquiries(enquiries))
                dispatch(receiveCars(cars))
                dispatch(receiveKits(kits))
                dispatch(receiveEngineerBookings(engineerbookings))
                dispatch(receiveKitBookings(kitbookings))
                dispatch(receiveCarBookings(carbookings))
                dispatch(setConfig(config))
            })
    }
}