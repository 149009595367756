import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import NewEnquiryModal from './NewEnquiryModal'
import Widget from '../../elements/DashboardWidget'
import { handleInitialData } from '../../actions/shared'
import AlertComponent from '../alerts/AlertComponent'
import NewJobModal from './NewJobModal'
import EnquiryTable from './EnquiryTable'

const Enquiries = ({ dispatch, enquiries, jobs }) => {
    const [modalopen, openModal] = useState(false)
    const [modalopenjob, openModalJob] = useState(false)
    const [enquirycreated, setEnquiryCreated] = useState(false)

    useEffect(() => {
        dispatch(handleInitialData())
    }, [])
    
    return (
        <Container fluid>
            {enquirycreated && <AlertComponent type="success" text="Project Created!"/>}
            <Row>
              <Widget title="Projects"  paddingStyle="5px" enquiry={true}>
                <Col> 
                  <EnquiryTable enquiries={enquiries} jobs={jobs} />
                </Col>
              </Widget>
            </Row>
                
            {modalopen && <NewEnquiryModal open={modalopen} openModal={openModal} created={setEnquiryCreated} />}
            {modalopenjob && <NewJobModal open={modalopenjob} openModal={openModalJob}/>}
        </Container>
    )
}

function mapStateToProps({ enquiries, jobs }) {
    return {
        enquiries,
        jobs
    }
}

export default connect(mapStateToProps)(Enquiries)