import React, { useState } from 'react'
import { Modal, Form, Col, InputGroup, Button } from 'react-bootstrap'
import { newKit, updateKit } from '../../actions/kits'
import axios from 'axios'
import { endpoint } from '../../utils/api'

const NewKitModal = ({ newkitmodal, openNewKit, kitCreated, kitUpdated, kitError, dispatch, dispatchKit }) => {
    const [validated, setValidated] = useState(false)

    const handleSubmit = async (e, props) => {
        const form = e.currentTarget
        
        console.log(form.checkValidity())

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
        } else {
            e.preventDefault()

            const formData = new FormData(e.target),
                    formDataObj = Object.fromEntries(formData.entries())
            console.log(formDataObj)
            let kitobj

            if (newkitmodal !== true) {
                await axios.put(`${endpoint}/api/kits`, formDataObj, {
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            console.log("Message Sent.")
                            kitobj = response.data
                            console.log(response)                 
                            dispatchKit({ type: 'UPDATE_KIT', kit: kitobj })
                            kitUpdated(true)
                            openNewKit(false)
                        } else {
                            console.log(response.statusText)  
                            console.log(response.errors)   
                            kitError(true)   
                            openNewKit(false)            
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        kitError(true)   
                        openNewKit(false)
                    })
            } else {
                await axios.post(`${endpoint}/api/kits`, formDataObj, {
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            console.log("Message Sent.")
                            kitobj = response.data
                            console.log(response)                 
                            dispatchKit({ type: 'NEW_KIT', kit: kitobj })
                            kitCreated(true)
                            openNewKit(false)
                        } else {
                            console.log(response.statusText)  
                            console.log(response.errors)   
                            kitError(true)    
                            openNewKit(false)           
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        kitError(true)    
                        openNewKit(false)
                    })
            }
        }
    }

    return (
        <Modal show={newkitmodal !== false ? true : false} onHide={() => openNewKit(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{newkitmodal !== true ? 'Edit Kit' : 'Add New Kit'}</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>

                <Form.Control type="hidden" name="kit_id" defaultValue={newkitmodal !== true ? newkitmodal._id : ''}/>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Name</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">create</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Kit Name..." name="name" defaultValue={newkitmodal !== true ? newkitmodal.name : ''} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a name.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDate">
                            <Form.Label>Type</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">speaker_group</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="select" name="type" defaultValue={newkitmodal !== true ? newkitmodal.type : ''} required>
                                    <option>Sound Testing</option>
                                    <option>Air Testing</option>
                                    <option>Noise Survey</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a type.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => openNewKit(false)}>Close</Button>
                    <Button variant="success" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default NewKitModal