import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Container, Row, Form, Button } from 'react-bootstrap'
import { newJob, updateJob } from '../../actions/jobs'
import { newJobToEnquiry } from '../../actions/enquiries'
import AlertComponent from '../alerts/AlertComponent'
import { withRouter } from 'react-router-dom'
import SoundForm from './SoundForm'
import AirForm from './AirForm'
import NoiseSurvey from './NoiseSurvey'
import RemoveJobModal from './RemoveJobModal'
import moment from 'moment'
import Completion from './Completion'
import Location from './Location'
import Contact from './Contact'
import Equipment from './Equipment'
import EquipmentNS from './EquipmentNS'
import DateTime from './DateTime'
import DateTimeNS from './DateTimeNS'
import CompletionModal from './CompletionModal'
import CompletionTrafficLight from './CompletionTrafficLight'
import { endpoint } from '../../utils/api'
import { formatTime, typeTest } from '../../utils/formatFunctions'
import { updateCarBooking } from '../../actions/carbookings'
import { updateKitBooking } from '../../actions/kitbookings'
import { updateEngineerBooking } from '../../actions/engineerbookings'
import { SaveButton } from '../saveButton/SaveButton'
import { ContactlessOutlined } from '@material-ui/icons'

const JobForm = ({ match, cars, location, dispatch, users, kits, jobs }) => {
    const [job, setJob] = useState(null)
    const [dateTime, setDateTime] = useState({
        startjob: '',
        endjob: ''
    })
    const [enquiry, setEnquiry] = useState(null)
    const [type, setType] = useState(null)
    const [jobupdated, setJobUpdated] = useState(false)
    const [jobcreated, setJobCreated] = useState(false)
    const [joberror, setJobError] = useState(false)
    const [validated, setValidated] = useState(false)
    const [removejobmodal, openRemoveModal] = useState(false)
    const [invoicecreated, setInvoiceCreated] = useState(false)
    const [completionmodal, setCompletionModal] = useState(false)
    const [completion, setCompletion] = useState({})
    const [linkJob, setLinkJob] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if ('id' in match.params) {
            axios({
                method: "GET", 
                url: `${endpoint}/api/job/${match.params.id}`,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                }
                }).then((response)=> {
                    if (response.status === 200){
                        setJob(response.data)

                        setCompletion(response.data.completion)
                    
                        if (response.data != null) {
                            const updatedDateTime = {                
                                departureTime: response.data.departureTime,
                                startjob: response.data.startjob,
                                endjob: response.data.endjob,
                                returnTime: response.data.returnTime
                            }
                   
                            setDateTime(updatedDateTime)  
                            
                        }
                        
                        typeTest(response.data) == 'sound' ? setType('sound') : (typeTest(response.data) == 'air' ? setType('air') : setType('noise'))
                    } else {
                        console.log("Message failed to send.")
                    }
                })
        }

        if ('type' in match.params) {
            setType(match.params.type)
        }

        if (location.query !== undefined) {
            if ('enquiry_id' in location.query) {
                axios({
                        method: "GET", 
                        url: `${endpoint}/api/enquiry/${location.query.enquiry_id}`,
                        headers: {
                            Authorization: 'Bearer '+ localStorage.token
                        }
                    }).then((response)=> {
                        if (response.status === 200){
                            setEnquiry(response.data)
                        } else {
                            console.log("Message failed to send.")
                        }
                    })
            }
        }
    }, [])

    const handleSubmit = async (e, props) => {

        setLoading(true)
        setJobUpdated(false)
        setJobCreated(false)
        setJobError(false)

        const form = e.currentTarget
        
        console.log(form.checkValidity())

        console.log("Handle submit")
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            console.log("check valid false")
        } else {
            e.preventDefault()

            const formData = new FormData(e.target)
            formData.append('completion', JSON.stringify(completion))
         
            //Add Multiple Engineers
            let engineerArray = [];
            let count = 0;

            while (count < 1000) {
                let extraEngIndex = "engineer" + count.toString();
                let extraEng = formData.get(extraEngIndex)
                
                if (extraEng !== null) {                                     
                    //check if already in array                    
                    if (!engineerArray.includes(extraEng)) {                      
                        engineerArray.push(extraEng)
                    }
                } else {             
                    break;
                }
                count+=1;
            }
            formData.append('engineers', JSON.stringify(engineerArray))

            //Add Multiple Cars
            let carArray = [];
            count = 0;

            while (count < 1000) {
                let extraCarIndex = "car" + count.toString();
                let extraCar = formData.get(extraCarIndex)
                
                if (extraCar !== null) {                                     
                    //check if already in array                    
                    if (!carArray.includes(extraCar)) {                      
                        carArray.push(extraCar)
                    }
                } else {             
                    break;
                }
                count+=1;
            }
            formData.append('cars', JSON.stringify(carArray))

            //Add Multiple Kits
            let kitArray = [];

            count = 0;
            while (count < 1000) {
                let extraKitIndex = "kit" + count.toString();
                let extraKit = formData.get(extraKitIndex)
                
                if (extraKit !== null) {                                     
                    //check if already in array                    
                    if (!kitArray.includes(extraKit)) {                      
                        kitArray.push(extraKit)
                    }
                } else {             
                    break;
                }
                count+=1;
            }
            formData.append('kits', JSON.stringify(kitArray))
            // formData.append('project_category', JSON.stringify(project_category))          
  
            if (type == "noise") {
                formData.append('startjob', new Date(formData.get('date') + ' ' + formData.get('startjob')));
                formData.append('endjob', new Date(formData.get('enddate') + ' ' + formData.get('endjob')));
                formData.append('setupengineer', JSON.stringify([formData.get('setupengineer')]));
                formData.append('setupcar', JSON.stringify([formData.get('setupcar')]));
                formData.append('pickupengineer', JSON.stringify([formData.get('pickupengineer')]));
                formData.append('pickupcar', JSON.stringify([formData.get('pickupcar')]));
                formData.append('kitsNS', JSON.stringify([formData.get('kitsNS')]));
                console.log(JSON.stringify([formData.get('pickupengineer')]));
            } else {
                formData.append('startjob', new Date(formData.get('date') + ' ' + formData.get('startjob')));
                formData.append('endjob', new Date(formData.get('date') + ' ' + formData.get('endjob')));
            }
            
            let formDataObj = Object.fromEntries(formData.entries())

            let jobobj

            console.log("sending")
            if (job !== null) {
                await axios({
                            method: "PUT",
                            url: `${endpoint}/api/job/${job._id}`,
                            headers: {
                                Authorization: 'Bearer '+ localStorage.token
                            },
                            data: formDataObj
                        })
                        .then(function (response) {
                            if (response.status === 200) {
                                console.log("Message Sent.")
                                jobobj = response.data
                                if (type === 'noise') { 
                                    setJobUpdated(true)
                                    setJob(jobobj.jobs[0]) 
                                    setLinkJob(jobobj.jobs[1])                    
                                    dispatch(updateJob(jobobj.jobs[0]))
                                    dispatch(updateJob(jobobj.jobs[1]))
                                    dispatch(updateCarBooking(jobobj.carbookings[0]))
                                    dispatch(updateCarBooking(jobobj.carbookings[1]))
                                    dispatch(updateKitBooking(jobobj.kitbookings))
                                    dispatch(updateEngineerBooking(jobobj.engineerbookings[0]))
                                    dispatch(updateEngineerBooking(jobobj.engineerbookings[1]))
                                    window.location.reload()
                                    
                                } else {
                                    
                                    setJobUpdated(true)
                                    setJob(jobobj.jobs[0])                   
                                    dispatch(updateJob(jobobj.jobs))
                                    dispatch(updateCarBooking(jobobj.carbookings))
                                    dispatch(updateKitBooking(jobobj.kitbookings))
                                    dispatch(updateEngineerBooking(jobobj.engineerbookings))
                                }   
                            } else {
                                console.log(response.statusText)  
                                console.log(response.errors)   
                                setJobError(true)               
                            }   
                        })
                        .catch((error) => {
                            console.log("error")
                            console.log(error)
                            setJobError(true)   
                        })

            } else {
                await axios({
                            method: "POST",
                            url: `${endpoint}/api/job`,
                            headers: {
                                Authorization: 'Bearer '+ localStorage.token
                            },
                            data: formDataObj
                        })
                        .then(function (response) { 
                            if (response.status === 200) {
                                console.log("Message Sent.")
                                jobobj = response.data;  
                                setJobCreated(true)  
                                setJob(response.data[0])                     
                                dispatch(newJob(jobobj))    
                                dispatch(newJobToEnquiry(jobobj, formDataObj.enquiry_id))  

                                /**
                                 * TODO - refactor to react-router v5
                                 * const history = useHistory();
                                 * history.push(`/jobs/${jobobj[0]._id}`);
                                 */
                                                                
                                window.location.href = `/jobs/${jobobj[0]._id}`
                            } else {
                                console.log(response.statusText)  
                                console.log(response.errors)       
                                setJobError(true)              
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setJobError(true)   
                        })
            }
        }
        setLoading(false)
    }

    const generateEmail = () => {
        window.location.href = "mailto:" + job.contact.name + "?subject=" + (typeTest(job) == 'sound' ? "Sound Testing: " : (typeTest(job) == 'air' ? 'Air Testing: ' : 'Noise Survey: ')) + job.address + "&body=" + "Dear Sir or Madam, %0D%0A%0D%0AThank you for booking Sound Testing  with JP Testing, please find attached a copy of our invoice and see the details below as confirmation for your appointment.%0D%0A%0D%0A" + "Test Date: " + moment(job.startjob).format('D/MM/YYYY') + "%0D%0A" + "Site Address: " + job.address + "%0D%0A" + "Contact: " + job.contact.name + " - " + job.contact.telephone + "%0D%0A" + "Start Time: " + formatTime(job, 'startjob') + "%0D%0A%0D%0A" + "We also ask you to provide site plans in advance so that calculations can be undertaken prior to arriving.%0D%0A%0D%0A" + "Additional Information:%0D%0A" + "Please find attached a site checklist to ensure the development is ready for testing. This should be checked prior to our arrival on site as any delays due to no fault of our own will be charged at cost. Please see https://www.jptesting.co.uk for further details.%0D%0A%0D%0A" + "Payment Terms:%0D%0A" + "JP Testing  will require payment prior to issuing any reports or certification. This may be paid via bank transfer (details in invoice), or by cash, card or cheque when we see you on site. If you wish to cancel or amend your booking, you must inform JP Testing  no later than 48 hours before the scheduled booking time (Tel: 0843 309 4504). A late cancellation fee of 75% of the invoiced testing amount is payable if you fail to inform JP Testing  of a cancellation to your booking within the aforementioned window. %0D%0A%0D%0A" + "Thank you for booking with JP Testing , if you have any further queries please do not hesitate to contact us."    
    }

    const invoice = () => {
        return axios({
                    url: `${endpoint}/api/job/${job._id}/invoice`,
                    method: "POST",
                    responseType: 'blob'
                })
                .then(function (response) {
                    console.log(response)
                    if (response.status === 200) {
                        console.log("Message Sent.")

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${job.invoice_number}_${job.address}.docx`); 
                        document.body.appendChild(link);
                        link.click();

                        setInvoiceCreated(true)
                    } else {
                        
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
    }


    return (
        <Container fluid id="main-container">
            {jobupdated && <AlertComponent type="primary" text="Job Updated!"/>}
            {jobcreated && <AlertComponent type="success" text="Job Created!"/>}
            {joberror && <AlertComponent type="danger" text="Car or Kit in Use!"/>}

            {job !== null && <CompletionTrafficLight completion={completion} setCompletion={setCompletion} />}

            <Form onSubmit={handleSubmit}>

                <Form.Control type="hidden" name="job_id" defaultValue={match.params !== undefined ? match.params.id : ''}/>
                <Form.Control type="hidden" name="enquiry_id" defaultValue={location.query !== undefined ? location.query.enquiry_id : ''}/>

                <Row>

                    <div className="col-12 col-lg-4">
                        {type === "noise" ? <DateTimeNS dateTime={dateTime} setDateTime={setDateTime} type={type} job={job} setLinkJob={setLinkJob}/> : <DateTime dateTime={dateTime} setDateTime={setDateTime} type={type} job={job} />}
                    </div>
                    
                    <div className="col-12 col-lg-4">
                        {type === "noise" ? 
                        <EquipmentNS dateTime={dateTime} job={job} users={users} cars={cars} kits={kits} linkJob={linkJob} />
                        : <Equipment dateTime={dateTime} job={job} users={users} cars={cars} kits={kits} type={type} linkJob={linkJob}/>
                            }
                        
                    </div>

                    {type === 'sound' && <SoundForm job={job} />}
                    {type === 'air' && <AirForm job={job} />}
                    {type === 'noise' && <NoiseSurvey
                    
                     job={job} linkJob={linkJob} />}

                    <div className="col-12 col-lg-4">
                        <Contact job={job} enquiry={enquiry} />
                    </div>

                    <div className="col-12 col-lg-4">
                        <Location job={job} enquiry={enquiry} />
                    </div>
                    
                    <div className="col-12 col-lg-4">
                        <div>
                            
                        <Completion job={job} setCompletionModal={setCompletionModal} completion={completion}/>

                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'inline-block' }}>
                                    <SaveButton loading={loading}  />
                                </div>
                                {job !== null &&
                                    <div style={{ display: 'inline-block', paddingLeft: '3px'}}>
                                        <Button variant="primary" onClick={invoice}>
                                            <i className="material-icons">file_download</i>&nbsp;Invoice
                                        </Button>
                                    </div>
                                }
                                {job !== null &&
                                    <div style={{ display: 'inline-block', paddingLeft: '3px'}}>
                                        <Button variant="default" onClick={generateEmail}>
                                            <i className="material-icons">email</i>&nbsp;Email
                                        </Button>
                                    </div>
                                }
                                <div style={{ display: 'inline-block', paddingLeft: '3px'}}>
                                    <Button variant="danger" onClick={() => openRemoveModal(true)}>
                                        <i className="material-icons">delete</i>&nbsp;Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {removejobmodal && <RemoveJobModal removejobmodal={removejobmodal} openRemoveModal={openRemoveModal} jobid={job !== null ? job._id : null} dispatch={dispatch} enquiryid={enquiry !== null ? enquiry._id : null}/>}
                    {(completionmodal && completion !== {}) && <CompletionModal completionmodal={completionmodal} setCompletionModal={setCompletionModal} completion={completion} setCompletion={setCompletion} />}

                </Row>
            </Form>
        </Container>
    )
}

function mapStateToProps({ cars, enquiries, users, kits, jobs }) {
    return {
        cars,
        enquiries,
        users,
        kits,
        jobs
    }
}

export default connect(mapStateToProps)(withRouter(JobForm))