export const formatDate = (cell, row, color) => {
    let date = new Date(cell)
    return (
      <span>{date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()}</span>
    )
  }

export const formatTelephone = (cell, row) => {
    if (cell !== undefined) {
      return (
        <a href={`tel:0${cell}`}>{`0${cell}`}</a>
      )
    } else {
      return <span>-</span>
    }
  }

export const formatPostcode = (cell, row) => {
  if (cell !== undefined) {
    return (
      <a href={`https://www.google.com/maps/search/?api=1&query=${cell}`}>{cell}</a>
    )
  } else {
    return <span>-</span>
  }
}

export const formatTime = (timeObj, property) => {
  if (timeObj !== null && timeObj.hasOwnProperty(property)) {
    let date = new Date(timeObj[property])
    return `${(date.getHours() < 10 ? "0" + date.getHours() : date.getHours())}:${(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())}`
  } else {
    return ''
  }
}

export const formatFee = (cell, row) => {
  if (cell !== undefined) {
    return '£' + cell
  } else {
    return ''
  }
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const typeTest = doc => {
  if (doc.hasOwnProperty('soundtest') && Object.keys(doc.soundtest).length > 0) {
    return 'sound';
  } else if (doc.hasOwnProperty('airtest') && Object.keys(doc.airtest).length > 0) {
    return 'air';
  } else if (doc.hasOwnProperty('survey') && Object.keys(doc.survey).length > 0) {
    return 'noise';
  }

  return null;
}

export default { formatDate, formatTelephone, formatTime, formatFee, capitalizeFirstLetter, typeTest }