import {React, useState }from 'react'
import { OverlayTrigger, Popover, InputGroup } from 'react-bootstrap'
import GoogleMapComponent from '../dashboard/GoogleMapComponent'
import { Link } from 'react-router-dom'
import { soundTestColor, airTestColor, noiseSurveyColor } from '../../utils/colors'
import { library } from "@fortawesome/fontawesome-svg-core";
import { formatTime, typeTest } from '../../utils/formatFunctions'
import "../../css/icons/UI-Icons.css"

//Material Ui Icons
import SpeakerIcon from '@material-ui/icons/Speaker';

//Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHardHat, faCommentDollar, faClipboardList, faFileInvoice, faFan, faTools, faTruckPickup } from '@fortawesome/free-solid-svg-icons';

library.add(faHardHat, faCommentDollar, faClipboardList, faFileInvoice, faFan, faTools, faTruckPickup);

export const customDayPropGetter = date => {

    const today = new Date()

        if (date < today) {
            return {
                className: 'special-day',
                style: {
                    backgroundColor: '#d3d3d3', // gray
                },
            }
        } else {
            return {}
        }
};

export const eventStyleGetter = (event, start, end, isSelected) => {
  let backgroundColor = '#D9CAB3'

  if (typeTest(event) == 'sound') {
    backgroundColor = soundTestColor
  } else if (typeTest(event) == 'air') {
    backgroundColor = airTestColor
  } else {
    backgroundColor = noiseSurveyColor
  }
 
  var style = {
    backgroundColor: backgroundColor,
    opacity: '',
    borderRadius: '10px',
    color: '#C9CCD5',
    border: '',
    display: 'block',                                                                                         
    height: '50px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    fontWeight: 'bold',
    fontSize: '13px'
  }

  // Changes css if Job is confirmed, not used currently
  if(event.completion !== undefined && event.completion.confirmed){
      style.opacity = 1
      style.border = 'solid 3px #3F3E3E'
  } else {
      style.opacity = 0.9
      style.border = 'solid 2.5px #717171'
  }
  //Sets styles if the job has been completed.
  if(event.completion !== undefined && event.completion.issued){
    style.backgroundColor = "#42E6A4",
    style.border = 'solid 4px #7FC8A9',
    style.borderStyle = "solid"
  }
 
  return {
      style: style
  }
};

export const eventResourceStyleGetter = event => {
  let backgroundColor = '#ff6961'

  var style = {
    backgroundColor: event.title.indexOf("Site Visit") === -1 && backgroundColor,
  }
  return {
      style: style
  }
};

export const eventBookStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = 'rgb(144,238,144)'
  
    var style = {
      backgroundColor: event.completion.hasOwnProperty('confirmed') ? event.completion.confirmed === false ? 'rgb(255, 178, 102)' : backgroundColor : backgroundColor,
      opacity: event.completion.hasOwnProperty('confirmed') ? !event.completion.confirmed ? 0.8 : 1 : 1,
      borderRadius: '0px',
      color: 'black',
      border: event.completion.hasOwnProperty('confirmed') ? event.completion.confirmed === false ? 'solid 3px orange' : 'solid 3px green' : 'solid 3px green',
      display: 'block',
      height: '50px',
      overflowWrap: 'break-word',
      wordWrap: 'break-word'
    }
    return {
        style: style
    }
  };

export function Event({ event }) {
    let popoverClickRootClose = (
      <Popover id="popover-trigger-click-root-close" style={{ zIndex: 10000, width: '500px' }}>
        <Popover.Title>{typeTest(event) == 'sound' ? 'Sound Test' : typeTest(event) == 'air' ? 'Air Test' : 'Noise Survey'} - {formatTime(event, 'startjob')}  - {formatTime(event, 'endjob')}</Popover.Title>
        <Popover.Content>
          <b>Address: </b>{event.postcode}<br/>
          <div style={{textAlign:'center'}}><br/>

          {event.hasOwnProperty('geocode') && <GoogleMapComponent jobs={[event]} height={25} center={event.geocode} />}

          <Link 
            to={{
              pathname: `/jobs/${event._id}`
            }}>
            <button className="btn btn-primary btn-xs">Go To Job</button>
          </Link>
          </div>
        </Popover.Content>
      </Popover>
    )
    
    let issuedColour = "#4B6587"
    return (
      <div>
        <div className="calendarEntry">
          <OverlayTrigger id="help" trigger="click" rootClose container={this} placement="bottom" overlay={popoverClickRootClose}>
          
            <div className={(event.completion && event.completion.confirmed) ? (event.completion && event.completion.issued) ? 'issued':'verified':'notverified'} >
              {typeTest(event) == 'air' && <FontAwesomeIcon icon={faFan} className="iconNoise" style={(event.completion && event.completion.issued) && {"--color": issuedColour}} size='2x' />}
              {typeTest(event) == 'sound' && <SpeakerIcon className={(event.completion && event.completion.issued) ? "iconTypeIssued" : "iconType"}  />}
              {(event.survey != null && event.survey.type === "Setup") && <FontAwesomeIcon icon={faTools} className="iconNoise" size='2x' style={(event.completion && event.completion.issued) && {"--color": issuedColour}} />}
              {(event.survey != null && event.survey.type === "Pickup") && <FontAwesomeIcon icon={faTruckPickup} className="iconNoise" size='2x' style={(event.completion && event.completion.issued) && {"--color": issuedColour}}/>}

              {(event.completion && event.completion.checklist) && <FontAwesomeIcon icon={faClipboardList} className="iconChecklist" size='2x' style={(event.completion && event.completion.issued) && {"--color": issuedColour}}/> }
              {(event.completion &&  event.completion.paid) && <FontAwesomeIcon icon={faCommentDollar} className="iconPaid" size='2x' style={(event.completion && event.completion.issued) && {"--color": issuedColour}}/> }
              {(event.completion && event.completion.rams) && <FontAwesomeIcon icon={faHardHat} className="iconRams" size='2x' style={(event.completion && event.completion.issued) && {"--color": issuedColour}} />}
              {(event.completion && event.completion.issued) && <FontAwesomeIcon icon={faFileInvoice} className="iconIssued" size='2x' style={(event.completion && event.completion.issued) && {"--color": issuedColour}} />}

              {event.projectName !== null && event.projectName}
              {event.projectName == null && event.title}
              
            </div>
            
          </OverlayTrigger>
        </div>
      </div>
    )
  };

  export function BookEvent({ event }) {
    let popoverClickRootClose = (
      <Popover id="popover-trigger-click-root-close" style={{ zIndex: 10000, width: '500px' }}>
        <Popover.Title>Book Test</Popover.Title>
        <Popover.Content>
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <th>Fee</th>
                <td>{`£${event.price}`}</td>
              </tr>
              <tr>
                <th>Postcode</th>
                <td>{event.postcode}</td>
              </tr>
              <tr>
                <th>Car</th>
                <td>Silver Ford</td>
              </tr>
              <tr>
                <th>Kit</th>
                <td>ST-001</td>
              </tr>
            </tbody>
          </table>


          <div style={{textAlign:'center'}}><br/>

            <button className="btn btn-primary btn-xs">Book</button>

          </div>
        </Popover.Content>
      </Popover>
    )
  
    return (
      <div>
        <div>
          <OverlayTrigger id="help" trigger="click" rootClose container={this} placement="bottom" overlay={popoverClickRootClose}>
            <div>{event.title}</div>
          </OverlayTrigger>
        </div>
      </div>
    )
  };