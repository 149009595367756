import React from 'react'
import { InputGroup, Form, Col } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import { formatTime } from '../../utils/formatFunctions'
import moment from 'moment'

const DateTime = ({ dateTime, setDateTime, type, job, jobs }) => {
    const formatDate = () => {
        if (dateTime !== null) {
            let date = new Date(dateTime.startjob)
           
            return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2)
        } else {
            return ''
        }
    }

    const onDateTimeChange = e => {
        const startdate = document.querySelector('[controlId="date"]').value
        const starttime = document.querySelector('[controlId="startjob"]').value
        const endtime = document.querySelector('[controlId="endjob"]').value

        let startjob = moment(startdate + " " + starttime)
        let endjob = moment(startdate + " " + endtime)
        
        /*if (window.location.href.indexOf("create") > -1) {
            // Check if date is in past
            if (startjob < now || endjob < now) {            
                document.querySelector('[controlId="date"]').value = now.format("yyyy-MM-DD")     
                document.querySelector('[controlId="startjob"]').value = now.format("HH:MM")
                document.querySelector('[controlId="endjob"]').value = now.format("HH:MM")
                return;
            }
        }*/

        // handle if null
        //if (startdate != ""  && starttime != "" && endtime != "") {
        //    if (startjob > endjob) {
       //         endjob = startjob
        //    }

            setDateTime({                
                startjob,
                endjob
            })
        //}
    }

    return (
        <Widget title="Date & Time" icon="date_range" paddingStyle="5px">
            <>
                <Form.Group as={Col} controlId="formGridDate">
                    <Form.Label>Test Date</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">event</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="date" controlId="date" placeholder="Test Date" name="date" defaultValue={formatDate()} value={formatDate()} onChange={onDateTimeChange} required />
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridStart">
                    <Form.Label>Start</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">access_time</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="time" placeholder="Start Time" name="startjob" controlId="startjob" defaultValue={formatTime(dateTime, "startjob")} value={formatTime(dateTime, "startjob")} onChange={onDateTimeChange} required />
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEnd">
                    <Form.Label>End</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">access_time</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="time" placeholder="End Time" name="endjob" controlId="endjob" defaultValue={formatTime(dateTime, "endjob")} value={formatTime(dateTime, "endjob")} onChange={onDateTimeChange} required />
                    </InputGroup>
                </Form.Group>   
            </>
            
            <div style={{paddingLeft: "10px"}}>
            {/**   <Form.Label><b>Depart: {dateTime !== null ? formatTime(dateTime.departureTime) : ''} - Return: {dateTime !== null ? formatTime(dateTime.returnTime) : ''} </b></Form.Label> */}
            </div>     

        </Widget>
    )
}

export default DateTime