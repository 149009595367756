import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { GOOGLE_MAPS_API_KEY } from '../../utils/api'

const Marker = ({ text, color }) => <i style={{ color: color }} className={'fa fa-3x fa-map-marker'} />

class GoogleMapComponent extends Component {
  static defaultProps = {
    center: {
        lat: 51.455517, 
        lng: -2.586151
    },
    zoom: 7
  }

  render() {
    const { jobs } = this.props
    const colors = ["yellow", "green", "purple", "blue", "orange", "black", "red"]

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: `${this.props.height}vh`, width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={7}
        >
          {Object.keys(jobs).map(j => {
            if (jobs[j].hasOwnProperty('geocode')) {
              return <Marker
                        key={jobs[j]._id}
                        lat={jobs[j].geocode.lat}
                        lng={jobs[j].geocode.lng}
                        text={jobs[j].address}
                        color={colors[new Date(jobs[j].startjob).getDay()]}
                      />
            }
          })}
        </GoogleMapReact>
      </div>
    )
  }
}

export default GoogleMapComponent