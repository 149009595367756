import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import axios from 'axios'
import { newEnquiry } from '../../actions/enquiries'
import { connect } from 'react-redux'

import { Modal, Button, Table, Form, Col } from 'react-bootstrap'
import { endpoint } from '../../utils/api'

class NewEnquiryModal extends Component {

    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    /* 
    Date of Enquiry
    Site name
    Site post code

    Client Name
    client phone number    
    Client Email

    Fee - calculate fee option?
   
    Client Company
    Client Address?

    Types:
        - SI: Fee
             - Number of sets?? - Number of dwellings
        - NS: Fee
            Type,
            Number of mics needed? / types of measurements? 

            Mic locations? - jobs?
            
        - AT: Fee
              Number of types

        - 

    Notes


    Won? / Confirmed ???
    */



    async handleSubmit(e, props) {
        e.preventDefault()

        const formData = new FormData(e.target),
                formDataObj = Object.fromEntries(formData.entries())
          console.log(formDataObj)

        let newEnquiryModel;

        let response = await axios.post(`${endpoint}/api/enquiry`, formDataObj)
        
        if (response.status === 200){
            console.log("Message Sent.")
            newEnquiryModel = response.data;                      
            this.props.dispatch(newEnquiry(newEnquiryModel))
            document.getElementById('newEnquiry-form').reset();
            this.props.openModal(false)
            this.props.created(true)
        }else {
            console.log(response.statusText)                    
        }
   
        //resetForm()
    }

    

    render() {
        const { open, openModal } = this.props

        console.log(this.props)

        return (
          <Modal
            show={open}
            onHide={() => openModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Create New Job
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>  
                      
                <Form id="newEnquiry-form" onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Form.Label>Site Address</Form.Label>
                        <Form.Control type="text" id="address" name="address" />        
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Client name</Form.Label>
                        <Form.Control type="text" id="name" name="name"/>        
                    </Form.Group>


                    <Form.Group>
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control type="text" id="postcode" name="postcode" />        
                    </Form.Group>                

                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" id="email" name="email"/>        
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control type="tel" id="telephone" name="telephone"/>        
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Fee</Form.Label>
                        <Form.Control type="text" id="price" name="price"/>        
                    </Form.Group>


                    <Button variant="primary" type="submit">Submit</Button>                                   
                </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => openModal(false)}>Close</Button>
            </Modal.Footer>
          </Modal>
        )
    }
}

function mapStateToProps({ enquiries }) {
    return {
        enquiries
    }
}

export default connect(mapStateToProps)(NewEnquiryModal)