import { RECEIVE_CARS, NEW_CAR, UPDATE_CAR, REMOVE_CAR } from "../actions/cars"

export function cars(state = {}, action) {
    switch(action.type) {
        case RECEIVE_CARS:
            return {
                ...state,
                ...action.cars
            }
        case NEW_CAR:
            return {
                ...state,
                [action.car._id]: action.car               
            }
        case UPDATE_CAR:
            return {
                ...state,
                [action.car._id]: action.car               
            }
        case REMOVE_CAR:
            return {
                ...state,
                [action.carid]: {
                    ...state[action.carid],
                    is_deleted: true
                }
            }
        default:
            return state
    }
}