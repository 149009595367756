import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { formatDate, formatTelephone, formatFee, formatPostcode, typeTest } from '../../utils/formatFunctions'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { RowingTwoTone } from '@material-ui/icons'


const JobsTable = ({ jobsArr, engineers, openRemoveModal, setID }) => {
    const checkStatus = (cell, row) => {
        if (row.completion.issued) {
            return (
                <span className={`badge badge-success badge-outline`}>
                Complete
                </span>)
        } else {
            if (cell) {
            return (
                <span className={`badge badge-info badge-outline`}>
                Confirmed
                </span>)
            } else {
            return (
                <span className={`badge badge-warning badge-outline`}>
                Pending
                </span>)
            }
        }
    }

    const checkCompletion = (cell, row) => {
        if (cell) {
            return <span>Yes</span>
        } else {
            return <span>No</span>
        }
        
    }

    const setForDeletion = (state, id) => {
        openRemoveModal(state)
        setID(id)
    }

    
    const GetActionFormat = (cell, row) => {
        return (<div style={{width: '100%', textAlign: 'center'}}>
                    <div style={{display: "inline-block"}}> 
                        <Link 
                            to={{
                                pathname: `/jobs/${row._id}`
                            }}>
                                <button className="btn btn-primary btn-sm"><i className="material-icons">edit</i></button>
                        </Link>
                    </div>
                    <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                         <button className="btn btn-danger btn-sm" onClick={ () => setForDeletion(true, row._id)} ><i className="material-icons">delete</i></button>
                    </div>
                </div>)
    }


    const returnEngineers = (engineers) =>{
        return engineers;
    }

    const columns2 = [       
        {
          dataField: '_id',
          text: 'ID',
          sort: true,
          hidden: true
        }, {
            dataField: 'invoice_number',
            text: 'Invoice No.',
            sort: true,
            classes: 'col-hide',
            headerClasses: 'col-hide',
            hidden: true
        }, {
          dataField: 'startjob',
          text: 'Date',
          sort: true,
          classes: (cell, row) => {
            return row.completion.issued ? 'completedBorder' : typeTest(row) == 'sound' ? 'soundtestBorder' : (typeTest(row) == 'air' ? 'airtestBorder' : 'noisesurveyBorder')
          },
          formatter: formatDate
        }, {
            dataField: '' ,
            text:'Type',
            sort:true,
            formatter:(cell, row) =>{
                let type = typeTest(row)
                let html = '';
                let displayType
                if (type === 'sound'){
                    displayType = "Sound Test" 
                } else if (type === 'air'){
                    displayType = "Air Test"
                } else{
                    if (row.survey.type === 'Setup'){
                        displayType = 'Noise Survey: \n Setup'
                    } else {
                        displayType = 'Noise Survey: \n Pickup'
                    }
                }
                html += `${displayType} `
                return <div className='new-line'>{html}</div>

            }
        }, {
            dataField: 'projectName',
            text: 'Project Name',
            sort: true
          }, {
            dataField: 'contact.project_leader',
            text: 'Project Leader',
            sort: true,
          }, {
          dataField: 'address',
          text: 'Address',
          sort: true
          }, {
          dataField: 'postcode',
          text: 'Postcode',
          sort: true,
          classes: 'col-hide',
          headerClasses: 'col-hide',
          formatter: formatPostcode
        }, {
            dataField: 'engineers[0]',
            text: 'Engineer(s)',
            sort: true,
            classes: 'col-hide',
            headerClasses: 'col-hide',
            formatExtraData: returnEngineers,
            formatter:(cell, row, formatExtraData) => {
                let html = ""
                for(let i = 0; i < row.engineers.length; i++){
                    
                    Object.values(engineers).map( (engineer) => {
                        if (engineer._id == row.engineers[i]){
                            if (i === row.engineers.length -1){
                                html += `${engineer.name} ` 
                            } else {
                                //https://stackoverflow.com/questions/45935733/how-to-add-a-br-tag-in-reactjs-between-two-strings/45935795 How to add a break after each engineer.
                                html += `${engineer.name}, ` + '\n' ;
                            }                                                
                        }
                    })
                    
                }
                return <div className='new-line'>{html}</div>
              }
        }, {
          dataField: 'contact.name',
          text: 'Client Name',
          sort: true
        }, {
          dataField: 'contact.telephone',
          text: 'Telephone',
          sort: true,
          formatter: formatTelephone
        }, {
          dataField: 'price',
          text: 'Fee',
          sort: true,
          formatter: formatFee,
          classes: 'col-hide',
          headerClasses: 'col-hide'
        }, 
        {
            dataField: 'completion.invoiced',
            text: 'Invoiced',
            sort: true,
            classes: (cell) => {
                return cell ? 'col-hide greenCellColor' : 'col-hide redCellColor'
            },
            headerClasses: 'col-hide centerTable',
            formatter: checkCompletion
        },
        {
            dataField: 'completion.paid',
            text: 'Paid',
            sort: true,
            classes: (cell) => {
                return cell ? 'col-hide greenCellColor' : 'col-hide redCellColor'
            },
            headerClasses: 'col-hide centerTable',
            formatter: checkCompletion
          }, {
            dataField: 'completion.issued',
            text: 'Issued',
            sort: true,
            classes: 'col-hide',
            headerClasses: 'col-hide centerTable',
            classes: (cell) => {
                return cell ? 'col-hide greenCellColor' : 'col-hide redCellColor'
            },
            formatter: checkCompletion
          }, {
          dataField: 'completion.confirmed',
          text: 'Status',
          sort: true,
          classes: 'col-hide centerTable',
          headerClasses: 'col-hide centerTable',
          formatter: checkStatus
        },{
          dataField: 'actions',
          text: 'Actions',
          sort: false,
          formatter: GetActionFormat,
          classes: 'actions-div',
        }]

    const data3 = Object.keys(jobsArr).map(i => jobsArr[i])

    const defaultSorted = [{
        dataField: 'startjob',
        order: 'desc'
    }]

    const { SearchBar } = Search;

    const afterSearch = (newResult) => {
    };

    return (
        <ToolkitProvider
            keyField="_id"
            data={ data3 }
            columns={ columns2 }
            search={ { afterSearch } }
        >
            {
            props => (
                <div>
                <SearchBar { ...props.searchProps } />
                <hr />
                <BootstrapTable
                { ...props.baseProps }
                    pagination={ paginationFactory() }
                    defaultSorted={ defaultSorted } 
                    bordered={true}
                    striped
                    hover
                    responsive
                />
                </div>
            )
            }
        </ToolkitProvider>
    )
}

export default JobsTable