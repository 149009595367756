import React, { useState } from 'react'
import { Row, Container, Col, InputGroup, Form } from 'react-bootstrap'

const ProjectDetails = () => {
    const [projectname, setProjectName] = useState('')
    const [clientname, setClientName] = useState('')

    return (
        <Row>
            <Col lg={6} md={6} sm={12}>
                <Form.Group controlId="formGridEnd">
                    <Form.Label>Project Name</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">assignment</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" placeholder="Project Name" name="projectname" onChange={e => setProjectName(e.target.value)} required/>
                    </InputGroup>
                </Form.Group>   
            </Col>
            <Col lg={6} md={6} sm={12}>
                <Form.Group controlId="formGridEnd">
                    <Form.Label>Client Name</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">account_box</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" placeholder="Client Name" name="clientname" onChange={e => setClientName(e.target.value)} required/>
                    </InputGroup>
                </Form.Group>   
            </Col>
        </Row>
    )
}

export default ProjectDetails