import { useState } from 'react'
import moment from 'moment'
import { Row, Col, Table, Button } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import RemoveBookingModal from '../kits/RemoveBookingModal'
import BookModal from '../kits/BookModal'
import { capitalizeFirstLetter, formatTime } from '../../utils/formatFunctions'
import 'moment/locale/en-gb'

const BookingsTable = ({ bookings, type, data, setBookingUpdated, setBookingCreated, setBookingError }) => {
    const [removebookingmodal, openRemoveBooking] = useState(false)
    const [bookModal, setBookModal] = useState(false)
    moment.locale('en-gb')
    return (
      
        <Row>
            <Col md={12}>
                <Widget title="Bookings" paddingStyle="5px" buttonRight={<Button variant="primary" onClick={() => setBookModal(true)}>Book</Button>}>
                    <Table striped border hover className="table-responsive">
                        <thead>
                            <tr>
                                <th>{capitalizeFirstLetter(type)}</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Type</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(bookings).filter((b) => bookings[b].type.indexOf("Site Visit") === -1).filter((bo) => moment(bookings[bo].end) > moment(new Date()).subtract(1, 'days')).map((booking) => {
                                return <tr key={bookings[booking]._id}>
                                        <td>{data[bookings[booking][`${type}_idfk`]].name}</td>
                                        <td>{`${moment(bookings[booking].start).format('L')} - ${formatTime(bookings[booking], 'start')}`}</td>
                                        <td>{`${moment(bookings[booking].end).format('L')} - ${formatTime(bookings[booking], 'end')}`}</td>
                                        <td>{bookings[booking].type}</td>
                                        <td>
                                            <div style={{width: '100%'}}>
                                                <div style={{display: "inline-block"}}> 
                                                    <button className="btn btn-primary btn-sm" onClick={() => setBookModal(bookings[booking])}><i className="material-icons">edit</i></button>
                                                </div>
                                                <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                                                    <button className="btn btn-danger btn-sm" onClick={() => openRemoveBooking(bookings[booking])}><i className="material-icons">delete</i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                            })}
                        </tbody>
                    </Table>
                </Widget>
            </Col>

            {bookModal && <BookModal bookModal={bookModal} setBookModal={setBookModal} type={type} setBookingCreated={setBookingCreated} setBookingUpdated={setBookingUpdated} />}
            {removebookingmodal !== false && <RemoveBookingModal removebookingmodal={removebookingmodal} openRemoveBooking={openRemoveBooking} type={type} setBookingError={setBookingError} />}
        </Row>  
    )
}

export default BookingsTable