import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {random} from '../../functions'
import Widget from '../../elements/DashboardWidget'
import EngineeerTable from './EngineerTable'
import JobTable from './JobTable'
import ReportsTable from './ReportsTable'
import GoogleMapComponent from './GoogleMapComponent'
import IconWidget6 from '../../icon-widgets/IconWidget6'
import { handleInitialData } from '../../actions/shared'
import "react-datepicker/dist/react-datepicker.css"
import { DateRangePicker } from './DateRangePicker'
import { Filter } from '../jobcalendar/Filter'
import { Row, Col, Alert } from 'react-bootstrap'
import { typeTest } from '../../utils/formatFunctions'
import { projectLeadersDashboardFilter } from '../../utils/projectLeaders'



const AdminDashboard = ({ jobs, alljobs, startweek, endweek, users, dispatch }) => {
  const [range, setRange] = useState(`${moment(startweek).format('DD/MM/YYYY')} - ${moment(endweek).format('DD/MM/YYYY')}`)
  const [rangejobs, setRangeJobs] = useState(jobs)
  const {leaders} = projectLeadersDashboardFilter();
  const [engineer, setEngineer] = useState(null)
  const [type, setType] = useState(null)
  const [delegated, setDelegated] = useState(null);

  const [startDate, setStartDate] = useState(startweek);
  const [endDate, setEndDate] = useState(endweek);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
  useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    
    }, []);
  
  useEffect(() => {
    dispatch(handleInitialData())
  }, [])

  useEffect(() => {
    onChange([startDate, endDate])
  }, [engineer, type, delegated])

  useEffect(() => {  
    document.getElementsByClassName('react-datepicker')[0].style.display = 'none'
    onChange([startDate, endDate])
  }, [alljobs])


  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start !== null && end !== null) {
        let rangejobsarr = []
        Object.keys(alljobs).map(j => {
            if (moment(alljobs[j].startjob) >= moment(start).startOf('day') && moment(alljobs[j].startjob) <= moment(end).endOf('day')) {
              let flag = true
              if (engineer !== null) {
                if (alljobs[j].engineers.includes(engineer.id)) {
                  flag = true
                } else {
                  flag = false
                }
              }

              let typetest = typeTest(alljobs[j]);
              let jobtype = typetest == 'sound' ? 'Sound Test' : (typetest == 'air' ? 'Air Test' : 'Noise Survey')

              if (flag) {
                if (type !== null) {
                  if (type.name == jobtype) {
                    flag = true
                  } else {
                    flag = false
                  }
                }
              }
          
              if (flag) {
                if (delegated !== null) {
                  let internal = delegated.name == "All External" ? false : true
                  let leader = (delegated.name !== "All External" && delegated.name!== "All Internal" ) ? delegated.name : null

                  if (leader === null && internal == alljobs[j].delegated) {                    
                    flag = true
                  } 
                  else if (leader !== null && alljobs[j].contact.project_leader == leader) {
                    flag = true
                  } 
                  else {
                    flag = false
                  }
                }
              }
              
              if (flag) {
                rangejobsarr.push(alljobs[j])
              }
            }
        })

        setRange(`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`)
        setRangeJobs(rangejobsarr)

        document.getElementsByClassName('react-datepicker')[0].style.display = 'none'
    } 
  };

  const openRange = e => {
    document.getElementsByClassName('react-datepicker')[0].style.display = 'inline-block'
  }

    let turnover = 0;

    for (let i = 0; i < rangejobs.length; i++) {
        turnover = turnover + rangejobs[i].price
    }

    let reportsJobs = []

    Object.keys(alljobs).map((job) => {

      if (!alljobs[job].completion.issued && moment(alljobs[job].startjob) < moment()) {
        reportsJobs.push(alljobs[job])
      }
    })

      const widgets1 = [
        {
          bg: 'info',
          color: 'white',
          icon: 'assignment',
          text: 'Jobs',
          number: rangejobs.length
        },
        {
          bg: 'success',
          color: 'white',
          icon: 'attach_money',
          text: 'Projected Turnover',
          number: `£${turnover.toString().split(/(?=(?:...)*$)/).join(',')}`
        },
        {
          bg: 'warning',
          color: 'white',
          icon: 'timer',
          text: 'Hours',
          number: Math.round(Object.keys(rangejobs).map((job) => moment.duration(moment(rangejobs[job].endjob).diff(moment(rangejobs[job].startjob))).asHours()).reduce((a, b) => a + b, 0))
        },
        {
          bg: 'danger',
          color: 'white',
          icon: 'phone',
          text: 'Reports To Issue',
          number: reportsJobs.length
        }
      ]

      return (

        <div className="container-fluid">
          <Row>
            <div className="col-lg-6 col-md-12">
                  <Alert key={1} variant={"secondary"}>
                    <Row>
                      <Filter title="Engineer" controlId="engineerSelect" setFunction={setEngineer} data={users} cols={4} margin=""/>
                      <Filter title="Type" controlId="typeSelect" setFunction={setType} data={[{"name" : "Sound Test"}, {"name" : "Air Test"}, {"name": "Noise Survey"}]} cols={3} margin=""/>
                      <Filter title="Delegated" controlId="engineerSelect" setFunction={setDelegated} data={leaders} cols={5} margin=""/>
                    </Row>
                  </Alert>
            </div>
            <Col lg={6} md={12}>
              <DateRangePicker openRange={openRange} startDate={startDate} endDate={endDate} onChange={onChange} range={range} />
            </Col>
          </Row>
          
            <div className="row">
                {widgets1.map((widget, i) => (
                  <div className="col-12 col-md-12 col-lg-3" key={i}>
                    <div className="row" key={i}>
                      <div className="col-12 m-b-10">
                        <IconWidget6 {...widget} />
                      </div>
                    </div>
                  </div>
                ))}

              <div className="col-12 col-xl-12">
                    <Widget title="Job Overview" icon="assignment" paddingStyle="5px">
                      <JobTable jobs={rangejobs} dashboardType="admin" filter={[]} />
                    </Widget>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-xl-6">
                 
                    <Widget title="Map Overview" icon="map" paddingStyle="5px">                  
                      <GoogleMapComponent jobs={rangejobs} height={windowDimensions.width > 1500 ? 60 : 75} />                 
                    </Widget>
                    
                </div>
                <div className="col-12 col-xl-6">
                  <div className="row">
                    <div className="col-12 col-xl-12">
                        <Widget title="Engineer Overview" icon="account_circle" paddingStyle="5px">
                            <EngineeerTable jobs={rangejobs} users={users} />
                        </Widget>
                    </div>
                    <div className="col-12 col-xl-12">
                      <Widget title="Reports to Issue" icon="assignment_late" paddingStyle="5px">
                          <ReportsTable jobs={reportsJobs} />
                      </Widget>
                    </div>
                  </div>
                </div>
              </div>

          </div>
      )
}

const mapStateToProps = ({ jobs, users }) => {
    let d = new Date()
    let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1)
    let dayend = d.getDay(),
        diffend = d.getDate() - dayend + (dayend == 0 ? -6 : 7)
    let startweek = new Date(d.setDate(diff)) // get Monday
    let endweek = new Date(d.setDate(diffend)) // get Sunday

    const weekjobs = []

    Object.keys(jobs).map(j => (moment(jobs[j].startjob) >= moment(startweek).startOf('day') && moment(jobs[j].startjob) <= moment(endweek).endOf('day')) && weekjobs.push(jobs[j]))

    return {
        jobs: weekjobs,
        alljobs: jobs,
        startweek,
        endweek,
        users //: Object.keys(users).filter((user) => users[user].isEngineer)
    }
}

export default connect(mapStateToProps)(AdminDashboard)