export const RECEIVE_CARS = 'RECEIVE_CARS'
export const NEW_CAR = 'NEW_CAR'
export const UPDATE_CAR = 'UPDATE_CAR'
export const REMOVE_CAR = 'REMOVE_CAR'

export function receiveCars(cars) {
    return {
        type: RECEIVE_CARS,
        cars
    }
}

export function newCar(car) {
  return {
      type: NEW_CAR,
      car
  }
}

export function updateCar(car) {
  return {
      type: UPDATE_CAR,
      car
  }
}

export function deleteCar(carid) {
  return {
      type: REMOVE_CAR,
      carid
  }
}