import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Container, Row, Col, Form, Button, Breadcrumb, Card, InputGroup, Accordion } from 'react-bootstrap'
import AlertComponent from '../alerts/AlertComponent'
import { withRouter } from 'react-router-dom'
import Widget from '../../elements/DashboardWidget'
import GoogleMapComponent from '../dashboard/GoogleMapComponent'
import { newEnquiry, updateEnquiry } from '../../actions/enquiries'
import { ProjectDetails } from './ProjectDetails'
import Contact from '../jobs/Contact'
import { endpoint } from '../../utils/api'
import { SaveButton } from '../saveButton/SaveButton'

const EnquiryForm = ({ match, cars, location, dispatch }) => {
    const [enquiry, setEnquiry] = useState(null)
    const [enquiryupdated, setEnquiryUpdated] = useState(false)
    const [enquirycreated, setEnquiryCreated] = useState(false)
    const [enquiryerror, setEnquiryError] = useState(false)
    const [delegatedcheck, setDelegatedCheck] = useState(false)
    const [loading, setLoading] = useState(false)
    const [delegated, setDelegated] = useState(false)

    useEffect(() => {
        setDelegatedCheck(enquiry !== null ? enquiry.delegated : false)
    }, [enquiry])

    useEffect(() => {
        if ('id' in match.params) {
            axios({
                method: "GET", 
                url: `${endpoint}/api/enquiry/${match.params.id}`,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                }
                }).then((response)=> {
                    if (response.status === 200){
                        setEnquiry(response.data)
                    } else {
                        console.log("Message failed to send.")
                    }
                })
        }
    }, [])

    const handleSubmit = async (e, props) => {
        setLoading(true)
        setEnquiryUpdated(false)
        setEnquiryCreated(false)
        setEnquiryError(false)

        e.preventDefault()

        const formData = new FormData(e.target);
        let formDataObj = Object.fromEntries(formData.entries())
        let jobobj

        if (enquiry !== null) {
            await axios({
                method: "PUT",
                url: `${endpoint}/api/enquiry/${enquiry._id}`,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                },
                data: formDataObj
                }).then((response) => {
                    if (response.status === 200) {
                        console.log("Message Sent.")
                        jobobj = response.data
                        setEnquiryUpdated(true)    
                        setEnquiry(response.data)                    
                        dispatch(updateEnquiry(jobobj))
                    } else {
                        console.log(response.statusText)  
                        console.log(response.errors)   
                        setEnquiryError(true)               
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setEnquiryError(true)   
                })

        } else {
            await axios({
                method: "POST",
                url: `${endpoint}/api/enquiry`,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                },
                data: formDataObj
                }).then((response) => {
                    if (response.status === 200) {
                        console.log("Message Sent.")
                        jobobj = response.data;  
                        setEnquiryCreated(true)         
                        setEnquiry(response.data)                
                        dispatch(newEnquiry(jobobj))   
                        
                        window.location.href = `/enquiry/${jobobj._id}`
                    } else {
                        console.log(response.statusText)  
                        console.log(response.errors)       
                        setEnquiryError(true)              
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setEnquiryError(true)   
                })
        }
        setLoading(false)
    }
    function changeDelegated(){
        setDelegated(!delegated)
    }
    return (
        <Container fluid id="main-container">
            {enquiryupdated && <AlertComponent type="primary" text="Project Updated!"/>}
            {enquirycreated && <AlertComponent type="success" text="Project Created!"/>}
            {enquiryerror && <AlertComponent type="danger" text="Error!"/>}

            <Form onSubmit={handleSubmit}>
                <Form.Control type="hidden" name="enquiry_id" defaultValue={location.query !== undefined ? location.query.enquiry_id : ''}/>

                <Row>
                    <div className="col-12 col-lg-4">
                        <ProjectDetails enquiry={enquiry} />
                    </div>

                    <div className="col-12 col-lg-4">
                        <Contact job={enquiry} enquiry={enquiry} onClick={changeDelegated} />
                    </div>

                    <div className="col-12 col-lg-4">
                        <Widget title="Location" icon="location_on" paddingStyle="5px">

                            <Form.Group as={Col} controlId="formGridAddress">
                                <Form.Label>Address</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><i className="material-icons">location_on</i></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control placeholder="Address..." name="address" defaultValue={enquiry !== null ? enquiry.address : ''}/>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPostcode">
                                <Form.Label>Postcode</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><i className="material-icons">map</i></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control placeholder="Postcode..." name="postcode" defaultValue={enquiry !== null ? enquiry.postcode : ''}/>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridLink">
                                <a href={"https://www.google.com/maps/search/?api=1&query=" + (enquiry !== null && enquiry.postcode)} style={{ color: 'blue' }}>Get Directions</a>
                            </Form.Group>
                        </Widget>
                        <SaveButton loading={loading}/>
                    </div>                    
                </Row>
            </Form>
        </Container>
    )
}

function mapStateToProps({ cars, enquiries }) {
    return {
        cars,
        enquiries
    }
}

export default connect(mapStateToProps)(withRouter(EnquiryForm))