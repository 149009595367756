export const RECEIVE_KITS = 'RECEIVE_KITS'
export const NEW_KIT = 'NEW_KIT'
export const UPDATE_KIT = 'UPDATE_KIT'
export const REMOVE_KIT = 'REMOVE_KIT'

export function receiveKits(kits) {
    return {
        type: RECEIVE_KITS,
        kits
    }
}

export function newKit(kit) {
  return {
      type: NEW_KIT,
      kit
  }
}

export function updateKit(kit) {
  return {
      type: UPDATE_KIT,
      kit
  }
}

export function deleteKit(kitid) {
  return {
      type: REMOVE_KIT,
      kitid
  }
}