import { RECEIVE_USERS, NEW_USER, UPDATE_USER, REMOVE_USER } from "../actions/users"

export function users(state = {}, action) {

    switch(action.type) {
        case RECEIVE_USERS:
            return {
                ...state,
                ...action.users
            }
        case NEW_USER:
            return {
                ...state,
                [action.engineer._id]: action.engineer               
            }
        case UPDATE_USER:
            return {
                ...state,
                [action.engineer._id]: action.engineer               
            }
        case REMOVE_USER:
            /*let updatedstate = {}
            let newState = Object.keys(state).filter((key) => key !== action.kitid) // array of keys minus deleted
            newState.forEach(n => (updatedstate[n] = state[n]))*/

            return {
                ...state,
                [action.userid]: {
                    ...state[action.userid],
                    is_deleted: true
                }
            }
        default:
            return state
    }
}