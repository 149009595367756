import { useState } from 'react'
import ResourceCalendar from './ResourceCalendar'
import { connect } from 'react-redux'
import { Filter } from '../jobcalendar/Filter'
import AlertComponent from '../alerts/AlertComponent'
import BookModal from '../kits/BookModal'

const Resources = ({ cars, users, kits }) => {
    const [resource, setResource] = useState({ name: "Engineer", _id: 1 })
    const [bookModal, setBookModal] = useState(false)

    const [bookingupdated, setBookingUpdated] = useState(false)
    const [bookingcreated, setBookingCreated] = useState(false)

    return (
        <>
            {bookingupdated && <AlertComponent type="primary" text="Booking Updated!"/>}
            {bookingcreated && <AlertComponent type="success" text="Booking Created!"/>}

            <Filter title="Resource" id="resourceSelect" setFunction={setResource} data={[{name: "Engineer", _id: 1}, {name: "Kit", _id: 2}, {name: "Car", _id: 3}]} cols={4} margin="mb-3" noAll={true} />
            
            <ResourceCalendar 
                resourcesArr={resource.name === "Engineer" ? users : (resource.name === "Kit" ? kits : cars)} 
                item={resource.name.toLowerCase()} 
                bookingcreated={bookingcreated} 
                bookingupdated={bookingupdated} 
                setBookModal={setBookModal}
            />
        
            {bookModal && (
                <BookModal 
                    bookModal={bookModal} 
                    setBookModal={setBookModal} 
                    type={resource.name.toLowerCase()} 
                    setBookingCreated={setBookingCreated} 
                    setBookingUpdated={setBookingUpdated} 
                />
            )}
        </>
    )
}

const mapStateToProps = ({ cars, users, kits }) => {
    return {
        cars, 
        users,
        kits
    }
}

export default connect(mapStateToProps)(Resources)