import React, { useEffect } from 'react'
import { Row, Container, Col, InputGroup, Form, FormControl, Button } from 'react-bootstrap'

const TestAmount = ({ numberoftests, postcode }) => {
    useEffect(() => {
        
    }, [numberoftests, postcode])

    return (
        <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Test Type</th>
                    <th>No. Tests</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(numberoftests).map((testtype) => (
                    <tr>
                        <th>{testtype}</th>
                        <td>
                            <input type="number" name={testtype} value={numberoftests[testtype]} required />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default TestAmount