import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {random} from '../../functions'
import Widget from '../../elements/DashboardWidget'
import JobTable from './JobTable'
import GoogleMapComponent from './GoogleMapComponent'
import { handleInitialData } from '../../actions/shared'

import "react-datepicker/dist/react-datepicker.css";
import { DateRangePicker } from './DateRangePicker'

const EngineerDashboard = ({ jobs, user, alljobs, startweek, endweek, dispatch }) => {
    const [range, setRange] = useState(`${moment(startweek).format('DD/MM/YYYY')} - ${moment(endweek).format('DD/MM/YYYY')}`)
    const [weekjobs, setWeekJobs] = useState(jobs)

    const [startDate, setStartDate] = useState(startweek);
    const [endDate, setEndDate] = useState(endweek);

    useEffect(() => {
        document.getElementsByClassName('react-datepicker')[0].style.display = 'none'
    }, [])

    useEffect(() => {  
        document.getElementsByClassName('react-datepicker')[0].style.display = 'none'
        onChange([startDate, endDate])
      }, [alljobs])

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start !== null && end !== null) {
            let rangejobs = []
            Object.keys(alljobs).map(j => {
                if (moment(alljobs[j].startjob) >= moment(start).startOf('day') && moment(alljobs[j].startjob) <= moment(end).endOf('day')) {
                    rangejobs.push(alljobs[j])
                }
            })

            setRange(`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`)
            setWeekJobs(rangejobs)

            document.getElementsByClassName('react-datepicker')[0].style.display = 'none'
        } 
    };

    useEffect(() => {
        dispatch(handleInitialData())
    }, [])
  
    const chartData = () => {
        let data = []
        for (let i = 0; i < 20; i++) {
            data.push({name: 'Serie ' + (i + 1), x: random(20, 90)})
        }
        return data
    }

    const openRange = e => {
        document.getElementsByClassName('react-datepicker')[0].style.display = 'inline-block'
    }

    return (
        <div className="container-fluid">
            <DateRangePicker openRange={openRange} startDate={startDate} endDate={endDate} onChange={onChange} range={range} />

            <div className="row">
                <div className="col-12 " style={{marginTop: '1rem', marginLeft: '1rem'}}>
                    <h3>{user.hasOwnProperty('name') && user.name} - Engineer Dashboard</h3>
                </div>

                <div className="col-12 col-lg-6" style={{marginTop: '1rem'}}>
                    <Widget title="Upcoming Jobs" icon="assignment" paddingStyle="5px">
                        <JobTable jobs={weekjobs} dashboardType="engineer" filter={[user]} />
                    </Widget>
                </div>
                <div className="col-12 col-lg-6">
                    <Widget title="Map Overview" icon="map" paddingStyle="5px">
                        <GoogleMapComponent jobs={weekjobs} height={60} />
                    </Widget>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ jobs, auth }) => {
    let d = new Date()
    let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1)
    let dayend = d.getDay(),
        diffend = d.getDate() - dayend + (dayend == 0 ? -6 : 7)
    let startweek = new Date(d.setDate(diff)) // get Monday
    let endweek = new Date(d.setDate(diffend)) // get Sunday

    const weekjobs = []

    Object.keys(jobs).map(j => (moment(jobs[j].startjob) >= moment(startweek).startOf('day') && moment(jobs[j].startjob) <= moment(endweek).endOf('day')) && weekjobs.push(jobs[j]))

    return {
        alljobs: jobs,
        jobs: weekjobs,
        startweek,
        endweek,
        user: (auth.user !== null) ? auth.user : {}//(auth.user.hasOwnProperty('name') ? auth.user.name : '') : ''
    }
}

export default connect(mapStateToProps)(EngineerDashboard)