import { Modal, Button, Form } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { capitalizeFirstLetter } from '../../utils/formatFunctions'

const CompletionModal = ({ completionmodal, setCompletionModal, completion, setCompletion }) => {
    return (
        <Modal show={completionmodal} onHide={() => setCompletionModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Completion</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <table className="table table-bordered table-striped">
                    <tbody>
                        {Object.keys(completion).map(c => (
                            <tr>
                                <th>{capitalizeFirstLetter(c)}</th>
                                <td>
                                    <BootstrapSwitchButton
                                        checked={completion[c]}
                                        onlabel='Yes'
                                        offlabel='No'
                                        onstyle="success"
                                        offstyle="danger"
                                        id={c}
                                        onChange={(checked) => setCompletion({...completion, [c]: checked})}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setCompletionModal(false)}>Close</Button>
                <Button variant="primary" onClick={() => setCompletionModal(false)}><i className="material-icons">save</i>&nbsp;Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CompletionModal