import React from 'react'
import {InputGroup} from 'react-bootstrap'


export const Filter = ({ title, id, setFunction, data, cols, margin, noAll }) => {
    const handleChange = e => {
        if (noAll) {
            console.log(data[Object.keys(data)[e.target.options.selectedIndex - 1]])
            setFunction({ name: e.target.value, id: data[Object.keys(data)[e.target.options.selectedIndex]]._id})
        } else {
            if (e.target.options.selectedIndex > 0) {
                setFunction({ name: e.target.value, id: data[Object.keys(data)[e.target.options.selectedIndex - 1]]._id})
            } else {
                setFunction(null)
            }
        }
    }

    return (
        <div className={`col-lg-${cols} input-group ${margin}`}>
            <div className="input-group-prepend" >
                <label className="input-group-text" >{ title }</label>

            </div>
            
            
            <select className="custom-select" controlId={id} onChange={handleChange}>
                {!noAll && <option selected>All</option>}
                {Object.keys(data).map((d) => (
                    <option key={data[d]._id} data-key={data[d]._id}>{data[d].name}</option>
                ))}
            </select>
        </div>
    )
}
