import moment from 'moment'

const EngineeerTable = ({ jobs, users }) => {
  if (jobs.length > 0) {
    return (<div className="table-widget-4">
            <table className="table table-unbordered table-striped table-responsive">
              <thead>
                <tr>
                  <th>Engineer</th>
                  <th>No. Jobs</th>
                  <th className="col-hide">No. Days</th>
                  <th className="col-hide">No. Hours</th>
                  <th className="col-hide">Longest Day</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(users).filter(u => users[u].isEngineer).map((user) => {
                  let days = []
                  let numberHours = 0

                  Object.keys(jobs).filter((j) => jobs[j].engineers[0] == users[user]._id).forEach((job) => {
                    const searchDay = days.filter((day) => day == moment(jobs[job].startjob).format('dddd'))

                    if (searchDay.length === 0) {
                      days.push({
                        day: moment(jobs[job].startjob).format('dddd'),
                        hours: moment.duration(moment(jobs[job].endjob).diff(moment(jobs[job].startjob))).asHours()
                      })
                    } else {
                      searchDay.hours = searchDay.hours + moment.duration(moment(jobs[job].endjob).diff(moment(jobs[job].startjob))).asHours()
                    }
                  })

                  return (<tr key={users[user]._id}>
                            <td>{users[user].name}</td>
                            <td className="center">{Object.keys(jobs).filter((job) => jobs[job].engineers[0] == users[user]._id).length}</td>
                            <td className="col-hide center">{days.length}</td>
                            <td className="col-hide center">{days.map((day) => day.hours).reduce((a, b) => Math.round(a + b), 0)}</td>
                            <td className="col-hide center">{days.length > 0 ? Math.round(Math.max(...days.map((day) => day.hours))) : 0}</td>
                            <td>
                              {days.map((day) => day.hours).reduce((a, b) => a + b, 0) > 40 || Math.max(...days.map((day) => day.hours)) > 9 ?
                                <span className={`badge badge-danger badge-outline`}>
                                  Concern
                                </span> : 
                                <span className={`badge badge-success badge-outline`}>
                                  Okay
                                </span>
                              }
                            </td>
                          </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
    )
  } else {
    return (
      <div style={{ textAlign: 'center' }}>
        <b>No jobs in the range...</b>
      </div>
    )
  }
}

export default EngineeerTable
