import React from 'react'
import { Col, Form, InputGroup } from 'react-bootstrap'

const Postcode = ({ postcodeerror, setPostcode }) => {
    return (
        <Col lg={6} md={6} sm={6}>
            <Form.Group controlId="formGridEnd">
                <Form.Label>Postcode</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">location_on</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control type="text" placeholder="Postcode" name="postcode" controlId="postcode" onChange={e => setPostcode({
                        text: e.target.value,
                        driving: {
                            text: '',
                            value: ''
                        },
                        geocode: {
                            lat: '',
                            lng: ''
                        }
                    })} required/>
                </InputGroup>
                {postcodeerror && <span style={{color: 'red'}}>Invalid Postcode!</span>}
            </Form.Group>   
        </Col>
    )
}
export default Postcode