import React, { useState, useEffect } from 'react'
import { Modal, Form, Col, InputGroup, Button } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import { connect } from 'react-redux'
import { newEngineerBooking, updateEngineerBooking } from '../../actions/engineerbookings'
import { newKitBooking, updateKitBooking } from '../../actions/kitbookings'
import { newCarBooking, updateCarBooking } from '../../actions/carbookings'
import { capitalizeFirstLetter, formatTime } from '../../utils/formatFunctions'
import { endpoint } from '../../utils/api'

const BookModal = ({ bookModal, setBookModal, type, kits, cars, users, dispatch, setBookingCreated, setBookingUpdated }) => {
    const [dateTime, setDateTime] = useState({
        start: '',
        end: ''
    })
    const [availableengineers, setAvailableEngineers] = useState(users)
    const [availablecars, setAvailableCars] = useState(cars)
    const [availablekits, setAvailableKits] = useState(kits)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (typeof bookModal == 'object') {
            setDateTime({
                start: bookModal.start,
                end: bookModal.end
            })
        }
    }, [])

    useEffect(() => {
        if (dateTime !== null ) {
            setLoading(true)

            let url;

            bookModal._id == undefined ? url = `${endpoint}/api/${type}bookings/checkavailability` : `${endpoint}/api/${type}bookings/checkavailability/${bookModal._id}`

            axios({
                method: "POST",
                url: url,
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                },
                data: {
                    starttime: dateTime.start,
                    endtime: dateTime.end 
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response.data)
                    type === "engineer" ? setAvailableEngineers(response.data.engineers) : (type === "kit" ? setAvailableKits(response.data.kits) : setAvailableCars(response.data.cars))
                    setLoading(false)
                } else {
                    console.log("test")         
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }, [dateTime])
    
    const handleSubmit = async e => {
        e.preventDefault()

        const formData = new FormData(e.target);

        formData.append('start', new Date(formData.get('startdate') + ' ' + formData.get('start')));
        formData.append('end', new Date(formData.get('enddate') + ' ' + formData.get('end')));

        let formDataObj = Object.fromEntries(formData.entries())

        if (bookModal === true) {
            console.log(formDataObj)
            await axios.post(`${endpoint}/api/${type}bookings`, formDataObj, {
                        headers: {
                            Authorization: 'Bearer '+ localStorage.token
                        }
                    })
                        .then(function (response) {
                            if (response.status === 200) {
                                console.log("Message Sent.")
                                console.log(response)       
                                type === "engineer" ? dispatch(newEngineerBooking(response.data)) : (type === "kit" ? dispatch(newKitBooking(response.data)) : dispatch(newCarBooking(response.data)))        
                                setBookingCreated(true)
                                setBookModal(false)  
                            } else {
                                console.log(response.statusText)  
                                console.log(response.errors)           
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
        } else {
            await axios.put(`${endpoint}/api/${type}bookings/${bookModal._id}`, formDataObj, {
                        headers: {
                            Authorization: 'Bearer '+ localStorage.token
                        }
                    })
                        .then(function (response) {
                            if (response.status === 200) {
                                console.log("Message Sent.")
                                console.log(response)       
                                type === "engineer" ? dispatch(updateEngineerBooking([response.data])) : (type === "kit" ? dispatch(updateKitBooking([response.data])) : dispatch(updateCarBooking([response.data])))        
                                setBookingUpdated(true)
                                setBookModal(false)  
                            } else {
                                console.log(response.statusText)  
                                console.log(response.errors)           
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
        }
    }

    const onDateTimeChange = e => {
        document.querySelector('[controlId="date"]')

        const startdate = document.querySelector('[controlId="startdate"]').value
        const enddate = document.querySelector('[controlId="enddate"]').value
        const starttime = document.querySelector('[controlId="start"]').value
        const endtime = document.querySelector('[controlId="end"]').value

        let start = moment(startdate + " " + starttime)
        let end = moment(enddate + " " + endtime)

        // handle if end time before start time
        if (start > end) {
        //    end = start;
        }
       
        // handle if null
        //if (startdate != "" && enddate != "" && starttime != "" && endtime != "") {
            start = new Date(start)
            end = new Date(end) 
            setDateTime({                
                start,
                end
            })
        //}
    }

    const formatDate = startend => {
        if (dateTime[startend] !== '') {
            let date = new Date(dateTime[startend])

            //console.log(date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2))
           
            return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2)
        } else {
            return ''
        }
    }

    return (
        <Modal show={bookModal !== false ? true : false} onHide={() => setBookModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{bookModal === true ? `Book ${capitalizeFirstLetter(type)}` : 'Edit Booking'}</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit}>
                <Modal.Body>

                    {type === "engineer" && (
                        <Form.Group as={Col} controlId="formGridEngineer">
                            <Form.Label>Engineer</Form.Label>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">account_circle</i></InputGroup.Text>
                            </InputGroup.Prepend>
                                <Form.Control as="select" name="engineer" >
                                    {Object.keys(users).map((user) => {
                                        if (Object.keys(availableengineers).map((engineer) => availableengineers[engineer]._id).indexOf(users[user]._id) > -1) {
                                            return <option key={users[user]._id} selected={typeof bookModal === 'object' ? bookModal.engineer_idfk == users[user]._id ? 'selected' : '' : ''}>{users[user].name}</option>
                                        } else {
                                            return <option key={users[user]._id} disabled>{users[user].name}</option>
                                        }
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                    )}

                    {type === "kit" && (
                        <Form.Group as={Col} controlId="formGridKit">
                            <Form.Label>Kit</Form.Label>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">speaker_group</i></InputGroup.Text>
                            </InputGroup.Prepend>
                                <Form.Control as="select" name="kit" >
                                    {Object.keys(kits).map((kit) => {
                                        if (Object.keys(availablekits).map((k) => availablekits[k]._id).indexOf(kits[kit]._id) > -1) {
                                            return <option key={kits[kit]._id} selected={typeof bookModal === 'object' ? bookModal.kit_idfk == kits[kit]._id ? 'selected' : '' : ''}>{kits[kit].name}</option>
                                        } else {
                                            return <option key={kits[kit]._id} disabled>{kits[kit].name}</option>
                                        }
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                    )}

                    {type === "car" && (
                        <Form.Group as={Col} controlId="formGridCar">
                            <Form.Label>Car</Form.Label>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">directions_car</i></InputGroup.Text>
                            </InputGroup.Prepend>
                                <Form.Control as="select" name="car" >
                                    {Object.keys(cars).map((car) => {
                                        if (Object.keys(availablecars).map((c) => availablecars[c]._id).indexOf(cars[car]._id) > -1) {
                                            return <option key={cars[car]._id} selected={typeof bookModal === 'object' ? bookModal.car_idfk == cars[car]._id ? 'selected' : '' : ''}>{cars[car].name}</option>
                                        } else {
                                            return <option key={cars[car]._id} disabled>{cars[car].name}</option>
                                        }
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                    )}

                    <Form.Group as={Col} controlId="formGridDate">
                        <Form.Label>Start</Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">event</i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="date" controlId="startdate" placeholder="Date" name="startdate" defaultValue={formatDate("start")} value={formatDate("start")} onChange={onDateTimeChange} required />
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">access_time</i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="time" placeholder="Start Time" name="start" controlId="start" defaultValue={formatTime(dateTime, "start")} value={formatTime(dateTime, "start")} onChange={onDateTimeChange} required/>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEnd">
                        <Form.Label>End</Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">event</i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="date" controlId="enddate" placeholder="Date" name="enddate" defaultValue={formatDate("end")} value={formatDate("end")} onChange={onDateTimeChange} required/>
                        </InputGroup>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">access_time</i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="time" placeholder="End Time" name="end" controlId="end" defaultValue={formatTime(dateTime, "end")} value={formatTime(dateTime, "end")} onChange={onDateTimeChange} required/>
                        </InputGroup>
                    </Form.Group>   

                    {type === "engineer" && (
                        <Form.Group as={Col} controlId="formGridType">
                            <Form.Label>Type</Form.Label>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">work</i></InputGroup.Text>
                            </InputGroup.Prepend>
                                <Form.Control as="select" name="type" >
                                    <option key="Annual Leave" selected={typeof bookModal === 'object' ? bookModal.type == "Annual Leave" ? 'selected' : '' : ''}>Annual Leave</option>
                                    <option key="Meeting" selected={typeof bookModal === 'object' ? bookModal.type == "Meeting" ? 'selected' : '' : ''}>Meeting</option>
                                    <option key="Office Work" selected={typeof bookModal === 'object' ? bookModal.type == "Office Work" ? 'selected' : '' : ''}>Office Work</option>
                                    <option key="Sick" selected={typeof bookModal === 'object' ? bookModal.type == "Sick" ? 'selected' : '' : ''}>Sick</option>
                                    <option key="Other" selected={typeof bookModal === 'object' ? bookModal.type == "Other" ? 'selected' : '' : ''}>Other</option>
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                    )}

                    {type === "kit" && (
                        <Form.Group as={Col} controlId="formGridType">
                            <Form.Label>Type</Form.Label>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">work</i></InputGroup.Text>
                            </InputGroup.Prepend>
                                <Form.Control as="select" name="type" >
                                    <option key="Calibration" selected={typeof bookModal === 'object' ? bookModal.type == "Calibration" ? 'selected' : '' : ''}>Calibration</option>
                                    <option key="Broken" selected={typeof bookModal === 'object' ? bookModal.type == "Broken" ? 'selected' : '' : ''}>Broken</option>
                                    <option key="Other" selected={typeof bookModal === 'object' ? bookModal.type == "Other" ? 'selected' : '' : ''}>Other</option>
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                    )}

                    {type === "car" && (
                        <Form.Group as={Col} controlId="formGridType">
                            <Form.Label>Type</Form.Label>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="material-icons">work</i></InputGroup.Text>
                            </InputGroup.Prepend>
                                <Form.Control as="select" name="type" >
                                    <option key="MOT" selected={typeof bookModal === 'object' ? bookModal.type == "MOT" ? 'selected' : '' : ''}>MOT</option>
                                    <option key="In Garage" selected={typeof bookModal === 'object' ? bookModal.type == "In Garage" ? 'selected' : '' : ''}>In Garage</option>
                                    <option key="Broken" selected={typeof bookModal === 'object' ? bookModal.type == "Broken" ? 'selected' : '' : ''}>Broken</option>
                                    <option key="Other" selected={typeof bookModal === 'object' ? bookModal.type == "Other" ? 'selected' : '' : ''}>Other</option>
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                    )}

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setBookModal(false)}>Close</Button>
                    <Button variant="primary" type="submit">{typeof bookModal == 'object' ? 'Update Booking' : 'Book'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({ kits, users, cars }) => {
    return {
        kits,
        users,
        cars
    }
}

export default connect(mapStateToProps)(BookModal)