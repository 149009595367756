import {React, useEffect, useState} from 'react'
import { InputGroup, Form, Col, FormControl } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import '../../css/icons/fontAwesomeIcons.css'
import NSType1 from './categories/NSType1';
import NSType2 from './categories/NSType2';

const NoiseSurvey = ({ job, linkJob }) => {

    const [NSTestType, setNSTestType] = useState(1)

    const categories = [
        {
            label: "Noise Survey Report",
            name: "Noise Survey Report",
            type: 1
        },
        {
            label: "Noise Impact Assessment - Construction",
            name: "Noise Impact Assessment - Construction",
            type: 1
        },
        {
            label: "Noise Impact Assessment - Façade Break-in - [Education]",
            name: "Noise Impact Assessment - Façade Break-in - [Education]",
            type: 1
        },
        {
            label: "Noise Impact Assessment - Façade Break-in - [Healthcare]",
            name: "Noise Impact Assessment - Façade Break-in - [Healthcare]",
            type: 1
        },
        {
            label: "Noise Impact Assessment - Façade Break-in - [Office]",
            name: "Noise Impact Assessment - Façade Break-in - [Office]",
            type: 1
        },
        {
            label: "Noise Impact Assessment - Façade Break-in - [Residential]",
            name: "Noise Impact Assessment - Façade Break-in - [Residential]",
            type: 1
        },
        {
            label: "Noise Impact Assessment - 24 Hour Noise Monitoring",
            name: "HTM",
            type: 2
        },
        {
            label: "Noise Impact Assessment - MUGA",
            name: "Noise Impact Assessment - MUGA",
            type: 1
        },
        {
            label: "Noise Impact Assessment - Operational Noise - Breakout",
            name: "Noise Impact Assessment - Operational Noise - Breakout",
            type: 1
        },
        {
            label: "Noise Impact Assessment - Plant Noise",
            name: "Noise Impact Assessment - Plant Noise",
            type: 1
        },
        {
            label: "Noise Impact Assessment - Road Traffic / Carpark",
            name: "Noise Impact Assessment - Road Traffic / Carpark",
            type: 1
        },
    ]

    useEffect(() => {
        if( job!== null){
            categories.forEach((category) => {
                if (category.name == job.survey.project_typeNS){
                    setNSTestType(category.type)
                }
            })
        }
        
    }, [job])

    const setCategory = (e) =>{        
        categories.forEach((category) => {
            if (category.name == e.target.value){
                setNSTestType(category.type)
            }
        })
    }

    return (
        <div className="col-12 col-lg-4">
            <Widget title="Noise Survey" icon="volume_up" paddingStyle="5px">

            <Form.Group as={Col} controlId="formGridContactName">
                    <Form.Label>Project Type</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">category</i></InputGroup.Text>
                        </InputGroup.Prepend>
                            <Form.Control as="select" name="project_typeNS" onChange={(e) => setCategory(e)}>
                                {categories.map((option) => {
                                    if(job !== null){                                                                                         
                                        return <option selected={job.survey.project_typeNS === option.name} value={option.name}>{option.label}</option>
                                    } else {
                                        return <option value={option.name}>{option.label}</option>
                                    }
                                })}                             
                            </Form.Control>
                    </InputGroup>
                </Form.Group>     
                
                {NSTestType === 1 ? <NSType1 job={job} linkJob={linkJob} /> : <NSType2 job={job} linkJob={linkJob} />}

            </Widget>
        </div>
    )
}

export default NoiseSurvey