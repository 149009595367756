import React from 'react'
import Logo from '../logo'
import ToggleLayout1 from './ToggleLayout1'
import ToggleRightSidebar from './ToggleRightSidebar'
import DropdownUser from './DropdownUser'
import '../../css/elements/navbar-1.css'

const Navbar1 = () => (
  <nav className="navbar navbar-1 d-flex justify-content-around align-items-center flex-nowrap">
    <Logo />
    <ToggleLayout1 />
    <div className="separator" />
    <ul className="nav nav-inline nav-inline-2">
      <li className="nav-item nav-item-dropdown">
        <a className="nav-link nav-link-avatar">   
          <img
            src="images/avatar.jpg"
            className="rounded-circle"
            alt="avatar"
          />
        </a>
        <DropdownUser />
      </li>
    </ul>
  </nav>
)

export default Navbar1
