import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import { Row, Col, Button } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import { eventResourceStyleGetter } from '../jobcalendar/calendarUtils'

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = momentLocalizer(moment)

const ResourceCalendar = ({ resourcesArr, item, engineerbookings, carbookings, kitbookings, bookingcreated, bookingupdated, setBookModal }) => {
    const [bookings, setBookings] = useState([])

    useEffect(() => {
        prepareResourcesArray(item)
    }, [item, bookingcreated, bookingupdated])

    const prepareResourcesArray = item => {
        let bookings = (item === "engineer" ? engineerbookings : (item === "kit" ? kitbookings : carbookings))

        let resourcesarr = Object.keys(bookings).map((k) => {
            return {
                id: bookings[k]._id,
                date: moment(bookings[k].start).toDate(),
                start: moment(bookings[k].start).toDate(),
                end: moment(bookings[k].end).toDate(),
                title: bookings[k].type,
                resourceId: bookings[k][`${item}_idfk`]
            }
        })

        setBookings(resourcesarr)
    }

    const resourceMap = Object.keys(resourcesArr).map((k) => {
        return {
            resourceId: resourcesArr[k]._id, 
            resourceTitle: resourcesArr[k].name
        }
    })

    return (
        <Row>
            <Col md={12}>
                <Widget title="Calendar" icon="calendar" paddingStyle="5px" buttonRight={<Button variant="primary" onClick={() => setBookModal(true)}>Book</Button>}>
                    <Calendar
                        localizer={localizer}
                        events={bookings}
                        step={60}
                        defaultView={Views.WORK_WEEK}
                        views={['day', 'work_week']}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 800 }}
                        resources={resourceMap}
                        resourceIdAccessor="resourceId"
                        resourceTitleAccessor="resourceTitle"
                        eventPropGetter={eventResourceStyleGetter}
                    />
                </Widget>
            </Col>
        </Row>  
    )
}

const mapStateToProps = ({ engineerbookings, carbookings, kitbookings }) => {
    return {
        engineerbookings, 
        carbookings, 
        kitbookings
    }
}

export default connect(mapStateToProps)(ResourceCalendar)