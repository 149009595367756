import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {random} from '../../functions'
import InvoiceTable from './Invoice Table'
import Widget from '../../elements/DashboardWidget'
import IconWidget6 from '../../icon-widgets/IconWidget6'
import { handleInitialData } from '../../actions/shared'
import "react-datepicker/dist/react-datepicker.css"
import { YearRangePicker } from './YearRangePicker'
import { Filter } from '../jobcalendar/Filter'
import { Row, Col, Alert } from 'react-bootstrap'
import { typeTest } from '../../utils/formatFunctions';
import YearRevenueChart from './Charts/YearRevenueChart'
import TypePieChart from './Charts/TypePieChart'
import ProjectLeaderJobChart from './Charts/ProjectLeaderJobChart'
import AllYearsRevenuesChart from './Charts/AllYearsRevenuesChart'
import EngineerJobsChart from './Charts/EngineerJobsChart'
import { projectLeadersDashboardFilter } from '../../utils/projectLeaders'



const AdminDashboard = ({ jobs, alljobs, startweek, endweek, users, dispatch }) => {
  const [range, setRange] = useState(`${moment(startweek).format('DD/MM/YYYY')} - ${moment(endweek).format('DD/MM/YYYY')}`)
  const [rangejobs, setRangeJobs] = useState(jobs)
  const {leaders} = projectLeadersDashboardFilter();
  
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null)
  const [delegated, setDelegated] = useState(null);
  const [delegatedChart, setDelegatedChart] = useState(null);
  const [internalJobs, setInternalJobs] = useState(0);
  const [externalJobs, setExternalJobs] = useState(0);
  const [projectedFee, setProjectedFee] = useState(0);
  const [invoicedFee, setInvoicedFee] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  const [year, setYear] = useState(moment().year());
  const [startDate, setStartDate] = useState(startweek);
  const [endDate, setEndDate] = useState(endweek);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  let allYears = [];
  Object.keys(alljobs).map(j => {
    if (!allYears.includes(moment(alljobs[j].startjob).year())){
      allYears.push(moment(alljobs[j].startjob).year())
    }
  })
  
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
  useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    
    }, []);
  
  useEffect(() => {
    dispatch(handleInitialData())
  }, [])

  useEffect(() => {
    onChange()
  }, [status, delegated])

  useEffect(() => {  
    onChange()
  }, [alljobs])


  const onChange = () => {

        let rangejobsarr = []
        Object.keys(alljobs).map(j => {

              let flag = true
      
              if (flag) {
                if (status !== null) {
                  let completed = status.name === "Completed Job" ? true : false;
                  
                  if (completed && moment() >= moment(alljobs[j].startjob) ) {
                    flag = true
                  } 
                  else if (!completed && moment() < moment(alljobs[j].startjob )) {
                    flag = true
                  }
                  else {
                    flag = false
                  }
                }
              }
          
              if (flag) {
                if (delegated !== null) {
                  let internal = delegated.name == "All External" ? false : true
                  let leader = (delegated.name !== "All External" && delegated.name!== "All Internal" ) ? delegated.name : null

                  if (leader === null && internal == alljobs[j].delegated) {                    
                    flag = true
                  } 
                  else if (leader !== null && alljobs[j].contact.project_leader == leader) {
                    flag = true
                  } 
                  else {
                    flag = false
                  }
                }
              }
              
              if (flag) {
                rangejobsarr.push(alljobs[j])
              }
            
        })
        rangejobsarr = rangejobsarr.filter( (j) => j.survey.type !== 'Pickup' && !j.completion.invoiced)
        setRangeJobs(rangejobsarr)

    };


    let turnover = 0;

    for (let i = 0; i < rangejobs.length; i++) {
        turnover = turnover + rangejobs[i].price
    }

    let reportsJobs = []

    Object.keys(alljobs).map((job) => {

      if (alljobs[job].completion.paid && !alljobs[job].completion.issued) {
        reportsJobs.push(alljobs[job])
      }
    })

      const widgets1 = [
        {
          bg: 'info',
          color: 'white',
          icon: 'assignment',
          text: 'Internal Jobs',
          number: internalJobs
        },
        {
          bg: 'success',
          color: 'white',
          icon: 'assignment',
          text: 'External Jobs',
          number: externalJobs
        },
        {
          bg: 'warning   ',
          color: 'white',
          icon: 'attach_money',
          text: 'Projected Fee',
          number: `£${projectedFee.toString().split(/(?=(?:...)*$)/).join(',')}`
        },
        {
          bg: 'danger',
          color: 'white',
          icon: 'attach_money',
          text: 'Invoiced Fee',
          number: `£${invoicedFee.toString().split(/(?=(?:...)*$)/).join(',')}`
        },        
      ]
     
      let today = new Date().toLocaleDateString('en-uk', { weekday:"long", year:"numeric", month:"long", day:"numeric"})
    
      return (

        <div className="container-fluid">
          <Row>            
            <Col lg={12} md={12}>
              <YearRangePicker allYears={allYears} setYear={setYear} year={year} />
            </Col>
          </Row>
          
            <div className="row">
                {widgets1.map((widget, i) => (
                  <div className="col-12 col-md-12 col-lg-3" key={i}>
                    <div className="row" key={i}>
                      <div className="col-12 m-b-10">
                        <IconWidget6 {...widget} />
                      </div>
                    </div>
                  </div>
                ))}
              <div className="col-12 col-xl-12">
                <Widget title={"Revenue breakdown " + year } icon="assignment" paddingStyle="5px">
                        <YearRevenueChart year={year} alljobs={alljobs} setInternalJobs={setInternalJobs} setExternalJobs={setExternalJobs} setProjectedFee={setProjectedFee} setInvoicedFee={setInvoicedFee} setTotalHours={setTotalHours} type={type} delegatedChart={delegatedChart} />
                </Widget>
              </div>
            </div>  
            <Row>
              <Col lg={6} sm={12}>
                    <Alert key={1} variant={"secondary"}>
                      <Row>
                        <Filter title="Status" controlId="typeSelect" setFunction={setStatus} data={[{"name" : "Completed Job"}, {"name" : "Future Job"}]} cols={6} margin=""/>
                        <Filter title="Delegated" controlId="delegateSelect" setFunction={setDelegated} data={leaders} cols={6} margin=""/>
                      </Row>
                    </Alert>
              </Col>

              <Col lg={6} sm={12}>
                    <Alert key={1} variant={"secondary"}>
                      <Row>
                        <Filter title="Type" controlId="typeSelect" setFunction={setType} data={[{"name" : "Air Test"}, {"name" : "Sound Test"}, {"name" : "Noise Surveys"}]} cols={6} margin=""/>
                        <Filter title="Delegated" controlId="delegateSelect" setFunction={setDelegatedChart} data={leaders} cols={6} margin=""/>
                      </Row>
                    </Alert>
              </Col>
            </Row>

            <Row>
              <Col lg={6} sm={12}>
                    <Widget title={"Jobs to Invoice as of " + today } paddingStyle="5px">
                      <InvoiceTable jobs={rangejobs}  filter={[]} />
                    </Widget>
              </Col>
              <Col lg={6} sm={12}>
                <Row>
                  <Col lg={6} sm={12} >
                    <Widget title={"Job type breakdown "+ year} paddingStyle="5px" >
                      <TypePieChart alljobs={alljobs} year={year} delegatedChart={delegatedChart} type={type}/>
                    </Widget>
                  </Col>
                  <Col lg={6} sm={12} >
                    <Widget title={"Engineer jobs breakdown "+ year} paddingStyle="5px">
                      <EngineerJobsChart alljobs={alljobs} year={year} users={users} type={type} delegatedChart={delegatedChart} />
                    </Widget>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={12} >
                    <Widget title="Yearly Revenues" paddingStyle="5px">
                      <AllYearsRevenuesChart alljobs={alljobs} allYears={allYears} type={type} delegatedChart={delegatedChart} />
                    </Widget>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} sm={12} >
                    <Widget title={"Project Leader breakdown "+ year} paddingStyle="5px">
                      <ProjectLeaderJobChart alljobs={alljobs} year={year} type={type} delegatedChart={delegatedChart}/>
                    </Widget>
                  </Col>
                </Row>
              </Col>
            </Row>


                  
                    

             

        </div>
      )
}

const mapStateToProps = ({ jobs, users }) => {
    let d = new Date()
    let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1)
    let dayend = d.getDay(),
        diffend = d.getDate() - dayend + (dayend == 0 ? -6 : 7)
    let startweek = new Date(d.setDate(diff)) // get Monday
    let endweek = new Date(d.setDate(diffend)) // get Sunday

    const weekjobs = []

    Object.keys(jobs).map(j => (moment(jobs[j].startjob) >= moment(startweek).startOf('day') && moment(jobs[j].startjob) <= moment(endweek).endOf('day')) && weekjobs.push(jobs[j]))

    return {
        jobs: weekjobs,
        alljobs: jobs,
        startweek,
        endweek,
        users //: Object.keys(users).filter((user) => users[user].isEngineer)
    }
}

export default connect(mapStateToProps)(AdminDashboard)