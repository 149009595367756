import React from 'react'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import '../../css/elements/logo.css'

const Logo = ({ auth }) => {
  let bctsflag = false
  if (auth.user !== null) {
    if (auth.user.name === "Brian Cunningham") {
      bctsflag = true
    }
  }

  return (
    <Link
      to="/"
      className="logo d-flex justify-content-start align-items-center flex-nowrap">
      <img src={bctsflag ? "http://machgroup.co.uk/BCTSLogo.png" : "http://machgroup.co.uk/JPLogo.png"} height="70"/>
    </Link>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

export default connect(mapStateToProps)(Logo)
