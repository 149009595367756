import React, { useState, useEffect } from 'react'
import { Row, Container, Col, InputGroup, Form, FormControl, Button } from 'react-bootstrap'
import GoogleMapComponent from '../dashboard/GoogleMapComponent'
import Postcode from './Postcode'
import { calculateFee, handlePostcode } from './feeFunctions'

const STBB93 = ({ numberoftests, setNumberTests, postcode, setPostcode, setFeeBreakdown }) => {
    const [teachingspaces, setTeachingSpaces] = useState(0)
    const [postcodeerror, setPostcodeError] = useState(false)

    useEffect(() => {
        handlePostcode(postcode, setPostcodeError, setFeeBreakdown, setPostcode)
    }, [postcode.text])

    useEffect(() => {
        if (teachingspaces > 0) {
            setNumberTests({
                "Airborne Walls": Math.ceil(teachingspaces / 4),
                "Airborne Floors": Math.ceil(teachingspaces / 4),
                "Impacts": Math.ceil(teachingspaces / 4),
                "Backgrounds": Math.ceil(teachingspaces / 4),
                "RTs": Math.ceil(teachingspaces / 4)
            })
        }
    }, [teachingspaces])

    useEffect(() => {
        if (postcodeerror === false) {
            const feebreakdown = calculateFee(numberoftests, postcode, "sound")
            postcodeerror === false && setFeeBreakdown(feebreakdown)
        }
    }, [postcodeerror, numberoftests])

    return (
        <Row>
            <Col lg={12} md={12}>
                <Row>
                    <Col lg={6} md={6} sm={6}>
                        <Form.Group controlId="formGridEnd">
                            <Form.Label>Teaching Spaces</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="material-icons">home</i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="number" placeholder="Teaching Spaces" name="teachingspaces" onChange={e => setTeachingSpaces(e.target.value)} required/>
                            </InputGroup>
                        </Form.Group>   
                    </Col>
                    <Postcode postcodeerror={postcodeerror} setPostcode={setPostcode} />
                </Row>
                
                {(postcodeerror === false && postcode.geocode.lat != '' && postcode.text.length > 2) &&
                    <GoogleMapComponent 
                        jobs={[{
                            _id: 1,
                            geocode: postcode.geocode,
                            address: '',
                            date: new Date()
                        }]}
                        center={postcode.geocode}
                        height={20}
                    />
                }
            </Col>
        </Row>
    )
}

export default STBB93