import { RECEIVE_JOBS, NEW_JOB, UPDATE_JOB, DELETE_JOB } from "../actions/jobs"

export function jobs(state = {}, action) {
    switch(action.type) {
        case RECEIVE_JOBS:
            return {
                ...state,
                ...action.jobs
            }
        case NEW_JOB:
            let updatedjobs = {
                ...state
            };

            for (let job in action.jobs) {
                updatedjobs[job._id] = job;
            }

            return updatedjobs;
        case UPDATE_JOB:
            let updatedjobsObj = {
                ...state
            };

            let jobs = action.jobs;

            for (let job in jobs) {
                updatedjobsObj[jobs[job]._id] = jobs[job];
            }

            return updatedjobsObj
        case DELETE_JOB:
            let updatedstate = {}
            let newState = Object.keys(state).filter((key) => key !== action.jobid) // array of keys minus deleted
            newState.forEach(n => (updatedstate[n] = state[n]))

            return updatedstate
        default:
            return state
    }
}