import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { deleteJob } from '../../actions/jobs'
import { Redirect } from 'react-router-dom'
import { deleteJobFromEnquiry } from '../../actions/enquiries'
import { endpoint } from '../../utils/api'

const RemoveJobModal = ({ removejobmodal, openRemoveModal, jobid, dispatch, enquiryid, openRemoveAlert }) => {
    const removeJob = async (jobid) => {
        await axios({
                    method: "DELETE",
                    url: `${endpoint}/api/job/${jobid}`,
                    headers: {
                        Authorization: 'Bearer '+ localStorage.token
                    }
                })
                .then(function (response) {
                    if (response.status === 200) {
                        console.log("Message Sent.")
                        console.log(response)                      
                        dispatch(deleteJob(response.data.id))
                        dispatch(deleteJobFromEnquiry(response.data.id, enquiryid))
                        window.location.href = "/jobs"
                    } else {
                        console.log(response.msg)
                        console.log(response.statusText)  
                        console.log(response.errors)           
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
    }

    return (
        <Modal show={removejobmodal} onHide={() => openRemoveModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'red' }}>Delete Job</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to delete this job?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => openRemoveModal(false)}>Close</Button>
                <Button variant="danger" onClick={() => removeJob(jobid)}><i className="material-icons">delete</i>&nbsp;Delete Job</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveJobModal