import React from 'react'
import { InputGroup, Form, Col, FormControl, Row } from 'react-bootstrap'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoundSign, faMicrophoneAlt, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import '../../../css/icons/fontAwesomeIcons.css'

library.add(faPoundSign, faMicrophone, faMicrophoneAlt)
const NSType1 = ({job, linkJob}) => {  
    return (
        <div>
                <Form.Group as={Col} controlId="formGridFee">
                    <Form.Label>Fee</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon={faPoundSign}  size='1x' className='poundIcon' /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="Fee..." name="price" type="number" defaultValue={job !== null ? linkJob != null ? job.price + linkJob.price : '' : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose a fee.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Number of Fixed</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons"><FontAwesomeIcon icon={faMicrophoneAlt}  size='1x' className='micAltIcon' /></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Fixed..." name="fixed" type="number" defaultValue={job !== null ? job.survey.fixed : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose the number of Fixed.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Number of Spots</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons"><FontAwesomeIcon icon={faMicrophone}  size='1x' className='micIcon' /></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Spots..." name="spots" type="number" defaultValue={job !== null ? job.survey.spots : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose the number of spots.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </div>
    )

}

export default NSType1
