import React, { useState } from 'react'
import { Row, Container, Col, InputGroup, Form, Button } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import STPartE from './STPartE'
import STBB93 from "./STBB93"
import AirForm from './AirForm'
import { FeeResult } from './FeeResult'
import ProjectDetails from './ProjectDetails'
import axios from 'axios'
import TestAmount from './TestAmount'
import { endpoint } from '../../utils/api'

const BookForm = ({ setShowCalendar, setTestType, testtype }) => {
    const [numberoftests, setNumberTests] = useState({})
    const [feebreakdown, setFeeBreakdown] = useState({
        fee: 0,
        travel: 0,
        sitevisits: 0
    })
    const [postcode, setPostcode] = useState({
        text: '',
        driving: {
            text: '',
            value: 0
        },
        geocode: {
            lat: '',
            lng: ''
        }
    })
    
    const onChange = e => {
        setTestType(e.target.value)
        setNumberTests({})
        setPostcode({
            text: '',
            driving: {
                text: '',
                value: 0
            },
            geocode: {
                lat: '',
                lng: ''
            }
        })
        setShowCalendar(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())
        formDataObj.fee = feebreakdown
        formDataObj.type = testtype.includes("Sound") ? "Sound" : (testtype.includes("Air") ? "Air" : "Sound") 

        await axios({
                method: 'POST',
                url: `${endpoint}/api/book/fee`,
                data: formDataObj,
                responseType: 'blob'
            })
            .then(function (response) {
                console.log(response)
                if (response.status === 200) {
                    console.log("Message Sent.")

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${formDataObj.projectname} - FeeProposal.docx`); 
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <Widget title="Enter Test" icon="project" paddingStyle="5px">
                <Form.Group as={Col} >
                    <Form.Label>Test Type</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">speaker_group</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" name="type" onChange={onChange}>
                            <option>Please Select...</option>
                            <option>Sound Testing - Part E</option>
                            <option>Sound Testing - Part E - RFRP</option>
                            <option>Sound Testing - Part E - Hotel</option>
                            <option>Sound Testing - Hotel 24 Hours</option>
                            <option>Sound Testing - BB93</option>
                            <option>Sound Testing - Office</option>
                            <option>Sound Testing - HTM</option>
                            <option>Sound Testing</option>
                            <option>Air Testing</option>
                            <option>Noise Survey</option>
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </Widget>
            <Form onSubmit={handleSubmit}>
                {testtype !== 'Please Select...' && 
                    <div className="widget">
                        <div className="row">
                            <div className="col">
                    
                                {testtype !== 'Please Select...' && <ProjectDetails />}

                                {testtype === "Sound Testing - Part E" && <STPartE numberoftests={numberoftests} setNumberTests={setNumberTests} rfrp={false} postcode={postcode} setPostcode={setPostcode} setFeeBreakdown={setFeeBreakdown} />}
                                {testtype === "Sound Testing - Part E - RFRP" && <STPartE numberoftests={numberoftests} setNumberTests={setNumberTests} rfrp={true} postcode={postcode} setPostcode={setPostcode} setFeeBreakdown={setFeeBreakdown} />}
                                {testtype === "Sound Testing - BB93" && <STBB93 numberoftests={numberoftests} setNumberTests={setNumberTests} postcode={postcode} setPostcode={setPostcode} setFeeBreakdown={setFeeBreakdown}/>}
                                {testtype === "Sound Testing - Other" && <STPartE numberoftests={numberoftests} setNumberTests={setNumberTests} rfrp={false} postcode={postcode} setPostcode={setPostcode} setFeeBreakdown={setFeeBreakdown} />}
                                {testtype === "Air Testing" && <AirForm numberoftests={numberoftests} setNumberTests={setNumberTests} postcode={postcode} setPostcode={setPostcode} setFeeBreakdown={setFeeBreakdown}/>}

                            </div>
                        </div>
                    </div>
                }
                {testtype !== 'Please Select...' && Object.entries(numberoftests).length > 0 && 
                    <div className="widget">
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <TestAmount numberoftests={numberoftests} postcode={postcode} />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                {testtype !== null && <FeeResult feebreakdown={feebreakdown} />}

                                {testtype !== null &&
                                    <Button variant="success" type="submit">
                                        + Create Project
                                    </Button>
                                }

                                {testtype !== null && 
                                    <Button variant="primary" type="submit">
                                        <i className="material-icons">file_download</i>&nbsp;Download Fee Proposal
                                    </Button>
                                }  
                            </Col>
                        </Row>
                    </div>
                }
            </Form>
        </>
    )
}

export default BookForm