import axios from 'axios'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCRA2EknATAiksUEj0G31Z6OLUhRmyjNi0'
export const endpoint = "https://calendar.jptesting.co.uk:8080" //"http://localhost:9000" 

export function getInitialData() {
    return Promise.all([
            getJobs(),
            getUsers(),
            getEnquiries(),
            getCars(),
            getKits(),
            getConfig(),
            getEngineerBookings(),
            getKitBookings(),
            getCarBookings()
        ]).then(([jobs, users, enquiries, cars, kits, config, engineerbookings, kitbookings, carbookings]) => ({
            jobs,
            users,
            enquiries,
            cars,
            kits,
            config,
            engineerbookings,
            kitbookings,
            carbookings
        }))
}

export const getJobs = () => {
    return fetch(`${endpoint}/api/job`, {
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                }
            })
            .then(res => res.json())
            .then(j => {
                let jobs = {}
                for (let i = 0; i < Object.keys(j).length; i++) {
                    jobs[j[i]._id] = j[i]
                }
                return jobs
            })
}

export const getUsers = () => {
    return fetch(`${endpoint}/api/users`, {
        headers: {
          Authorization: 'Bearer '+ localStorage.token
        }
      })
        .then(res => res.json())
        .then(u => {
            let users = {}
            for (let i = 0; i < Object.keys(u).length; i++) {
                users[u[i]._id] = u[i]
            }
            return users
        })
}

export const getEnquiries = () => {
    return fetch(`${endpoint}/api/enquiry`, {
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                }
            })
            .then(res => res.json())
            .then(e => {
                let enquiries = {}
                for (let i = 0; i < Object.keys(e).length; i++) {
                    enquiries[e[i]._id] = e[i]
                }
                return enquiries
            })
}

export const getCars = () => {
    return fetch(`${endpoint}/api/car`, {
                headers: {
                Authorization: 'Bearer '+ localStorage.token
                }
            })
            .then(res => res.json())
            .then(e => {
                let cars = {}
                for (let i = 0; i < Object.keys(e).length; i++) {
                    cars[e[i]._id] = e[i]
                }
                return cars
            })
}

export const getKits = () => {
    return fetch(`${endpoint}/api/kits`, {
                headers: {
                    Authorization: 'Bearer '+ localStorage.token
                }
            })
            .then(res => res.json())
            .then(e => {
                let kits = {}
                for (let i = 0; i < Object.keys(e).length; i++) {
                    kits[e[i]._id] = e[i]
                }
                return kits
            })
}

function getConfig() {
    return {
        layout: 'default-sidebar-1',
        navbar: 'light',
        logo: 'info',
        leftSidebar: 'dark'
      }
}

export const updateJobAPI = (formData) => {
    return axios.put(`${endpoint}/api/job`, formData, {
                headers: {
                Authorization: 'Bearer '+ localStorage.token
                }
            })
}

export const getResourceBookings = resource => {
    return fetch(`${endpoint}/api/${resource}bookings`, {
        headers: {
            Authorization: 'Bearer '+ localStorage.token
        }
    })
    .then(res => res.json())
    .then(e => {
        let bookings = {}
        for (let i = 0; i < Object.keys(e).length; i++) {
            bookings[e[i]._id] = e[i]
        }
        return bookings
    })
}

export const getEngineerBookings = () => {
    return fetch(`${endpoint}/api/engineerbookings`, {
        headers: {
            Authorization: 'Bearer '+ localStorage.token
        }
    })
    .then(res => res.json())
    .then(e => {
        let engineerbookings = {}
        for (let i = 0; i < Object.keys(e).length; i++) {
            engineerbookings[e[i]._id] = e[i]
        }
        return engineerbookings
    })
}

export const getKitBookings = () => {
    return fetch(`${endpoint}/api/kitbookings`, {
        headers: {
            Authorization: 'Bearer '+ localStorage.token
        }
    })
    .then(res => res.json())
    .then(e => {
        let kitbookings = {}
        for (let i = 0; i < Object.keys(e).length; i++) {
            kitbookings[e[i]._id] = e[i]
        }
        return kitbookings
    })
}

export const getCarBookings = () => {
    return fetch(`${endpoint}/api/carbookings`, {
        headers: {
            Authorization: 'Bearer '+ localStorage.token
        }
    })
    .then(res => res.json())
    .then(e => {
        let carbookings = {}
        for (let i = 0; i < Object.keys(e).length; i++) {
            carbookings[e[i]._id] = e[i]
        }
        return carbookings
    })
}