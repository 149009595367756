import React from 'react'
import { formatDate, formatFee } from '../../utils/formatFunctions'
import { connect } from 'react-redux'
import { typeTest } from '../../utils/formatFunctions'

const ReportsTable = ({ jobs, users }) => {

  const handleRowClick = (id) => {
    window.location.href = `/jobs/${id}`
  }  

  return (
    <div className="table-widget-4">
      <table className="table table-unbordered table-striped table-responsive">
        <thead>
          <tr>
              <th>Test Type</th>
              <th>Date</th>
              <th>Site Address</th>
              <th className="col-hide">Client</th>
              <th className="col-hide">Fee</th>
              <th className="col-hide">Engineers</th>
              <th className="col-hide">Delegated</th>
          </tr>
        </thead>
        <tbody>
              {jobs.sort((a,b) => (a.startjob > b.startjob) ? 1 : ((b.startjob > a.startjob) ? -1 : 0)).map(j => (
                  <tr key={j._id} className="pointer" onClick={() => handleRowClick(j._id)}>
                      <td>{typeTest(j) == 'sound' ? 'Sound Test' : typeTest(j) == 'air' ? 'Air Test' : 'Noise Survey'}</td>
                      <td>{formatDate(j.startjob)}</td>
                      <td>{j.address}</td>                      
                      <td className="col-hide">{j.contact.name}</td>
                      <td className="col-hide">{formatFee(j.price)}</td>
                      <td>
                        {j.engineers.map((e, i) => {
                          let name;
                          Object.keys(users).map(u => {                 
                            if(e == u){                        
                              if (j.engineers.length === i+1){
                                name = users[u].name + ' '
                              } else {
                                name = users[u].name + ',  '
                              }  
                            }     
                          })
                          return name           
                        })}
                        </td>
                      <td className="col-hide" style={{textAlign: 'center'}}>
                        {j.delegated ?
                          <i style={{ color: 'green' }} className={'fa fa-lg fa-check'} /> :
                          <i style={{ color: 'red' }} className={'fa fa-lg fa-times'} />}
                      </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = ({ users }) => {
  return {
    users
  }
}

export default connect(mapStateToProps)(ReportsTable)