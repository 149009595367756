import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import axios from 'axios'
import { endpoint } from '../../utils/api'
import { Modal, Button, Table, Form, Col } from 'react-bootstrap'

class NewJobModal extends Component {
    handleSubmit(e) {
        e.preventDefault();
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const message = document.getElementById('message').value;

       axios({
        method: "POST", 
        url: `${endpoint}/send`, 
        data: {
            name: name,   
            email: email,  
            messsage: message
        }
        }).then((response)=>{
            if (response.data.msg === 'success'){
                alert("Message Sent."); 
                this.resetForm()
            }else if(response.data.msg === 'fail'){
                alert("Message failed to send.")
            }
        })
    }

    resetForm() {
        document.getElementById('contact-form').reset();
    }

    render() {
        const { open, openModal } = this.props

        return (
          <Modal
            show={open}
            onHide={() => openModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Create New Job
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
          

            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                <div className="form-group">
                    <label for="name">Name</label>
                    <input type="text" className="form-control" id="name" />
                </div>
                <div className="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp" />
                </div>
                <div className="form-group">
                    <label for="message">Message</label>
                    <textarea className="form-control" rows="5" id="message"></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => openModal(false)}>Close</Button>
            </Modal.Footer>
          </Modal>
        )
    }
}

export default NewJobModal