import React, { useState, useReducer, useEffect } from 'react'
import { connect } from 'react-redux'
import Widget from '../../elements/DashboardWidget'
import AlertComponent from '../alerts/AlertComponent'
import { kits as kitsReducer } from '../../reducers/kits'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import NewKitModal from './NewKitModal'
import RemoveKitModal from './RemoveKitModal'
import { getKits, getResourceBookings } from '../../utils/api'
import BookingsTable from '../engineers/BookingsTable'

const Kits = ({ kits, kitbookings, dispatch }) => {
    const [newkitmodal, openNewKit] = useState(false)
    const [removekitmodal, openRemoveKit] = useState(false)
    const [kitcreated, kitCreated] = useState(false)
    const [kitupdated, kitUpdated] = useState(false)
    const [kitremoved, kitRemoved] = useState(false)
    const [kiterror, kitError] = useState(false)

    const [kitsArr, dispatchKit] = useReducer(kitsReducer, kits)

    const [bookingupdated, setBookingUpdated] = useState(false)
    const [bookingcreated, setBookingCreated] = useState(false)
    const [bookingerror, setBookingError] = useState(false)

    return (
        <Container id="main-container">
            {kitupdated && <AlertComponent type="primary" text="Kit Updated!"/>}
            {kitcreated && <AlertComponent type="success" text="Kit Created!"/>}
            {kitremoved && <AlertComponent type="danger" text="Kit Removed!"/>}
            {kiterror !== false && <AlertComponent type="danger" text={kiterror}/>}

            {bookingupdated && <AlertComponent type="primary" text="Booking Updated!"/>}
            {bookingcreated && <AlertComponent type="success" text="Booking Created!"/>}
            {bookingerror && <AlertComponent type="danger" text="Booking Removed!"/>}

            <Row>
                <Col md={12}>
                <Widget title="Kits"  paddingStyle="5px" buttonRight={<Button variant="primary" onClick={() => openNewKit(true)}>+ New Kit</Button>}>

                        <Table striped border hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(kitsArr).map((kit) => {
                                    if (kitsArr[kit].is_deleted === false) {
                                        return <tr key={kitsArr[kit]._id}>
                                                    <td>{kitsArr[kit].name}</td>
                                                    <td>{kitsArr[kit].type}</td>
                                                    <td>
                                                        <div style={{width: '100%'}}>
                                                            <div style={{display: "inline-block"}}> 
                                                                <button className="btn btn-primary btn-sm" onClick={() => openNewKit(kitsArr[kit])}><i className="material-icons">edit</i></button>
                                                            </div>
                                                            <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                                                                <button className="btn btn-danger btn-sm" onClick={() => openRemoveKit(kitsArr[kit])}><i className="material-icons">delete</i></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                    }
                                })}
                            </tbody>
                        </Table>
                    </Widget>
                </Col>
            </Row>    

            <BookingsTable bookings={kitbookings} type="kit" data={kits} setBookingUpdated={setBookingUpdated} setBookingCreated={setBookingCreated} setBookingError={setBookingError} />

            {newkitmodal && <NewKitModal newkitmodal={newkitmodal} openNewKit={openNewKit} kitCreated={kitCreated} kitUpdated={kitUpdated} kitError={kitError} dispatch={dispatch} dispatchKit={dispatchKit}/>}
            {removekitmodal && <RemoveKitModal removekitmodal={removekitmodal} openRemoveKit={openRemoveKit} kitRemoved={kitRemoved} kitUpdated={kitUpdated} dispatchKit={dispatchKit} kitError={kitError}/>}

        </Container>
    )
}

function mapStateToProps({ kits, kitbookings }) {
    return {
        kits, 
        kitbookings
    }
}

export default connect(mapStateToProps)(Kits)