import { RECEIVE_KITS, NEW_KIT, UPDATE_KIT, REMOVE_KIT } from "../actions/kits"

export function kits(state = {}, action) {
    switch(action.type) {
        case RECEIVE_KITS:
            return {
                ...state,
                ...action.kits
            }
        case NEW_KIT:
            return {
                ...state,
                [action.kit._id]: action.kit               
            }
        case UPDATE_KIT:
            return {
                ...state,
                [action.kit._id]: action.kit               
            }
        case REMOVE_KIT:
            /*let updatedstate = {}
            let newState = Object.keys(state).filter((key) => key !== action.kitid) // array of keys minus deleted
            newState.forEach(n => (updatedstate[n] = state[n]))*/

            return {
                ...state,
                [action.kitid]: {
                    ...state[action.kitid],
                    is_deleted: true
                }
            }
        default:
            return state
    }
}