import {React} from 'react'
import { InputGroup, Form, Col, FormControl } from 'react-bootstrap'
import { faBed, faPoundSign } from '@fortawesome/free-solid-svg-icons';
import '../../../css/icons/fontAwesomeIcons.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faBed, faPoundSign)

const SoundType3 = ({job}) => {
    return (
        <div>

                <Form.Group as={Col} controlId="formGridFee">
                    <Form.Label>Fee</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon={faPoundSign}  size='1x' className='poundIcon' /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="Fee..." name="price" type="number" defaultValue={job !== null ? job.price : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose a fee.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
           
                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Number of Rooms</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon={faBed}  size='1x' className='roomIcon' /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Rooms..." name="rooms" type="number" defaultValue={job !== null ? job.soundtest.rooms : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose the number of rooms.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

        </div>
    )

}

export default SoundType3