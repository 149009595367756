import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, loginSuccess, loginFail, LOGIN_SUCCESS, loadUser } from '../../actions/auth';
import { Container, Row, Col, Form, Button, Breadcrumb, Card, InputGroup, Accordion } from 'react-bootstrap'
import axios from 'axios'
import { endpoint } from '../../utils/api';

const Login = ({ dispatch, isAuthenticated }) => {
    const [loginfail, setLoginFail] = useState(false)
    const [validated, setValidated] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target),
             formDataObj = Object.fromEntries(formData.entries())
         
          await axios.post(`${endpoint}/api/auth`, formDataObj)
                    .then(function (response) {
                        if (response.status === 200) {
                            console.log("Message Sent.")
                            console.log(response)  
                            console.log("Message Sent.")
                            console.log(response.data.token)
                            dispatch(loginSuccess(response.data))  
                            dispatch(loadUser())  
                        } else {
                            console.log(response.statusText)  
                            console.log(response.errors)   
                            dispatch(loginFail())  
                            setLoginFail(true)  
                            setValidated(true)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        setLoginFail(true)  
                        setValidated(true)
                    })
    }

    if (isAuthenticated) {
       return <Redirect to="/dashboard"/>
    }

    return (
        <div className="login-container">
                <Container className="login">
                    <Row>
                        <Col>
                            <div style={{textAlign: 'center'}}>
                                <div className="loginLogo" style={{ display: 'block' }} />
                            </div>
                            <Form 
                                onSubmit={handleSubmit}
                                title="Login"
                                description="Please enter your name and email address to login"
                                >
                                <Form.Group as={Col} controlId="formGridContactEmail">
                                    <Form.Label>Email</Form.Label>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>@</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control placeholder="Email..." name="email" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridContactEmail">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><i className="material-icons">vpn_key</i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type="password" placeholder="Password..." name="password"/>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid password.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                {loginfail && <span style={{color:'red'}}>Incorrect Credentials!</span>}

                                <div className="form-group" style={{textAlign: 'center'}}>
                                    <button
                                        className="btn btn-primary btn-lg"
                                        type="submit">           
                                        Login
                                    </button>
                                </div>

                                <div className="links">
                                    <p>
                                        <span className="m-r-5">Don't have an account?</span>
                                        <Link to="/pages/create-account">Create account</Link>
                                    </p>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

export default connect(mapStateToProps)(Login)
