import React from 'react'
import { InputGroup, Form, Col, FormControl } from 'react-bootstrap'
import Widget from '../../elements/DashboardWidget'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoundSign, faSortNumericDown, faFan } from '@fortawesome/free-solid-svg-icons';
import '../../css/icons/fontAwesomeIcons.css'

library.add(faPoundSign , faSortNumericDown, faFan)

const AirForm = ({ job }) => {
    return (
        <div className="col-12 col-lg-4">
            <Widget title="Air Testing" fontIcon = {faFan} paddingStyle="5px">
                <Form.Group as={Col} controlId="formGridFee">
                    <Form.Label>Fee</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon={faPoundSign}  size='1x' className='poundIcon'  /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="Fee..." name="price" type="number" defaultValue={job !== null ? job.price : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose a fee.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Number of Types</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">home</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Types..." name="types" type="number" defaultValue={job !== null ? job.airtest.types : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose the number of types.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Number of Tests</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon={faSortNumericDown}  size='1x' className='testIcon'  /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Tests..." name="tests" type="number" defaultValue={job !== null ? job.airtest.tests : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose the number of types.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Widget>
        </div>
    )
}

export default AirForm