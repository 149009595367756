import { LOGIN_SUCCESS, LOGIN_FAIL, USER_LOADED, LOGOUT, AUTH_ERROR, REGISTER_FAIL, REGISTER_SUCCESS} from "../actions/auth"

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: null
  }

export default function auth(state = initialState, action) {
    const { payload } = action

    switch(action.type) {
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            console.log(payload)
            
            localStorage.setItem('token', payload.token)
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            }
        
        
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            }
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token')
            return {                
                ...state,
                isAuthenticated: false,
                loading: false,
                user: null
            }
        default:
            return state
    }
}