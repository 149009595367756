import {React, useState, useEffect} from 'react'
import Widget from '../../elements/DashboardWidget'
import { Col, Form, InputGroup } from 'react-bootstrap'
import { projectLeaders } from '../../utils/projectLeaders'

export const ProjectDetails = ({ enquiry }) => {
    const {leaders} = projectLeaders();
    const formatDate = (enquiry) => {
        if (enquiry !== null) {
            let date = new Date(enquiry.date)
           
            return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2)
        } else {
            return ''
        }
    }

    const [delegatedCheck, setDelegatedCheck] = useState(false)    
    useEffect(() => {
        setDelegatedCheck(enquiry !== null ? enquiry.delegated : false)
    }, [ enquiry])
   
    useEffect(() => {
    }, [delegatedCheck])


    return (
        <Widget title="Project Details" icon="date_range" paddingStyle="5px">
            
            <Form.Group as={Col} controlId="formGridProjectName">
                <Form.Label>Project Name</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">bookmark</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control placeholder="Project Name..." name="projectName" defaultValue={enquiry !== null ? enquiry.projectName : ''} required/>
                </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridProjectNumber">
                <Form.Label>Project Number</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">assignment</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control placeholder="Project Number..." name="project_number" type="text" defaultValue={enquiry !== null ? enquiry.project_number != null ? enquiry.project_number : '' : ''} required/>
                </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Enquiry Date</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="material-icons">event</i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control type="date" name="date" placeholder="Test Date" name="date" defaultValue={enquiry !== null ? formatDate(enquiry) : ''} />
                </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridFee">
                <Form.Label>Fee</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>£</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control placeholder="Fee..." name="price" type="number" defaultValue={enquiry !== null ? enquiry.price : ''} required/>
                    <Form.Control.Feedback type="invalid">
                        Please choose a fee.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

            
            <Form.Group  as={Col} controlId="formGridDelegated">
                <input 
                    className="delegatedCheckBox"
                    type="checkbox"
                    label="delegated"
                    controlId="delegated"
                    name="delegated" 
                    checked={delegatedCheck}  
                    onChange={() => setDelegatedCheck(!delegatedCheck)}
                    
                />
                <label className="delegatedCheckLabel" for="delegated">&nbsp;&nbsp;&nbsp;&nbsp;Delegated</label>
            </Form.Group>  

            { delegatedCheck && 
                <Form.Group as={Col} controlId="formGridContactName">
                    <Form.Label>Project Leader</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">how_to_reg</i></InputGroup.Text>
                        </InputGroup.Prepend>
                            <Form.Control as="select" name="project_leader">
                                {leaders.map((option) => {
                                    if (enquiry !== null){                                        
                                        return <option  selected={enquiry.contact.project_leader === option.value} value={option.value}>{option.label}</option>
                                    } else {
                                        return <option value={option.value}>{option.label}</option>
                                    }
                                })}
                            </Form.Control>
                        </InputGroup>
                </Form.Group>
            }
        </Widget>
    )
}
