import React, { useEffect, useReducer, useState } from 'react'
import { connect } from 'react-redux'
import Widget from '../../elements/DashboardWidget'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import { users as engineerReducer } from '../../reducers/users'
import BookingsTable from './BookingsTable';
import NewEngineerModal from './NewEngineerModal';
import RemoveEngineerModal from './RemoveEngineerModal'
import AlertComponent from '../alerts/AlertComponent'

const Engineers = ({ users, engineerbookings, dispatch }) => {

    const [engineers, dispatchEngineer] = useReducer(engineerReducer, users)
    const [bookingupdated, setBookingUpdated] = useState(false)
    const [bookingcreated, setBookingCreated] = useState(false)
    const [bookingerror, setBookingError] = useState(false)
    const [newEngineer, openNewEngineer] = useState(false);
    const [removeEngineer, openRemoveEngineer] = useState(false);
    const [engineercreated, engineerCreated] = useState(false)
    const [engineerupdated, engineerUpdated] = useState(false)
    const [engineerremoved, engineerRemoved] = useState(false)
    const [engineererror, engineerError] = useState(false)

    return (
        <Container id="main-container">

            {engineerupdated && <AlertComponent type="primary" text="Engineer Updated!"/>}
            {engineercreated && <AlertComponent type="success" text="Engineer Created!"/>}
            {engineerremoved && <AlertComponent type="danger" text="Engineer Removed!"/>}
            {engineererror && <AlertComponent type="danger" text={engineererror}/>}

            {bookingupdated && <AlertComponent type="primary" text="Booking Updated!"/>}
            {bookingcreated && <AlertComponent type="success" text="Booking Created!"/>}
            {bookingerror && <AlertComponent type="danger" text="Booking Removed!"/>}

            <Row>
                <Col md={12}>
                    <Widget title="Engineers" paddingStyle="5px"  buttonRight={<Button variant="primary" onClick={() => openNewEngineer(true)}>+ New Engineer </Button>} >
                        <Table striped border hover className="table-responsive">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Engineer</th>
                                    <th>Deleted?</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(engineers).map((user) => {
                                       
                                        return <tr key={engineers[user]._id}>
                                                <td>{engineers[user].name}</td>
                                                <td>{engineers[user].email}</td>                        
                                                <td>{engineers[user].isEngineer ? "Yes" : "No"}</td>
                                                <td>{engineers[user].is_deleted === true ? "Yes" : "No"}</td>
                                                <td>
                                                    <div style={{width: '100%'}}>
                                                        <div style={{display: "inline-block"}}> 
                                                            <button className="btn btn-primary btn-sm" onClick={() => openNewEngineer(engineers[user])}><i className="material-icons">edit</i></button>
                                                        </div>
                                                        <div style={{display: "inline-block", paddingLeft: '3px'}}> 
                                                            <button className="btn btn-danger btn-sm" onClick={() => openRemoveEngineer(engineers[user])}><i className="material-icons">delete</i></button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                            
                                )}
                                
                            </tbody>
                        </Table>
                    </Widget>
                </Col>
            </Row>    

            <BookingsTable bookings={engineerbookings} type="engineer" data={users} setBookingUpdated={setBookingUpdated} setBookingCreated={setBookingCreated} setBookingError={setBookingError} />
            {newEngineer && <NewEngineerModal newEngineer={newEngineer} openNewEngineer={openNewEngineer} engineerCreated={engineerCreated} engineerUpdated={engineerUpdated} engineerError={engineerError} dispatch={dispatch} dispatchEngineer={dispatchEngineer} />}
            {removeEngineer && <RemoveEngineerModal removeEngineer={removeEngineer} openRemoveEngineer={openRemoveEngineer} engineerRemoved={engineerRemoved} dispatchEngineer={dispatchEngineer} engineerError={engineerError}/>}
        </Container>
    )
}

function mapStateToProps({ users, engineerbookings }) {
    return {
        users,
        engineerbookings
    }
}

export default connect(mapStateToProps)(Engineers)