export const RECEIVE_USERS = 'RECEIVE_USERS'
export const NEW_USER = 'NEW_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const REMOVE_USER = 'REMOVE_USER'

export function receiveUsers(users) {
    return {
        type: RECEIVE_USERS,
        users
    }
}

export function newUser(user) {
  return {
      type: NEW_USER,
      user
  }
}

export function updateUser(user) {
  return {
      type: UPDATE_USER,
      user
  }
}

export function deleteUser(userid) {
  return {
      type: REMOVE_USER,
      userid
  }
}