import React from 'react'
import { InputGroup, Form, Col, FormControl, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faPoundSign } from '@fortawesome/free-solid-svg-icons';
import '../../../css/icons/fontAwesomeIcons.css'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faBed, faPoundSign)

const SoundType1 = ({job}) => {  
    return (
        <div>
                <Form.Group as={Col} controlId="formGridFee">
                    <Form.Label>Fee</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon={faPoundSign}  size='1x' className='poundIcon'  /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="Fee..." name="price" type="number" defaultValue={job !== null ? job.price : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose a fee.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Sets</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="material-icons">toc</i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Sets..." name="sets" type="number" defaultValue={job !== null ? job.soundtest.sets : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please enter the number of sets.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSets">
                    <Form.Label>Number of Rooms</Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon={faBed}  size='1x' className='roomIcon'  /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Rooms..." name="rooms" type="number" defaultValue={job !== null ? job.soundtest.rooms : ''} required/>
                        <Form.Control.Feedback type="invalid">
                            Please choose the number of rooms.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </div>
    )

}

export default SoundType1
