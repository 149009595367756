export const RECEIVE_ENGINEER_BOOKINGS = 'RECEIVE_ENGINEER_BOOKINGS'
export const NEW_ENGINEER_BOOKING = 'NEW_ENGINEER_BOOKING'
export const UPDATE_ENGINEER_BOOKING = 'UPDATE_ENGINEER_BOOKING'
export const REMOVE_ENGINEER_BOOKING = 'REMOVE_ENGINEER_BOOKING'

export function receiveEngineerBookings(engineerbookings) {
    return {
        type: RECEIVE_ENGINEER_BOOKINGS,
        engineerbookings
    }
}

export function newEngineerBooking(booking) {
  return {
      type: NEW_ENGINEER_BOOKING,
      booking
  }
}

export function updateEngineerBooking(bookings) {
  return {
      type: UPDATE_ENGINEER_BOOKING,
      bookings
  }
}

export function deleteEngineerBooking(bookingid) {
  return {
      type: REMOVE_ENGINEER_BOOKING,
      bookingid
  }
}