const projectLeaders = () => {
    const leaders = [
        {
            label: "Aaron Andrews",
            value:  "Aaron Andrews",
        },
        {
            label: "Andrew Rickard",
            value: "Andrew Rickard" 
        },
        {
            label: "Callum Brewer",
            value: "Callum Brewer"
        },
        {
            label: "Chase Bartlett",
            value: "Chase Bartlett"
        },
        {
            label: "Josh Childs",
            value: "Josh Childs"
        },
        {
            label: "Kyle Edwards",
            value: "Kyle Edwards"
        },
        {
            label: "Max Reynolds",
            value: "Max Reynolds"
        },
        {
            label: "Phil Jordan",
            value: "Phil Jordan"
        },
        {
            label: "Suji",
            value: "Suji"
        },
        {
            label: "Ze Nunes",
            value: "Ze Nunes"
        },
        {
            label: "Zeinab Elamin",
            value: "Zeinab Elamin"
        }
    ]

    return {
        leaders
        } ;
}

export {projectLeaders};

const projectLeadersSelect = () => {
    const leaders = [
        {
            label: "All",
            value:  "All",
        },
        {
            label: "Aaron Andrews",
            value:  "Aaron Andrews",
        },
        {
            label: "Andrew Rickard",
            value: "Andrew Rickard" 
        },
        {
            label: "Callum Brewer",
            value: "Callum Brewer"
        },
        {
            label: "Chase Bartlett",
            value: "Chase Bartlett"
        },
        {
            label: "Josh Childs",
            value: "Josh Childs"
        },
        {
            label: "Kyle Edwards",
            value: "Kyle Edwards"
        },
        {
            label: "Max Reynolds",
            value: "Max Reynolds"
        },
        {
            label: "Phil Jordan",
            value: "Phil Jordan"
        },
        {
            label: "Suji",
            value: "Suji"
        },
        {
            label: "Ze Nunes",
            value: "Ze Nunes"
        },
        {
            label: "Zeinab Elamin",
            value: "Zeinab Elamin"
        }
    ]

    return {
        leaders
        } ;
}

export {projectLeadersSelect};

const projectLeadersDashboardFilter = () => {
    const leaders = [
        {
            name: "All External",
        },
        {
            name: "All Internal",
        },
        {
            name: "Aaron Andrews",
        },
        {
            name: "Andrew Rickard",
        },
        {
            name: "Callum Brewer",
        },
        {
            name: "Chase Bartlett",
        },
        {
            name: "Josh Childs",
        },
        {
            name: "Kyle Edwards",
        },
        {
            name: "Max Reynolds",
        },
        {
            name: "Phil Jordan",
        },
        {
            name: "Suji",
        },
        {
            name: "Ze Nunes",
        },
        {
            name: "Zeinab Elamin",
        }
    ]

    return {
        leaders
        } ;
}

export {projectLeadersDashboardFilter};