import React, { useState, useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import NewJobModal from './NewJobModal'
import Widget from '../../elements/DashboardWidget'
import RemoveJobModal from './RemoveJobModal'
import AlertComponent from '../alerts/AlertComponent'
import { Container, Row, Col } from 'react-bootstrap'
import { jobs as jobReducer } from '../../reducers/jobs'
import JobsTable from './JobsTable'
import { handleInitialData } from '../../actions/shared'

const Jobs = ({ jobs, users, location, dispatch }) => {
    const [modalopen, openModal] = useState(false)
    const [removejobmodal, openRemoveModal] = useState(false)
    const [id, setID] = useState("")
    const [jobremoved, openRemoveAlert] = useState(false)
    
    useEffect(() => {
        dispatch(handleInitialData())   
    }, [])
  
    useEffect(() => {
        if (location.query !== undefined) {
            if ('jobdeleted' in location.query) {
                openRemoveAlert(true)
            }
        }
    }, [])

    return (
        <Container fluid id="main-container">

            {jobremoved && <AlertComponent type="danger" text="Job Removed!"/>}

            <Row>
                <Widget title="Site Visits" icon="assignment" paddingStyle="5px">
                    <Col md={12}>
                        <JobsTable jobsArr={jobs} engineers={users} openRemoveModal={openRemoveModal} setID={setID}/>
                
                    </Col>
                </Widget>
            </Row>            
            {modalopen && <NewJobModal open={modalopen} openModal={openModal}/>}
            
     
            {removejobmodal && <RemoveJobModal removejobmodal={removejobmodal} openRemoveModal={openRemoveModal} jobid={id} dispatch={dispatch} openRemoveAlert={openRemoveAlert}/>}
        </Container>
    )
}

function mapStateToProps({ jobs, users }) {
    return {
        jobs,
        users
    }
}

export default connect(mapStateToProps)(Jobs)