import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import {config} from './config'
import {colors, backgroundColors} from './colors'
import {navigation} from './navigation'
import {contactUs} from './pages/contact-us'
import {createAccount} from './pages/create-account'
import {login} from './pages/login'
import {resetPassword} from './pages/reset-password'
import {subscribe} from './pages/subscribe'
import {underMaintenance} from './pages/under-maintenance'
import {unlockAccount} from './pages/unlock-account'
import {accountSettings} from './pages/account-settings'
import {emailPreferences} from './pages/email-preferences'
import {securitySettings} from './pages/security-settings'
import {reducer as notificationsReducer} from 'reapop'
import auth from './auth'
import { users } from './users'
import { jobs } from './jobs'
import enquiries from './enquiries'
import { cars } from './cars'
import { kits } from './kits'
import { carbookings } from './carbookings'
import { kitbookings } from './kitbookings'
import { engineerbookings } from './engineerbookings'

const rootReducer = combineReducers({
  config,
  colors,
  backgroundColors,
  navigation,
  contactUs,
  createAccount,
  login,
  resetPassword,
  subscribe,
  underMaintenance,
  unlockAccount,
  accountSettings,
  emailPreferences,
  securitySettings,
  router: routerReducer,
  notifications: notificationsReducer(),
  auth,
  users,
  jobs,
  enquiries,
  cars,
  kits,
  carbookings,
  engineerbookings,
  kitbookings
})
export default rootReducer
